import React from 'react';
import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import { payroll, session } from '../actions';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { BooleanFormatter, BooleanTypeEditor, TFNFormatter } from '../utils';

const columns = [
  { key: 'name', width: 110, name: 'Name', sortable: true, editable: true, resizable: true},
  { key: 'abn', name: 'ABN', width: 120, sortable: true, editable: true, resizable: true, formatter: TFNFormatter},
  { key: 'gst', name: 'GST', width: 60, sortable: true, editable: true, resizable: true, formatter: BooleanFormatter, editor: BooleanTypeEditor},
  { key: 'phone', name: 'Phone', width: 120, sortable: true, editable: true, resizable: true},
  { key: 'address', name: 'Address', sortable: true, editable: true, resizable: true},
  { key: 'comment', name: 'Comment', sortable: true, editable: true, resizable: true},
];

const styles = theme => ({
  wrapper: {
    marginTop: theme.spacing.unit * 2,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px !important',
    '& .react-grid-Grid': {
      overflow: 'visible !important'
    },
    '& .react-grid-Header': {
      position: 'sticky !important',
      zIndex: 3,
      top: 0,
    },
    '& .react-grid-Canvas': {
      overflowY: 'hidden !important'
    },
    '& .react-grid-Main': {
      outline: 'none'
    },
    '& .react-grid-HeaderRow': {
      backgroundColor: '#dedede',
    },
    '& .react-grid-HeaderCell': {
      fontWeight: 'normal',
      color: 'white',
      backgroundColor: '#8bc34a',
    },
    '& .react-grid-Row--odd > .react-grid-Cell': {
      backgroundColor: '#EDF5E6',
    },
    '& .react-grid-Row--odd:hover > .react-grid-Cell': {
      backgroundColor: '#e3ebdc',
    },
  },
  bar: {
    position: 'relative',
    padding: theme.spacing.unit * 2,
    height: 19 + theme.spacing.unit * 4,
    backgroundColor: 'white',
    border: '1px solid #e7eaec',
    borderTop: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  button: {
    position: 'absolute',
    left: 10
  },
  fab: {
    margin: theme.spacing.unit,
    boxShadow: 'none',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.8)',
    color: 'black',
    width: 24,
    height: 24,
    minHeight: 24,
  }
});

const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === "DESC") {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};

class PayrollSubGrid extends React.Component {
  state = {
    rows: [],
    subs: [],
    selectedIndexes: [],
    isUpdate: false,
  };

  componentDidMount() {
    this.setState({rows: this.props.subs, subs: this.props.subs});
  }

  static getDerivedStateFromProps(props, state) {
    if (Array.isArray(props.subs) && !(props.subs.length === state.subs.length && props.subs.every((value, index) => value === state.subs[index]))) {
      return { rows: props.subs, subs: props.subs, isUpdate: true };
    }
    return null;
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    if (this.props.disabled) {
      return;
    }
    const { subs, isUpdate } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(subs, this.sortColumn, this.sortDirection)(rows);
    }

    const newRow = {...rows[fromRow], ...updated};
    const {
      id,
      name,
      abn,
      gst,
      phone,
      address,
      comment,
      user,
    } = newRow;

    if (!name) {
      return;
    }

    if (!abn || abn.length !== 11) {
      return;
    }

    this.props.updateSub(
      id,
      name.toUpperCase(),
      abn.toUpperCase(),
      gst,
      phone.toUpperCase(),
      address.toUpperCase(),
      comment.toUpperCase(),
      user,
    );
    this.props.updateSessionTime();
  };

  onRowsSelected = rows => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        rows.map(r => r.rowIdx)
      )
    });
  };

  onRowsDeselected = rows => {
    let rowIndexes = rows.map(r => r.rowIdx);
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        i => rowIndexes.indexOf(i) === -1
      )
    });
  };

  getTotal = (colName) => {
    const { rows } = this.state;
    if (!rows || rows.length === 0) {
      return 0;
    }
    return rows.reduce((total, e) => total + Number(e[colName]), 0);
  };

  deleteSelectedRows = () => {
    const { subs, isUpdate, selectedIndexes } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(subs, this.sortColumn, this.sortDirection)(rows);
    }
    selectedIndexes.map((index) => {
      return this.props.deleteSub(rows[index].id);
    });
    this.setState({ selectedIndexes: [] });
    this.props.updateSessionTime();
  };

  render() {
    const { classes } = this.props;
    const { subs, isUpdate, selectedIndexes } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(subs, this.sortColumn, this.sortDirection)(rows);
    }

    return (
      <div ref={ref => this.div = ref} className={classes.wrapper}>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          rowHeight={30}
          enableCellSelect
          minHeight={(rows.length + 1) * 30}
          onGridRowsUpdated={this.onGridRowsUpdated}
          onGridSort={(sortColumn, sortDirection) => {
            this.sortColumn = sortColumn;
            this.sortDirection = sortDirection;
            this.setState({rows: sortRows(subs, sortColumn, sortDirection)(rows), isUpdate: false})
          }}
          rowSelection={{
            showCheckbox: true,
            enableShiftSelect: true,
            onRowsSelected: this.onRowsSelected,
            onRowsDeselected: this.onRowsDeselected,
            selectBy: {
              indexes: selectedIndexes
            }
          }}
        />
        <div className={classes.bar}>
          <div className={classes.button}>
            <Tooltip title='Delete selected rows'>
              <Fab size="small" aria-label="Delete" className={classes.fab} onClick={this.deleteSelectedRows}>
                <FontAwesomeIcon icon="trash-alt" />
              </Fab>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    subs: state.payroll.subs,
    user: state.auth.user,
    config: state.auth.config,
    selectedConfig: state.admin.selectedConfig,
    start: state.period.start,
    end: state.period.end,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateSub: (...args) => dispatch(payroll.updateSub(...args)),
    deleteSub: (id) => dispatch(payroll.deleteSub(id)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PayrollSubGrid));