import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { connect } from "react-redux";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { currencyFormat } from '../utils';
import moment from 'moment';


const styles = theme => ({
  content: {
    position: 'relative',

    '& > div': {
      position: 'relative !important',
      width: '100% !important',
      height: '700px !important'
    },
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        height: '100% !important'
      }
    },
    '& thead': {
      backgroundColor: '#8bc34a91',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#8bc34a21',
    },
    '& tbody tr:last-child': {
      backgroundColor: '#fbee7391',
    }
  }
});


class IncomeBusinessTypeAMonthDialog extends React.Component {

  getRows = () => {
    const data = this.props.businessTypeAs;
    if (!data || data.length < 1) {
      return [];
    }

    const { start } = this.props;
    const startMonth = moment(start).month();
    const rows = Array(3);
    for (let i = 0; i < 3; i++) {
      rows[i] = {
        month: moment().month(startMonth + i).format('MMM'),
        card: 0,
        other: 0,
        total: 0,
      };
    }

    for (const row of data) {
      const idx = moment(row.date).month() - startMonth;
      if (idx < 0 || idx >= 3) {
        // period는 바뀌었는데 businessTypeAs가 아직 바뀌지 않았을 때
        continue;
      }
      rows[idx].card += row.card;
      rows[idx].other += row.other;
      rows[idx].total += row.total;
    }
    return rows;
  };

  getTotal = (rows) => {
    const total = {card: 0, other: 0, total: 0};
    for (const row of rows) {
      total.card += row.card;
      total.other += row.other;
      total.total += row.total;
    }
    return total;
  };

  render() {
    const { fullScreen, classes } = this.props;
    const rows = this.getRows();
    const total = this.getTotal(rows);

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={!fullScreen}
          maxWidth={fullScreen ? false : 'sm'}
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent className={classes.content}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Month</TableCell>
                  <TableCell align="right">Card</TableCell>
                  <TableCell align="right">Other</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.month}>
                    <TableCell>{row.month}</TableCell>
                    <TableCell align="right">{currencyFormat(row.card)}</TableCell>
                    <TableCell align="right">{currencyFormat(row.other)}</TableCell>
                    <TableCell align="right">{currencyFormat(row.total)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell align="right">{currencyFormat(total.card)}</TableCell>
                  <TableCell align="right">{currencyFormat(total.other)}</TableCell>
                  <TableCell align="right">{currencyFormat(total.total)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    start: state.period.start,
    businessTypeAs: state.income.businessTypeAs,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

IncomeBusinessTypeAMonthDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(IncomeBusinessTypeAMonthDialog)));