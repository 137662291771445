const initialState = {
  benchmark: {},
  benchmarkClientResult: {},
  pl: {},
  plYear: {},
  errors: {}
};

export default function benchmark(state=initialState, action) {

  switch (action.type) {

    case 'LOAD_BENCHMARK_SUCCESS':
      return {...state, benchmark: action.data, errors: {}};

    case 'LOAD_BENCHMARK_FAILURE':
      return {...state, benchmark: {}, errors: action.data};

    case 'UPDATE_BENCHMARK_SUCCESS':
      return {...state, benchmark: action.data, errors: {}};

    case 'UPDATE_BENCHMARK_FAILURE':
      return {...state, benchmark: {}, errors: action.data};

    case 'LOAD_BENCHMARK_CLIENT_RESULT_SUCCESS':
      return {...state, benchmarkClientResult: action.data, errors: {}};

    case 'LOAD_BENCHMARK_CLIENT_RESULT_FAILURE':
      return {...state, benchmarkClientResult: {}, errors: action.data};

    case 'LOAD_PL_SUCCESS':
      return {...state, pl: action.data, errors: {}};

    case 'LOAD_PL_FAILURE':
      return {...state, pl: {}, errors: action.data};

    case 'LOAD_PL_YEAR_SUCCESS':
      return {...state, plYear: action.data, errors: {}};

    case 'LOAD_PL_YEAR_FAILURE':
      return {...state, plYear: {}, errors: action.data};

    case 'RESET_ERROR':
      return {...state, errors: {}};

    default:
      return state;
  }
}
