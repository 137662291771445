import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { main, session } from '../actions';
import Button from '@material-ui/core/Button';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import cn from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  item: {
    padding: theme.spacing.unit * 2,
  },
  editor: {
    padding: theme.spacing.unit * 2,
    border: '1px solid rgba(0,0,0,0.15)',
  },
});

class AdminMain extends React.Component {
  state = {
    content: '',
    editorState: EditorState.createEmpty(),
    open: false,
    messages: [],
  };

  componentDidMount() {
    this.props.loadMain();
  }

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  onEditorStateChange = (editorState) => {
    this.setState({editorState});
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // 여기서는 setState 를 하는 것이 아니라
    // 특정 props 가 바뀔 때 설정하고 설정하고 싶은 state 값을 리턴하는 형태로
    // 사용됩니다.
    const props = {};
    if (nextProps.isLoaded && (nextProps.main.content !== prevState.content) && nextProps.main.content) {
      props.content = nextProps.main.content;
      const contentBlock = htmlToDraft(nextProps.main.content);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      props.editorState = EditorState.createWithContent(contentState);
    }

    return props ? props : null;
  }

  saveMain = () => {
    this.props.saveMain(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
    this.openSnackbar('Save successfully');
    this.props.updateSessionTime();
  };

  render() {
    const { classes } = this.props;
    const { editorState } = this.state;

    return (
      <React.Fragment>
        <div className={classes.box}>
          <div className={cn(classes.item, classes.editor)}>
            <Editor editorState={editorState} onEditorStateChange={this.onEditorStateChange} />
          </div>
          <div className={classes.item}>
            <Button variant="outlined" color="primary" onClick={this.saveMain}>Save</Button>
          </div>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={2000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </React.Fragment>
    );
  }
}

AdminMain.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoaded: state.main.isLoaded,
    main: state.main.main,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    saveMain: (content) => dispatch(main.saveMain(content)),
    loadMain: () => dispatch(main.loadMain()),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminMain));