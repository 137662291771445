const initialState = {
  isLoading: true,
  isLoaded: false,
  main: null,
  errors: {},
};


export default function auth(state=initialState, action) {

  switch (action.type) {

    case 'MAIN_LOADING':
      return {...state, isLoading: true, isLoaded: false, main: null, errors: {}};

    case 'MAIN_LOADED':
      return {...state, isLoading: false, isLoaded: true, main: action.main, errors: {}};

    case 'MAIN_LOAD_FAILED':
      return {...state, isLoading: false, isLoaded: false, main: null, errors: action.data};

    case 'SAVE_MAIN_SUCCESS':
      return {...state, errors: {}};

    case 'SAVE_MAIN_FAILURE':
      return {...state, errors: action.data};

    default:
      return state;
  }
}
