export const loadSupers = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_SUPERS_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/super/`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_SUPERS_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_SUPERS_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_SUPERS_FAILURE", data: {'detail': 'Server Error'}});
      });
  }
};

export const saveSuper = (year, super_rate) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'SAVE_SUPER_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const body = JSON.stringify({
      year, super_rate
    });

    return fetch(`/api/super/?year=${year}`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            if (data && data.length > 0) {
              return fetch(`/api/super/${data[0].id}/`, {headers, body, method: "PUT"})
                .then(res => {
                  if (200 <= res.status && res.status < 300) {
                    res.json().then(data => {
                      dispatch({type: 'SAVE_SUPER_SUCCESS', data});
                      dispatch(loadSupers());
                      return data;
                    }).catch(err => {
                      throw err;
                    });
                  } else {
                    dispatch({type: "SAVE_SUPER_FAILURE", data: res.data});
                    throw res.data;
                  }
                }).catch(err => {
                  dispatch({type: "SAVE_SUPER_FAILURE", data: {'detail': 'Server Error'}});
                });
            } else {
              return fetch(`/api/super/`, {headers, body, method: "POST"})
                .then(res => {
                  if (200 <= res.status && res.status < 300) {
                    res.json().then(data => {
                      dispatch({type: 'SAVE_SUPER_SUCCESS', data});
                      dispatch(loadSupers());
                      return data;
                    }).catch(err => {
                      throw err;
                    });
                  } else {
                    dispatch({type: "SAVE_SUPER_FAILURE", data: res.data});
                  }
                }).catch(err => {
                  dispatch({type: "SAVE_SUPER_FAILURE", data: {'detail': 'Server Error'}});
                });
            }
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "SAVE_SUPER_FAILURE", data: res.data});
        }
      })
      .catch(err => {
        dispatch({type: "SAVE_SUPER_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};
