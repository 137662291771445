import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { auth, session } from '../actions';
import moment from 'moment';


const styles = (theme) => ({
  root: {
    position: 'fixed',
    left: theme.spacing.unit,
    bottom: theme.spacing.unit,
    padding: theme.spacing.unit,
    borderRadius: 4,
  }
});


class SessionTimeCounter extends React.Component {
  state = {
    sessionTime: 1800
  };

  componentDidMount() {
    this.setTimer();
  }

  decreaseSessionTime = () => {
    const sessionTime = this.state.sessionTime - 1 >= 0 ? this.state.sessionTime - 1 : 0;
    this.setState({sessionTime});
    if (sessionTime === 0 && !this.props.isSessionTimeout) {
      this.props.setSessionTimeout();
      this.props.logout();
    }
  };

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.props.sessionUpdated !== nextProps.sessionUpdated) {
      this.clearTimer();
      this.setState({sessionTime: 1800});
      this.setTimer();
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  setTimer = () => {
    this.timer = window.setInterval(() => this.decreaseSessionTime(), 1000);
  };

  clearTimer = () => {
    window.clearInterval(this.timer);
  };

  render() {
    const { classes } = this.props;
    const { sessionTime } = this.state;
    const str = moment.utc(sessionTime * 1000).format('mm:ss');

    return (
      <div className={classes.root}>
        {str}
      </div>
    );
  }
}

SessionTimeCounter.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    isSessionTimeout: state.session.isSessionTimeout,
    sessionUpdated: state.session.sessionUpdated,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(auth.logout()),
    setSessionTimeout: () => dispatch(session.setSessionTimeout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SessionTimeCounter));
