import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { benchmark } from '../actions';
import { currencyFormat, currencyFormatWithPercent } from '../utils';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import cn from 'classnames';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import XLSX from 'xlsx';


const styles = theme => ({
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  namedBox: {
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  boxMargin: {
    marginTop: 16,
  },
  boxName: {
    margin: '-32px 8px 8px 8px',
    borderRadius: 4,
    padding: '8px 16px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(59, 132, 3, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(239, 239, 239, 0.4)',
    '& > span': {
      color: '#26710c',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
  },
  item: {
    padding: theme.spacing.unit,
    overflowX: 'scroll',
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  table: {
    '& th': {
      padding: '4px 24px 4px 24px',
      backgroundColor: '#EDF5E6',
    },
    '& tr:nth-child(-n+3) th': {
      textAlign: 'center',
    },
    '& td': {
      padding: '4px 24px 4px 24px',
      textAlign: 'right',
    }
  },
  button: {
    padding: '14px 24px',
    backgroundColor: 'white',
  },
  actions: {
    marginTop: theme.spacing.unit * 2,
  },
  fab: {
    margin: theme.spacing.unit,
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.15)',
  },
});

class SummaryBenchmark extends React.Component {
  state = {
    messages: [],
  };

  componentDidMount() {
    this.props.loadBenchmark(moment(this.props.fiscalEnd).year());
    this.props.loadBenchmarkClientResult();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errors !== prevProps.errors && this.props.errors.length > 0) {
      for (const error of this.props.errors) {
        this.openSnackbar(error.message);
        this.props.resetError();
      }
    }
    if (this.props.selectedUser !== prevProps.selectedUser && this.props.selectedUser) {
      this.props.loadBenchmark(moment(this.props.fiscalEnd).year());
      this.props.loadBenchmarkClientResult();
    }
    if (this.props.year !== prevProps.year || this.props.quarter !== prevProps.quarter) {
      this.props.loadBenchmark(moment(this.props.fiscalEnd).year());
      this.props.loadBenchmarkClientResult();
    }
  }

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  downloadCSV = () => {
    const username = this.props.selectedUser && this.props.selectedUser.username ? this.props.selectedUser.username : this.props.user.username;
    const filename = `summary_benchmark_${username}_${this.props.year}_${this.props.quarter}.xlsx`;
    const workbook = XLSX.utils.table_to_book(document.getElementById('sheetjs'));
    XLSX.writeFile(workbook, filename);
  };

  render() {
    const { classes } = this.props;
    const { benchmark, benchmarkClientResult } = this.props;

    if (benchmark.json === undefined || benchmarkClientResult.cash === undefined) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={cn(classes.box)}>
          <div className={classes.item}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" rowSpan={3}>Key Benchmark</TableCell>
                  <TableCell component="th" colSpan={2}>ATO Standard</TableCell>
                  <TableCell component="th" rowSpan={3}>Client Result</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" colSpan={2}>Average Turnover</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {currencyFormat(benchmark.json.key[0])}
                  </TableCell>
                  <TableCell>
                    {currencyFormat(benchmark.json.key[1])}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Cost of Sales</TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.cost_of_sales[0])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.cost_of_sales[1])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmarkClientResult.cost_of_sales * 100)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Total Expenses</TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.total_expenses[0])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.total_expenses[1])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmarkClientResult.total_expenses * 100)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Non Capital Purchase</TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.non_capital_purchase[0])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.non_capital_purchase[1])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmarkClientResult.non_capital_purchase * 100)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Labour</TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.labour[0])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.labour[1])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmarkClientResult.labour * 100)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Rent</TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.rent[0])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.rent[1])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmarkClientResult.rent * 100)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Cash</TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.cash[0])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmark.json.cash[1])}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithPercent(benchmarkClientResult.cash * 100)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>

        <div className={classes.actions}>
          <Tooltip title='Download Excel File'>
            <Fab aria-label="Download Excel File" className={classes.fab} onClick={this.downloadCSV}>
              <FontAwesomeIcon icon="file-excel" size="2x" />
            </Fab>
          </Tooltip>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={2000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        <table id="sheetjs" style={{display: 'none'}}>
          <tbody>
          <tr>
            <th rowSpan={3}>Key Benchmark</th>
            <th colSpan={2}>ATO Standard</th>
            <th rowSpan={3}>Client Result</th>
          </tr>
          <tr>
            <th colSpan={2}>Average Turnover</th>
          </tr>
          <tr>
            <td>{currencyFormat(benchmark.json.key[0])}</td>
            <td>{currencyFormat(benchmark.json.key[1])}</td>
          </tr>
          <tr>
            <td>Cost of Sales</td>
            <td>
              {currencyFormatWithPercent(benchmark.json.cost_of_sales[0])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmark.json.cost_of_sales[1])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmarkClientResult.cost_of_sales * 100)}
            </td>
          </tr>
          <tr>
            <td>Total Expenses</td>
            <td>
              {currencyFormatWithPercent(benchmark.json.total_expenses[0])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmark.json.total_expenses[1])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmarkClientResult.total_expenses * 100)}
            </td>
          </tr>
          <tr>
            <td>Non Capital Purchase</td>
            <td>
              {currencyFormatWithPercent(benchmark.json.non_capital_purchase[0])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmark.json.non_capital_purchase[1])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmarkClientResult.non_capital_purchase * 100)}
            </td>
          </tr>
          <tr>
            <td>Labour</td>
            <td>
              {currencyFormatWithPercent(benchmark.json.labour[0])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmark.json.labour[1])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmarkClientResult.labour * 100)}
            </td>
          </tr>
          <tr>
            <td>Rent</td>
            <td>
              {currencyFormatWithPercent(benchmark.json.rent[0])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmark.json.rent[1])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmarkClientResult.rent * 100)}
            </td>
          </tr>
          <tr>
            <td>Cash</td>
            <td>
              {currencyFormatWithPercent(benchmark.json.cash[0])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmark.json.cash[1])}
            </td>
            <td>
              {currencyFormatWithPercent(benchmarkClientResult.cash * 100)}
            </td>
          </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

SummaryBenchmark.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let errors = [];
  if (state.benchmark.errors) {
    errors = Object.keys(state.benchmark.errors).map(field => {
      return {field, message: state.benchmark.errors[field]};
    });
  }
  return {
    errors,
    fiscalEnd: state.period.fiscalEnd,
    benchmark: state.benchmark.benchmark,
    benchmarkClientResult: state.benchmark.benchmarkClientResult,
    selectedUser: state.admin.selectedUser,
    user: state.auth.user,
    year: state.period.year,
    quarter: state.period.quarter,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadBenchmark: (year) => dispatch(benchmark.loadBenchmark(year)),
    loadBenchmarkClientResult: () => dispatch(benchmark.loadBenchmarkClientResult()),
    resetError: () => dispatch(benchmark.resetError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SummaryBenchmark));