import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IncomeMenu from '../components/IncomeMenu';
import IncomeBusinessTypeA from '../components/IncomeBusinessTypeA';
import IncomeBusinessTypeB from '../components/IncomeBusinessTypeB';
import { auth, income, session } from '../actions';
import { connect } from 'react-redux';
import IncomeInterest from '../components/IncomeInterest';
import IncomeOther from '../components/IncomeOther';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
  left: {
    flex: '0 0 200px',
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 1080,
      flex: '1 1 auto',
      marginBottom: theme.spacing.unit * 2,
    },
  },
  right: {
    flex: '1 1 auto',
    maxWidth: 1080,
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: 1080,
      minWidth: 1080,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }
});

class Income extends React.Component {
  state = {
    menu: 0,
  };

  componentDidMount() {
    this.props.loadConfig();
    this.props.loadBusinessTypeAs();
    this.props.loadBusinessTypeBs();
    this.props.loadInterests();
    this.props.loadOthers();
    this.props.updateSessionTime();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedUser !== prevProps.selectedUser && this.props.selectedUser) {
      this.props.loadBusinessTypeAs();
      this.props.loadBusinessTypeBs();
      this.props.loadInterests();
      this.props.loadOthers();
      this.props.updateSessionTime();
    }
    if (this.props.year !== prevProps.year || this.props.quarter !== prevProps.quarter) {
      this.props.loadBusinessTypeAs();
      this.props.loadBusinessTypeBs();
      this.props.loadInterests();
      this.props.loadOthers();
      this.props.updateSessionTime();
    }
  }

  updateMenu = (menu) => {
    this.setState({ menu });
  };

  render() {
    const { classes, selectedConfig } = this.props;
    const { menu } = this.state;
    const config = selectedConfig ? selectedConfig : this.props.config;

    return (
      <div className={classes.root}>
        <div className={classes.left}>
          <IncomeMenu onChange={this.updateMenu} />
        </div>
        {config && config.json && config.json.income ? (
          <div className={classes.right}>
            {menu === 0 && config ? (config.json.income.incomeType === 'A' ? <IncomeBusinessTypeA /> : <IncomeBusinessTypeB />) : null}
            {menu === 1 && <IncomeInterest />}
            {menu === 2 && <IncomeOther />}
          </div>
        ) : (
          <div className={classes.right}>
            <p>The current user does not have configuration of "Income". Please contact to admin or check your session still valid.</p>
          </div>
        )}
      </div>
    )
  }
}

Income.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    config: state.auth.config,
    selectedConfig: state.admin.selectedConfig,
    selectedUser: state.admin.selectedUser,
    year: state.period.year,
    quarter: state.period.quarter,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadConfig: () => dispatch(auth.loadConfig()),
    loadBusinessTypeAs: () => dispatch(income.loadBusinessTypeAs()),
    loadBusinessTypeBs: () => dispatch(income.loadBusinessTypeBs()),
    loadInterests: () => dispatch(income.loadInterests()),
    loadOthers: () => dispatch(income.loadOthers()),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Income));
