import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { admin, session } from '../actions';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Divider, Typography } from '@material-ui/core';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import withMobileDialog from '@material-ui/core/es/withMobileDialog';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  item: {
    padding: theme.spacing.unit * 2,
  },
  button: {
  },
  fab: {
    margin: theme.spacing.unit,
    boxShadow: 'none',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.15)',
    color: 'rgba(0,0,0,0.20)',
  },
  fabSub: {
    width: 24,
    height: 24,
    minHeight: 24,
    '& svg': {
      fontSize: 16,
    },
    margin: 'auto',
  },
  fabEdit: {
    width: 24,
    height: 24,
    minHeight: 24,
  },
  title: {
    fontSize: '1.25rem',
    marginBottom: theme.spacing.unit,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  expenseMains: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  expenseMain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRadius: 4,
    backgroundColor: '#fffcd3',
    border: '1px solid #f7efa1',
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    '&:first-child': {
      marginTop: theme.spacing.unit,
    },
  },
  expenseMainHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > p': {
      flex: '1 1 auto',
    }
  },
  expenseMainActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  expenseSubs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  expenseSub: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff7a6',
    borderRadius: 4,
    border: '1px solid #f7e383',
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    '&:first-child': {
      marginTop: theme.spacing.unit,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }
  },
  expenseSubActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  expenseSubContent: {
    flex: '1 1 auto',
    marginLeft: 8,
    marginRight: 8,
    '& > p': {
      padding: '12px 16px',
      borderRadius: 4,
      backgroundColor: '#f7e383',
      border: '1px solid #ffca00',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 8,
      marginBottom: 8,
    }
  },
  assetItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  assetItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRadius: 4,
    backgroundColor: '#fffcd3',
    border: '1px solid #f7efa1',
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    '&:first-child': {
      marginTop: theme.spacing.unit,
    },
  },
  assetItemHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > p': {
      flex: '1 1 auto',
    }
  },
  assetItemActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  loanItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  loanItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRadius: 4,
    backgroundColor: '#fffcd3',
    border: '1px solid #f7efa1',
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    '&:first-child': {
      marginTop: theme.spacing.unit,
    },
  },
  loanItemHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > p': {
      flex: '1 1 auto',
    }
  },
  loanItemActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  formControl: {
    marginTop: 8,
    marginBottom: 8,
  }
});

const mapStateToProps = state => {
  return {
    selectedUser: state.admin.selectedUser,
    selectedConfig: state.admin.selectedConfig,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateConfig: (config) => {
      return dispatch(admin.updateConfig(config));
    },
    saveConfig: () => {
      return dispatch(admin.saveConfig());
    },
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

const SubDragHandle = SortableHandle(() => <FontAwesomeIcon style={{marginRight: 8, cursor: 'grab', color: '#ffaf34'}} icon='bars' />);

class ExpenseSubComponent extends React.Component {
  state = {
    open: false,
    labelWidth: 30,
    name: '',
    code: '',
    gst: 0,
  };

  componentDidMount() {
    if (this.InputLabelRef) {
      this.setState({
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      });
    }
  }

  editExpenseSub = (event) => {
    const { value } = this.props;
    this.setState({open: true, ...value});
    this.props.updateSessionTime();
  };

  deleteExpenseSub = (event) => {
    const config = {...this.props.selectedConfig};
    const { mainIndex, index } = this.props;
    config.json.expenditure.expense[mainIndex].subs.splice(index, 1);
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  handleCancel = () => {
    this.setState({open: false});
    this.props.updateSessionTime();
  };

  handleSave = () => {
    const config = {...this.props.selectedConfig};
    const { mainIndex, index } = this.props;
    const { name, code, gst } = this.state;
    config.json.expenditure.expense[mainIndex].subs[index] = { name, code, gst: Number(gst) };
    this.props.updateConfig(config);
    this.setState({open: false});
    this.props.updateSessionTime();
  };

  handleChange = (name) => (event) => {
    this.setState({[name]: event.target.value});
  };

  render() {
    const { classes, value, fullScreen } = this.props;
    const { name, code, gst } = this.state;

    return (
      <div className={classes.expenseSub}>
        <SubDragHandle />
        <div className={classes.expenseSubContent}>
          <Typography>{value.name}</Typography>
        </div>
        <div className={classes.expenseSubContent}>
          <Typography>{value.code}</Typography>
        </div>
        <div className={classes.expenseSubContent}>
          <Typography>{`${value.gst}%`}</Typography>
        </div>
        <div className={classes.expenseSubActions}>
          <Fab size="small" aria-label="Add" className={cn(classes.fab, classes.fabEdit)} onClick={this.editExpenseSub}>
            <FontAwesomeIcon icon="pen" />
          </Fab>
          <Fab size="small" aria-label="Delete" className={cn(classes.fab, classes.fabEdit)} onClick={this.deleteExpenseSub}>
            <FontAwesomeIcon icon="times" />
          </Fab>
          <Dialog
            fullScreen={fullScreen}
            open={this.state.open}
            onClose={this.handleCancel}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Edit Expenditure Expense Sub-Category</DialogTitle>
            <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-name"
                >
                  Name
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  labelWidth={this.state.labelWidth}
                  name="name"
                  id="outlined-name"
                  value={name}
                  onChange={this.handleChange('name')}
                />
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-code"
                >
                  Code
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  labelWidth={this.state.labelWidth}
                  name="code"
                  id="outlined-code"
                  value={code}
                  onChange={this.handleChange('code')}
                />
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-gst"
                >
                  GST
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  labelWidth={this.state.labelWidth}
                  type="number"
                  name="gst"
                  id="outlined-gst"
                  value={gst}
                  onChange={this.handleChange('gst')}
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleSave} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

const ExpenseSub = connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(SortableElement(ExpenseSubComponent))));

const ExpenseSubs = withStyles(styles)(SortableContainer(({classes, mainIndex, items}) => {
  return (
    <div className={classes.expenseSubs}>
      {items.map((value, index) => (
        <ExpenseSub key={`expense-sub-${index}`} mainIndex={mainIndex} index={index} value={value} />
      ))}
    </div>
  );
}));

const MainDragHandle = SortableHandle(({children}) => <Typography style={{cursor: 'grab', fontSize: '0.975rem'}}>{children}</Typography>);

class ExpenseMainComponent extends React.Component {
  state = {
    open: false,
    labelWidth: 50,
    name: '',
  };

  onExpenseSubSortEnd = ({oldIndex, newIndex}) => {
    const config = {...this.props.selectedConfig};
    const { index } = this.props;
    config.json.expenditure.expense[index].subs = arrayMove(config.json.expenditure.expense[index].subs, oldIndex, newIndex);
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  addExpenseSub = (index) => (event) => {
    const config = {...this.props.selectedConfig};
    if (!Array.isArray(config.json.expenditure.expense[index].subs)) {
      config.json.expenditure.expense[index].subs = [];
    }
    config.json.expenditure.expense[index].subs.push({
      name: 'Sub',
      code: '0',
      gst: 10,
    });
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  componentDidMount() {
    if (this.InputLabelRef) {
      this.setState({
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      });
    }
  }

  editExpenseMain = (event) => {
    const { value } = this.props;
    this.setState({open: true, name: value.name});
    this.props.updateSessionTime();
  };

  deleteExpenseMain = (event) => {
    const config = {...this.props.selectedConfig};
    const { index } = this.props;
    config.json.expenditure.expense.splice(index, 1);
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  handleCancel = () => {
    this.setState({open: false});
    this.props.updateSessionTime();
  };

  handleSave = () => {
    const config = {...this.props.selectedConfig};
    const { index } = this.props;
    const { name } = this.state;
    config.json.expenditure.expense[index].name = name;
    this.props.updateConfig(config);
    this.setState({open: false});
  };

  handleChange = (name) => (event) => {
    this.setState({[name]: event.target.value});
  };

  render() {
    const { classes, index, value, fullScreen } = this.props;
    const { name } = this.state;

    return (
      <div className={classes.expenseMain}>
        <div className={classes.expenseMainHeader}>
          <MainDragHandle>{value.name}</MainDragHandle>
          <div className={classes.expenseMainActions}>
            <Fab size="small" aria-label="Add" className={cn(classes.fab, classes.fabEdit)} onClick={this.editExpenseMain}>
              <FontAwesomeIcon icon="pen" />
            </Fab>
            <Fab size="small" aria-label="Delete" className={cn(classes.fab, classes.fabEdit)} onClick={this.deleteExpenseMain}>
              <FontAwesomeIcon icon="times" />
            </Fab>
            <Dialog
              fullScreen={fullScreen}
              open={this.state.open}
              onClose={this.handleCancel}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Edit Expenditure Expense Main-Category</DialogTitle>
              <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-name"
                  >
                    Name
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    labelWidth={this.state.labelWidth}
                    name="name"
                    id="outlined-name"
                    value={name}
                    onChange={this.handleChange('name')}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleSave} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <ExpenseSubs mainIndex={index} items={value.subs} onSortEnd={this.onExpenseSubSortEnd} useDragHandle />
        <Fab size="small" aria-label="Add" className={cn(classes.fab, classes.fabSub)} onClick={this.addExpenseSub(index)}>
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

const ExpenseMain = connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(SortableElement(ExpenseMainComponent))));

const ExpenseMains = withStyles(styles)(SortableContainer(({classes, items}) => {
  return (
    <div className={classes.expenseMains}>
      {items.map((value, index) => (
        <ExpenseMain key={`expense-main-${index}`} index={index} value={value} />
      ))}
    </div>
  );
}));


class AssetItemComponent extends React.Component {
  state = {
    open: false,
    labelWidth: 50,
    name: '',
    gst: '',
  };

  componentDidMount() {
    if (this.InputLabelRef) {
      this.setState({
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      });
    }
  }

  editAssetItem = (event) => {
    const { value } = this.props;
    this.setState({open: true, ...value});
    this.props.updateSessionTime();
  };

  deleteAssetItem = (event) => {
    const config = {...this.props.selectedConfig};
    const { index } = this.props;
    config.json.expenditure.asset.splice(index, 1);
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  handleCancel = () => {
    this.setState({open: false});
    this.props.updateSessionTime();
  };

  handleSave = () => {
    const config = {...this.props.selectedConfig};
    const { index } = this.props;
    const { name, gst } = this.state;
    config.json.expenditure.asset[index].name = name;
    config.json.expenditure.asset[index].gst = Number(gst);
    this.props.updateConfig(config);
    this.setState({open: false});
    this.props.updateSessionTime();
  };

  handleChange = (name) => (event) => {
    this.setState({[name]: event.target.value});
  };

  render() {
    const { classes, value, fullScreen } = this.props;
    const { name, gst } = this.state;

    return (
      <div className={classes.assetItem}>
        <div className={classes.assetItemHeader}>
          <MainDragHandle>{value.name} {value.gst && `(GST: ${value.gst}%)`}</MainDragHandle>
          <div className={classes.assetItemActions}>
            <Fab size="small" aria-label="Add" className={cn(classes.fab, classes.fabEdit)} onClick={this.editAssetItem}>
              <FontAwesomeIcon icon="pen" />
            </Fab>
            <Fab size="small" aria-label="Delete" className={cn(classes.fab, classes.fabEdit)} onClick={this.deleteAssetItem}>
              <FontAwesomeIcon icon="times" />
            </Fab>
            <Dialog
              fullScreen={fullScreen}
              open={this.state.open}
              onClose={this.handleCancel}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Edit Expenditure Expense Main-Category</DialogTitle>
              <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-name"
                  >
                    Name
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    autoFocus
                    labelWidth={this.state.labelWidth}
                    name="name"
                    id="outlined-name"
                    value={name}
                    onChange={this.handleChange('name')}
                  />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-gst"
                  >
                    GST
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    labelWidth={this.state.labelWidth}
                    type="number"
                    name="gst"
                    id="outlined-gst"
                    value={gst}
                    onChange={this.handleChange('gst')}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleSave} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

const AssetItem = connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(SortableElement(AssetItemComponent))));

const AssetItems = withStyles(styles)(SortableContainer(({classes, items}) => {
  return (
    <div className={classes.assetItems}>
      {items.map((value, index) => (
        <AssetItem key={`asset-item-${index}`} index={index} value={value} />
      ))}
    </div>
  );
}));



class LoanItemComponent extends React.Component {
  state = {
    open: false,
    labelWidth: 50,
    name: '',
  };

  componentDidMount() {
    if (this.InputLabelRef) {
      this.setState({
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      });
    }
  }

  editLoanItem = (event) => {
    const { value } = this.props;
    this.setState({open: true, name: value.name});
    this.props.updateSessionTime();
  };

  deleteLoanItem = (event) => {
    const config = {...this.props.selectedConfig};
    const { index } = this.props;
    config.json.expenditure.loan.splice(index, 1);
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  handleCancel = () => {
    this.setState({open: false});
    this.props.updateSessionTime();
  };

  handleSave = () => {
    const config = {...this.props.selectedConfig};
    const { index } = this.props;
    const { name } = this.state;
    config.json.expenditure.loan[index].name = name;
    this.props.updateConfig(config);
    this.setState({open: false});
    this.props.updateSessionTime();
  };

  handleChange = (name) => (event) => {
    this.setState({[name]: event.target.value});
  };

  render() {
    const { classes, value, fullScreen } = this.props;
    const { name } = this.state;

    return (
      <div className={classes.loanItem}>
        <div className={classes.loanItemHeader}>
          <MainDragHandle>{value.name}</MainDragHandle>
          <div className={classes.loanItemActions}>
            <Fab size="small" aria-label="Add" className={cn(classes.fab, classes.fabEdit)} onClick={this.editLoanItem}>
              <FontAwesomeIcon icon="pen" />
            </Fab>
            <Fab size="small" aria-label="Delete" className={cn(classes.fab, classes.fabEdit)} onClick={this.deleteLoanItem}>
              <FontAwesomeIcon icon="times" />
            </Fab>
            <Dialog
              fullScreen={fullScreen}
              open={this.state.open}
              onClose={this.handleCancel}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Edit Expenditure Expense Main-Category</DialogTitle>
              <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-name"
                  >
                    Name
                  </InputLabel>
                  <OutlinedInput
                    autoFocus
                    fullWidth
                    labelWidth={this.state.labelWidth}
                    name="name"
                    id="outlined-name"
                    value={name}
                    onChange={this.handleChange('name')}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleSave} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

const LoanItem = connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(SortableElement(LoanItemComponent))));

const LoanItems = withStyles(styles)(SortableContainer(({classes, items}) => {
  return (
    <div className={classes.loanItems}>
      {items.map((value, index) => (
        <LoanItem key={`loan-item-${index}`} index={index} value={value} />
      ))}
    </div>
  );
}));


class ClientExpenditure extends React.Component {
  state = {
    open: false,
    messages: [],
  };

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  onExpenseMainSortEnd = ({oldIndex, newIndex}) => {
    const config = {...this.props.selectedConfig};
    config.json.expenditure.expense = arrayMove(config.json.expenditure.expense, oldIndex, newIndex);
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  addExpenseMain = () => {
    const config = {...this.props.selectedConfig};
    if (config && config.json) {
      if (config.json.expenditure === undefined || config.json.expenditure === null) {
        config.json.expenditure = {};
      }
      if (!Array.isArray(config.json.expenditure.expense)) {
        config.json.expenditure.expense = [];
      }
      config.json.expenditure.expense.push({
        name: 'Main',
        subs: [],
      });
    }
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  onAssetItemSortEnd = ({oldIndex, newIndex}) => {
    const config = {...this.props.selectedConfig};
    config.json.expenditure.asset = arrayMove(config.json.expenditure.asset, oldIndex, newIndex);
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  addAssetItem = () => {
    const config = {...this.props.selectedConfig};
    if (config && config.json) {
      if (config.json.expenditure === undefined || config.json.expenditure === null) {
        config.json.expenditure = {};
      }
      if (!Array.isArray(config.json.expenditure.asset)) {
        config.json.expenditure.asset = [];
      }
      config.json.expenditure.asset.push({
        name: 'Asset Item',
        gst: 10,
      });
    }
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  onLoanItemSortEnd = ({oldIndex, newIndex}) => {
    const config = {...this.props.selectedConfig};
    config.json.expenditure.loan = arrayMove(config.json.expenditure.loan, oldIndex, newIndex);
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  addLoanItem = () => {
    const config = {...this.props.selectedConfig};
    if (config && config.json) {
      if (config.json.expenditure === undefined || config.json.expenditure === null) {
        config.json.expenditure = {};
      }
      if (!Array.isArray(config.json.expenditure.loan)) {
        config.json.expenditure.loan = [];
      }
      config.json.expenditure.loan.push({
        name: 'Loan Item',
      });
    }
    this.props.updateConfig(config);
    this.props.updateSessionTime();
  };

  saveConfig = () => {
    this.openSnackbar('Saved successfully');
    this.props.saveConfig();
    this.props.updateSessionTime();
  };

  render() {
    const { classes } = this.props;
    if (!!!this.props.selectedConfig) {
      return null;
    }
    const config = this.props.selectedConfig;
    let expenseMains = [];
    if (config && config.json && config.json.expenditure && config.json.expenditure.expense) {
      expenseMains = config.json.expenditure.expense;
    }
    let assetItems = [];
    if (config && config.json && config.json.expenditure && config.json.expenditure.asset) {
      assetItems = config.json.expenditure.asset;
    }
    let loanItems = [];
    if (config && config.json && config.json.expenditure && config.json.expenditure.loan) {
      loanItems = config.json.expenditure.loan;
    }

    return (
      <React.Fragment>
        <div className={classes.box}>
          <div className={classes.item}>
            <Typography variant="h3" className={classes.title}>
              Expense
              <Fab size="small" aria-label="Add" className={cn(classes.fabSub, classes.fab)} style={{margin: '0 8px'}} onClick={this.addExpenseMain}>
                <AddIcon />
              </Fab>
            </Typography>
            <Divider />
            <div className={classes.wrapper}>
              <ExpenseMains items={expenseMains} onSortEnd={this.onExpenseMainSortEnd} useDragHandle />
            </div>
          </div>
          <div className={classes.item}>
            <Typography variant="h3" className={classes.title}>
              Asset
              <Fab size="small" aria-label="Add" className={cn(classes.fabSub, classes.fab)} style={{margin: '0 8px'}} onClick={this.addAssetItem}>
                <AddIcon />
              </Fab>
            </Typography>
            <Divider />
            <div className={classes.wrapper}>
              <AssetItems items={assetItems} onSortEnd={this.onAssetItemSortEnd} useDragHandle />
            </div>
          </div>
          <div className={classes.item}>
            <Typography variant="h3" className={classes.title}>
              Loan
              <Fab size="small" aria-label="Add" className={cn(classes.fabSub, classes.fab)} style={{margin: '0 8px'}} onClick={this.addLoanItem}>
                <AddIcon />
              </Fab>
            </Typography>
            <Divider />
            <div className={classes.wrapper}>
              <LoanItems items={loanItems} onSortEnd={this.onLoanItemSortEnd} useDragHandle />
            </div>
          </div>
          <div className={classes.item}>
            <Button className={classes.button} size="large" variant="outlined" color="primary" onClick={this.saveConfig}>Save</Button>
          </div>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={2000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

      </React.Fragment>
    );
  }
}

ClientExpenditure.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientExpenditure));