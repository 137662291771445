import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { auth, notice, period, admin, session } from '../actions';
import { withStyles } from '@material-ui/core/styles';
import SelectPeriodDialog from '../components/SelectPeriodDialog';
import NoticeDialog from '../components/NoticeDialog';
import moment from 'moment';
import Header from '../components/Header';
import ScrollableTabs from '../components/ScrollableTabs';
import { Route, Switch } from 'react-router-dom';
import Income from './Income';
import Expenditure from './Expenditure';
import Payroll from './Payroll';
import Summary from './Summary';
import NotFound from './NotFound';
import Admin from './Admin';
import Client from './Client';
import SessionTimeCounter from '../components/SessionTimeCounter';


const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  container: {
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      margin: 'auto',
    },
  }
});


class Main extends React.Component {
  componentDidMount() {
    // 공지사항 불러오기
    // 하루 동안 보지 않기를 누른 사람에겐 24시간 동안 노출이 안됨
    const dontShowToday = localStorage.getItem('dontShowToday');
    if (!dontShowToday || moment().isAfter(moment(dontShowToday), 'day')) {
      // 서버로부터 공지사항을 가져오기
      this.props.loadNotice();
      this.openNoticeDialog();
    } else {
      // 공지사항을 읽을 필요가 없다면 바로 기간 선택창을 노출
      this.openSelectPeriodDialogWhenPeriodWasNotSelected();
    }

    // 유저 설정값 로드
    this.props.loadConfig();
    // 락 설정값 로드
    this.props.loadLocks();
    // 현재 유저가 관리자면 내비게이션을 위한 유저 목록을 불러온다
    if (this.props.user.is_staff) {
      this.props.loadUsers();
    }

    // 세션 타임아웃 시간 갱신
    this.props.updateSessionTime();
  }

  openSelectPeriodDialogWhenPeriodWasNotSelected = () => {
    // 이미 현재 세션에서 선택된 기간이 없을 시에만 선택창을 노출
    if (!this.props.quarter) {
      this.openSelectPeriodDialog();
    }
  };

  AdminRoute = ({component: ChildComponent, ...rest}) => {
    const { user } = this.props;
    return <Route {...rest} render={props => {
      if (user && user.is_staff) {
        return <ChildComponent {...props} />
      } else {
        return <NotFound />
      }
    }} />
  };

  render() {
    const { classes } = this.props;
    const { AdminRoute } = this;

    return (
      <React.Fragment>
        {this.props.quarter ?
          <div className={classes.root}>
            <Header />
            <ScrollableTabs>
              <div className={classes.container}>
                <Switch>
                  <Route exact path="/" component={Income} />
                  <Route exact path="/income" component={Income} />
                  <Route exact path="/expenditure" component={Expenditure} />
                  <Route exact path="/payroll" component={Payroll} />
                  <Route exact path="/summary" component={Summary} />
                  <AdminRoute exact path="/client" component={Client} />
                  <AdminRoute exact path="/admin" component={Admin} />
                </Switch>
              </div>
            </ScrollableTabs>
          </div> : null
        }
        <NoticeDialog setOpen={open => this.openNoticeDialog = open} onClose={this.openSelectPeriodDialogWhenPeriodWasNotSelected} />
        <SelectPeriodDialog setOpen={open => this.openSelectPeriodDialog = open} />
        <SessionTimeCounter />
      </React.Fragment>
    )
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    quarter: state.period.quarter,
    user: state.auth.user,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(auth.logout()),
    loadNotice: () => dispatch(notice.loadNotice()),
    loadConfig: () => dispatch(auth.loadConfig()),
    loadLocks: () => dispatch(period.loadLocks()),
    loadUsers: () => dispatch(admin.loadUsers()),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Main));