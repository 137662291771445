import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { payroll, session } from '../actions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { getSelectableYears } from '../utils';
import MenuItem from '@material-ui/core/MenuItem';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import { FilePond } from 'react-filepond';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = theme => ({
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  namedBox: {
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  boxMargin: {
    marginTop: 16,
  },
  boxName: {
    margin: '-32px 8px 8px 8px',
    borderRadius: 4,
    padding: '8px 16px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(59, 132, 3, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(239, 239, 239, 0.4)',
    '& > span': {
      color: '#26710c',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
  },
  item: {
    padding: theme.spacing.unit,
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      flex: '1 1 33.3333%',
    },
    '& > div:first-child': {
      marginRight: theme.spacing.unit,
    },
    '& > div:last-child': {
      marginLeft: theme.spacing.unit,
    },
  },
  table: {
    '& thead': {
      backgroundColor: '#8bc34a91',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#8bc34a21',
    },
    '& td > a': {
      fontSize: '1rem',
      textDecoration: 'none',
      color: '#000',
    }
  },
  filePond: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    padding: '14px 24px',
  },
  hover: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.15)',
  }
});

class AdminWageTax extends React.Component {
  state = {
    year: '',
    wage_type: '',
    labelWidth: 30,
    open: false,
    messages: [],
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
    this.props.loadWageTaxes();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errors !== prevProps.errors && this.props.errors.length > 0) {
      for (const error of this.props.errors) {
        this.openSnackbar(error.message);
        this.props.resetError();
      }
    }
    if (this.props.isWageTaxUploaded && !prevProps.isWageTaxUploaded) {
      this.openSnackbar('Upload successfully');
    }
  };

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value});
  };

  uploadWageTax = () => {
    const { year, wage_type } = this.state;
    if (year && wage_type && this.pond) {
      this.props.uploadWageTax(year, wage_type, this.pond);
    } else {
      this.openSnackbar('Fiscal Year, Wage Type and Wage Tax File can not be blank');
    }
    this.props.updateSessionTime();
  };

  render() {
    const { classes, wage_taxes } = this.props;
    const { year, wage_type, labelWidth } = this.state;

    return (
      <React.Fragment>
        <div className={cn(classes.box, classes.namedBox)}>
          <div className={classes.boxName}><span>Input</span></div>
          <div className={classes.item}>
            <div className={classes.filePond}>
              <FilePond ref={ref => this.pond = ref} />
            </div>
            <div className={classes.form}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-year"
                >
                  Fiscal Year
                </InputLabel>
                <Select
                  value={year}
                  onChange={this.handleChange}
                  input={
                    <OutlinedInput
                      labelWidth={labelWidth}
                      name="year"
                      id="outlined-year"
                    />
                  }
                >
                  {getSelectableYears().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-wage_type"
                >
                  Wage Type
                </InputLabel>
                <Select
                  value={wage_type}
                  onChange={this.handleChange}
                  input={
                    <OutlinedInput
                      labelWidth={labelWidth}
                      name="wage_type"
                      id="outlined-wage_type"
                    />
                  }
                >
                  <MenuItem value='W'>Weekly</MenuItem>
                  <MenuItem value='F'>Fortnightly</MenuItem>
                  <MenuItem value='M'>Monthly</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl} fullWidth>
                <Button className={classes.button}
                        variant="outlined" size="large" color="primary"
                        onClick={this.uploadWageTax}
                        fullWidth>
                  Upload {this.props.isWageTaxUploading && <FontAwesomeIcon style={{marginLeft: 8}} icon={'spinner'} pulse />}
                </Button>
              </FormControl>
            </div>
          </div>
        </div>
        <div className={cn(classes.box, classes.boxMargin)}>
          <div className={classes.item}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Fiscal Year</TableCell>
                  <TableCell>Wage Type</TableCell>
                  <TableCell>Tax File</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wage_taxes.map(wage_tax =>
                  <TableRow key={`wage_tax-${wage_tax.id}`}>
                    <TableCell>{wage_tax.year}</TableCell>
                    <TableCell>{wage_tax.get_wage_type_display}</TableCell>
                    <TableCell>
                      {wage_tax.tax_file ?
                        <a target="_blank" rel="noopener noreferrer" href={wage_tax.tax_file}>
                          <FontAwesomeIcon icon={"file-excel"}/>
                        </a> : null
                      }
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>

        {this.props.isWageTaxUploading && (
          <div className={classes.hover}>
            <CircularProgress className={classes.progress} color={'secondary'} />
          </div>
        )}

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={2000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </React.Fragment>
    );
  }
}

AdminWageTax.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let errors = [];
  if (state.payroll.errors) {
    errors = Object.keys(state.payroll.errors).map(field => {
      return {field, message: state.payroll.errors[field]};
    });
  }
  return {
    errors,
    wage_taxes: state.payroll.wage_taxes,
    isWageTaxUploading: state.payroll.isWageTaxUploading,
    isWageTaxUploaded: state.payroll.isWageTaxUploaded,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadWageTaxes: () => dispatch(payroll.loadWageTaxes()),
    uploadWageTax: (year, wage_type, pond) => dispatch(payroll.uploadWageTax(year, wage_type, pond)),
    resetError: () => dispatch(payroll.resetError()),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminWageTax));