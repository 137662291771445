import { combineReducers } from 'redux';
import auth from "./auth";
import registration from "./registration";
import notice from "./notice";
import period from "./period";
import expenditure from "./expenditure";
import admin from "./admin";
import income from "./income";
import payroll from "./payroll";
import main from "./main";
import _super from "./super";
import benchmark from "./benchmark";
import session from "./session";


const reducer = combineReducers({
  auth,
  registration,
  notice,
  period,
  expenditure,
  admin,
  income,
  payroll,
  main,
  _super,
  benchmark,
  session,
});

export default reducer;
