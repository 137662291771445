const initialState = {
  expenses: [],
  assets: [],
  loans: [],
  errors: {}
};


export default function registration(state=initialState, action) {

  switch (action.type) {

    case 'DELETE_EXPENSE_SUCCESS':
    case 'UPDATE_EXPENSE_SUCCESS':
    case 'ADD_EXPENSE_SUCCESS':
      return {...state, errors: {}};

    case 'DELETE_EXPENSE_ERROR':
    case 'DELETE_EXPENSE_FAILED':
    case 'ADD_EXPENSE_ERROR':
    case 'ADD_EXPENSE_FAILED':
    case 'UPDATE_EXPENSE_ERROR':
    case 'UPDATE_EXPENSE_FAILED':
      return {...state, errors: action.data};

    case 'LOAD_EXPENSES_SUCCESS':
      return {...state, expenses: action.data, errors: {}};

    case 'LOAD_EXPENSES_ERROR':
    case 'LOAD_EXPENSES_FAILED':
      return {...state, errors: action.data};

    case 'LOAD_ASSETS_SUCCESS':
      return {...state, assets: action.data, errors: {}};

    case 'LOAD_ASSETS_FAILURE':
      return {...state, assets: [], errors: action.data};

    case 'LOAD_LOANS_SUCCESS':
      return {...state, loans: action.data, errors: {}};

    case 'LOAD_LOANS_FAILURE':
      return {...state, loans: [], errors: action.data};

    case 'ADD_LOAN_SUCCESS':
    case 'UPDATE_LOAN_SUCCESS':
    case 'DELETE_LOAN_SUCCESS':
    case 'ADD_ASSET_SUCCESS':
    case 'UPDATE_ASSET_SUCCESS':
    case 'DELETE_ASSET_SUCCESS':
      return {...state, errors: {}};

    case 'ADD_LOAN_FAILURE':
    case 'UPDATE_LOAN_FAILURE':
    case 'DELETE_LOAN_FAILURE':
    case 'ADD_ASSET_FAILURE':
    case 'UPDATE_ASSET_FAILURE':
    case 'DELETE_ASSET_FAILURE':
      return {...state, errors: action.data};

    case 'RESET_ERROR':
      return {...state, errors: {}};

    default:
      return state;
  }
}
