let period;
try {
  const p = sessionStorage.getItem('period');
  period = p ? JSON.parse(sessionStorage.getItem('period')) : null;
} catch {
  sessionStorage.setItem('period', '{}');
}

const initialState = {
  year: period ? period.year : null,        // 2019
  quarter: period ? period.quarter : null,  // 1 (7/1 ~ 9/30)
  start: period ? period.start : null,      // 01/07/2019
  end: period ? period.end : null,          // 30/09/2019
  fiscalStart: period ? period.fiscalStart : null,
  fiscalEnd: period ? period.fiscalEnd : null,
  fiscalYear: period ? period.fiscalYear: null,
  locks: [],
  isLocked: false,
  userLocks: [],
  isUserLocked: false,
  isAllLocked: false,
};


export default function auth(state=initialState, action) {

  switch (action.type) {

    case 'PERIOD_SELECTED':
      sessionStorage.setItem('period', JSON.stringify({
        year: action.year,
        quarter: action.quarter,
        start: action.start,
        end: action.end,
        fiscalStart: action.fiscalStart,
        fiscalEnd: action.fiscalEnd,
        fiscalYear: action.fiscalYear,
      }));
      return {
        ...state,
        year: action.year,
        quarter: action.quarter,
        start: action.start,
        end: action.end,
        fiscalStart: action.fiscalStart,
        fiscalEnd: action.fiscalEnd,
        fiscalYear: action.fiscalYear,
      };

    case 'PERIOD_SELECT_FAILED':
      sessionStorage.setItem('period', '{}');
      return {...state,
        year: null,
        quarter: null,
        start: null,
        end: null,
        fiscalStart: null,
        fiscalEnd: null,
        fiscalYear: null
      };

    case 'LOAD_LOCKS_SUCCESS':
      let isLocked = false;
      for (const lock of action.data) {
        if (lock.year === state.year && lock.quarter === state.quarter) {
          isLocked = true;
          break;
        }
      }
      return {...state, locks: action.data, isLocked};

    case 'LOAD_LOCKS_FAILURE':
      return {...state, locks: [], isLocked: false};

    case 'LOAD_USER_LOCKS_SUCCESS':
      let isUserLocked = false, isAllLocked = false;
      for (const lock of action.data) {
        if (lock.year === state.year && lock.quarter === state.quarter) {
          isUserLocked = true;
          if (lock.model_name === 'all') {
            isAllLocked = true;
            break;
          }
        }
      }
      return {...state, userLocks: action.data, isUserLocked, isAllLocked};

    case 'LOAD_USER_LOCKS_FAILURE':
      return {...state, userLocks: [], isUserLocked: false};

    default:
      return state;
  }
}
