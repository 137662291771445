import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { session } from '../actions';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
    }
  },
  itemText: {
    letterSpacing: '0.02857em',
    lineHeight: '1.75',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.8)'
  }
});

class ClientMenu extends React.Component {
  state = {
    selectedIndex: 0,
  };

  handleListItemClick = (event, index) => {
    this.setState({ selectedIndex: index });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(index);
    }
    this.props.updateSessionTime();
  };

  render() {
    const { classes, disabled } = this.props;
    const { selectedIndex } = this.state;

    return (
      <div className={classes.root}>
        <List component="nav">
          <ListItem
            button
            disabled={disabled}
            selected={!disabled && selectedIndex === 0}
            onClick={event => this.handleListItemClick(event, 0)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="Basic" />
          </ListItem>
          <ListItem
            button
            disabled={disabled}
            selected={!disabled && selectedIndex === 1}
            onClick={event => this.handleListItemClick(event, 1)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="Income" />
          </ListItem>
          <ListItem
            button
            disabled={disabled}
            selected={!disabled && selectedIndex === 2}
            onClick={event => this.handleListItemClick(event, 2)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="Expenditure" />
          </ListItem>
          <ListItem
            button
            disabled={disabled}
            selected={!disabled && selectedIndex === 3}
            onClick={event => this.handleListItemClick(event, 3)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="Payroll" />
          </ListItem>
          <ListItem
            button
            disabled={disabled}
            selected={!disabled && selectedIndex === 4}
            onClick={event => this.handleListItemClick(event, 4)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="Benchmark" />
          </ListItem>
          <ListItem
              button
              disabled={disabled}
              selected={!disabled && selectedIndex === 5}
              onClick={event => this.handleListItemClick(event, 5)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="User Lock" />
          </ListItem>
        </List>
      </div>
    );
  }
}

ClientMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {}
};

const mapDispatchToProps = dispatch => {
  return {
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientMenu));