import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { _super, session } from '../actions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import { getSelectableYears } from '../utils';
import MenuItem from '@material-ui/core/MenuItem';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  item: {
    padding: theme.spacing.unit * 2,
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      flex: '1 1 50%',
    },
    '& > div:first-child': {
      marginRight: theme.spacing.unit,
    },
    '& > div:last-child': {
      marginLeft: theme.spacing.unit,
    },
  },
  table: {
    '& thead': {
      backgroundColor: '#8bc34a91',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#8bc34a21',
    }
  }
});

class AdminSuper extends React.Component {
  state = {
    year: '',
    super_rate: '',
    labelWidth: 30,
    open: false,
    messages: [],
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
    this.props.loadSupers();
  }

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value});
  };

  saveSuper = () => {
    const { year, super_rate } = this.state;
    if (year && super_rate) {
      this.props.saveSuper(year, super_rate);
      this.openSnackbar('Save successfully');
    } else {
      this.openSnackbar('Fiscal Year or Super Rate can not be blank');
    }
    this.props.updateSessionTime();
  };

  render() {
    const { classes, supers } = this.props;
    const { year, super_rate, labelWidth } = this.state;

    return (
      <React.Fragment>
        <div className={classes.box}>
          <div className={classes.item}>
            <div className={classes.form}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-year"
                >
                  Fiscal Year
                </InputLabel>
                <Select
                  value={year}
                  onChange={this.handleChange}
                  input={
                    <OutlinedInput
                      labelWidth={labelWidth}
                      name="year"
                      id="outlined-year"
                    />
                  }
                >
                  {getSelectableYears().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-super_rate"
                >
                  Super
                </InputLabel>
                <OutlinedInput
                  value={super_rate}
                  labelWidth={labelWidth}
                  onChange={this.handleChange}
                  type="number"
                  name="super_rate"
                  id="outlined-super_rate"
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </div>
          <div className={classes.item}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Fiscal Year</TableCell>
                  <TableCell>Super (%)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supers.map(s => (
                  <TableRow key={s.year}>
                    <TableCell>{s.year}</TableCell>
                    <TableCell>{s.super_rate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className={classes.item}>
            <Button variant="outlined" color="primary" onClick={this.saveSuper}>Save</Button>
          </div>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={2000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

      </React.Fragment>
    );
  }
}

AdminSuper.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    supers: state._super.supers,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadSupers: () => dispatch(_super.loadSupers()),
    saveSuper: (year, super_rate) => dispatch(_super.saveSuper(year, super_rate)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminSuper));