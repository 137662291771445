const initialState = {
  users: [],
  selectedUser: null,
  selectedConfig: null
};


export default function auth(state=initialState, action) {

  switch (action.type) {

    case 'LOAD_USERS_SUCCESS':
      return {...state, users: action.users};

    case 'SELECT_USER':
      return {...state, selectedUser: action.user, selectedConfig: action.config};

    case 'UPDATE_CONFIG':
      return {...state, selectedConfig: action.config};

    case 'SAVE_CONFIG_SUCCESS':
      return {...state, selectedConfig: action.config};

    case 'UPDATE_USER_SUCCESS':
      return {...state, selectedUser: action.data};

    case 'UPDATE_USER_FAILURE':
      return {...state, errors: action.data};

    default:
      return state;
  }
}
