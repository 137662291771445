import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import { Provider, connect } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { auth } from "./actions";
import reducers from "./reducers";

import HannaAirTTF from './fonts/BMHANNAAir_ttf.ttf';
import JuaTTF from './fonts/BMJUA_ttf.ttf';
import DohyunTTF from './fonts/BMDOHYEON_ttf.ttf';
import Main from './pages/Main';
import NotFound from './pages/NotFound';
import Login from "./pages/Login";
import loadFontAwesome from './fa';
import 'filepond/dist/filepond.min.css';
import 'react-day-picker/lib/style.css';

loadFontAwesome();
let store = createStore(reducers, applyMiddleware(thunk));

let theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  palette: {
    primary: {
      light: '#bef67a',
      main: '#8bc34a',
      dark: '#5a9216',
      contrastText: '#000'
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000000'
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 56
      },
      '@media(min - width: 600px)': {
        minHeight: 64
      }
    }
  }
});

theme = {
  ...theme,
  overrides: {
  }
};

const styles = {
  '@global': {
    body: {
      height: '100vh',
      maxHeight: '100vh',

      ['@media screen and (-webkit-min-device-pixel-ratio:0) and ' + theme.breakpoints.down('xs')]: {
        height: 'calc(100vh - 56px)',
      }
    },
    '::selection': {
      color: '#fffbf0',
      background: '#b53b2c'
    },
    '#root': {
      height: '100vh',
      maxHeight: '100vh',
    },
    '@font-face': [
      {
        fontFamily: 'BM Hanna Air TTF',
        fontStyle: 'normal',
        fontWeight: 500,
        src: 'url("' + HannaAirTTF + '") format("truetype")',
      },
      {
        fontFamily: 'BM Jua TTF',
        fontStyle: 'normal',
        fontWeight: 500,
        src: 'url("' + JuaTTF + '") format("truetype")',
      },
      {
        fontFamily: 'BM Dohyun TTF',
        fontStyle: 'normal',
        fontWeight: 500,
        src: 'url("' + DohyunTTF + '") format("truetype")',
      },
    ]
  },
};

class RootContainerComponent extends React.Component {
  componentDidMount() {
    this.props.loadUser();
  }

  PrivateRoute = ({component: ChildComponent, ...rest}) => {
    return <Route {...rest} render={props => {
      if (this.props.auth.isLoading) {
        return <em>Loading...</em>;
      } else if (!this.props.auth.isAuthenticated) {
        return <Redirect to="/login" />;
      } else {
        return <ChildComponent {...props} />
      }
    }} />
  };

  render() {
    let { PrivateRoute } = this;
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <PrivateRoute path="/" component={Main} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadUser: () => {
      return dispatch(auth.loadUser());
    }
  }
};

let RootContainer = connect(mapStateToProps, mapDispatchToProps)(RootContainerComponent);

class App extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <RootContainer />
        </Provider>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
