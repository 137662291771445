export const loadMain = () => {
  return (dispatch, getState) => {
    dispatch({type: "MAIN_LOADING"});

    let headers = {
      "Content-Type": "application/json",
    };

    return fetch(`/api/last/main/`, {headers, })
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          }).catch(err => {
            throw err;
          });
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'MAIN_LOADED', main: res.data });
          return res.data;
        } else {
          dispatch({type: 'MAIN_LOAD_FAILED', data: res.data});
          throw res.data;
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
};

export const saveMain = (content) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'SAVE_MAIN_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { main, isLoaded } = getState().main;

    const body = JSON.stringify({
      content
    });

    if (isLoaded && main.id) {
      return fetch(`/api/main/${main.id}/`, {headers, body, method: "PUT"})
        .then(res => {
          if (200 <= res.status && res.status < 300) {
            res.json().then(data => {
              dispatch({type: 'SAVE_MAIN_SUCCESS', data});
              dispatch(loadMain());
              return data;
            }).catch(err => {
              throw err;
            });
          } else {
            dispatch({type: "SAVE_MAIN_FAILURE", data: res.data});
            throw res.data;
          }
        }).catch(err => {
          dispatch({type: "SAVE_MAIN_FAILURE", data: {'detail': 'Server Error'}});
        });
    } else {
      return fetch(`/api/main/`, {headers, body, method: "POST"})
        .then(res => {
          if (200 <= res.status && res.status < 300) {
            res.json().then(data => {
              dispatch({type: 'SAVE_MAIN_SUCCESS', data});
              dispatch(loadMain());
              return data;
            }).catch(err => {
              throw err;
            });
          } else {
            dispatch({type: "SAVE_MAIN_FAILURE", data: res.data});
            throw res.data;
          }
        }).catch(err => {
          dispatch({type: "SAVE_MAIN_FAILURE", data: {'detail': 'Server Error'}});
        });
    }
  };
};
