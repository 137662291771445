import Cookie from 'js-cookie';

export const register = (username, nickname, password, isStaff) => {
  return (dispatch, getState) => {
    const csrftoken = Cookie.get('csrftoken');
    const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken
    };
    const body = JSON.stringify({username, nickname, password, is_staff: isStaff});

    return fetch("/api/auth/register/", {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            dispatch({type: 'REGISTRATION_SUCCESSFUL', data});
            return data;
          });
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: "REGISTRATION_ERROR", data: res.data});
          throw res.data;
        } else {
          dispatch({type: "REGISTRATION_FAILED", data: res.data});
          throw res.data;
        }
      });
  }
};
