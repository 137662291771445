import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { admin, session } from '../actions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  toggleContainer: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: `${theme.spacing.unit}px 0`,
  },
  item: {
    padding: theme.spacing.unit * 2,
  },
  button: {
  },
  toggleButton: {
    padding: '4px 24px',
    height: 48,
  }
});

class ClientBasic extends React.Component {
  state = {
    username: '',
    password: '',
    labelWidth: 30,
    open: false,
    messages: [],
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
    this.setState({username: this.props.selectedUser.username});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedUser !== prevProps.selectedUser && this.props.selectedUser) {
      this.setState({username: this.props.selectedUser.username});
    }
  }

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  };

  updateUser = () => {
    const { selectedUser } = this.props;
    const { username, password } = this.state;
    this.props.updateUser(
      selectedUser.id,
      username,
      password,
      null
    );
    this.openSnackbar(`The user '${username}' is updated successfully.`);
    this.props.updateSessionTime();
  };

  terminateUser = () => {
    const { selectedUser } = this.props;
    this.props.updateUser(
      selectedUser.id,
      '',
      '',
      false
    )
    this.props.updateSessionTime();
  };

  activateUser = () => {
    const { selectedUser } = this.props;
    this.props.updateUser(
      selectedUser.id,
      '',
      '',
      true
    )
    this.props.updateSessionTime();
  };

  render() {
    const { classes, selectedUser } = this.props;
    const { username, password } = this.state;

    if (selectedUser === null || selectedUser === undefined) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={classes.box}>
          <div className={classes.item}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-nickname"
              >
                Name
              </InputLabel>
              <OutlinedInput
                disabled
                labelWidth={this.state.labelWidth}
                name="nickname"
                id="outlined-nickname"
                value={selectedUser.nickname}
              />
            </FormControl>
          </div>
          <div className={classes.item}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-username"
              >
                ID
              </InputLabel>
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="username"
                id="outlined-username"
                value={username}
                onChange={this.handleChange}
              />
            </FormControl>
          </div>
          <div className={classes.item}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-password"
              >
                Password
              </InputLabel>
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="password"
                id="outlined-password"
                value={password}
                onChange={this.handleChange}
              />
            </FormControl>
          </div>

          <div className={classes.item}>
            <Button className={classes.button} variant="outlined" color="primary" onClick={this.updateUser}>Save</Button>
            {selectedUser.is_active ? (
              <Button style={{marginLeft: 16}} className={classes.button} variant="outlined" color="primary" onClick={this.terminateUser}>Terminate</Button>
            ) : (
              <Button style={{marginLeft: 16}} className={classes.button} variant="outlined" color="primary" onClick={this.activateUser}>Activate</Button>
            )}
          </div>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={2000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

      </React.Fragment>
    );
  }
}

ClientBasic.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedUser: state.admin.selectedUser,
    selectedConfig: state.admin.selectedConfig,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (userId, username, password, is_active) => dispatch(admin.updateUser(userId, username, password, is_active)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientBasic));