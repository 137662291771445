import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import { session } from '../actions';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
    }
  },
  itemText: {
    letterSpacing: '0.02857em',
    lineHeight: '1.75',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.8)'
  },
  badge: {
    top: '50%',
    right: theme.spacing.unit,
    borderRadius: 4,
    backgroundColor: '#fbee73',
  }
});

class IncomeMenu extends React.Component {
  state = {
    selectedIndex: 0,
  };

  handleListItemClick = (event, index) => {
    this.setState({ selectedIndex: index });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(index);
    }
    this.props.updateSessionTime();
  };

  render() {
    const { classes, disabled, selectedConfig } = this.props;
    const { selectedIndex } = this.state;
    const { businessTypeBs, interests, others } = this.props;
    const config = selectedConfig ? selectedConfig : this.props.config;
    let business;
    if (config && config.json && config.json.income && config.json.income.incomeType) {
      if (config.json.income.incomeType === 'A') {
        // TODO : businessTypeA와 Payroll Wage는 업데이트 로그 기준
        // business = businessTypeAs;
      } else if (config.json.income.incomeType === 'B') {
        business = businessTypeBs;
      }
    }

    return (
      <div className={classes.root}>
        <List component="nav">
          <ListItem
            button
            disabled={disabled}
            selected={!disabled && selectedIndex === 0}
            onClick={event => this.handleListItemClick(event, 0)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="Business" />
            {business && <Badge badgeContent={business.length} classes={{badge: classes.badge}}>{""}</Badge>}
          </ListItem>
          <ListItem
            button
            disabled={disabled}
            selected={!disabled && selectedIndex === 1}
            onClick={event => this.handleListItemClick(event, 1)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="Interest" />
            {interests && <Badge badgeContent={interests.length} classes={{badge: classes.badge}}>{""}</Badge>}
          </ListItem>
          <ListItem
            button
            disabled={disabled}
            selected={!disabled && selectedIndex === 2}
            onClick={event => this.handleListItemClick(event, 2)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="Other" />
            {others && <Badge badgeContent={others.length} classes={{badge: classes.badge}}>{""}</Badge>}
          </ListItem>
        </List>
      </div>
    );
  }
}

IncomeMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    config: state.auth.config,
    selectedConfig: state.admin.selectedConfig,
    businessTypeAs: state.income.businessTypeAs,
    businessTypeBs: state.income.businessTypeBs,
    interests: state.income.interests,
    others: state.income.others,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IncomeMenu));