import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import LoginForm from "../components/LoginForm";
import { connect } from "react-redux";
import DeskImage from "../images/desk.jpg";
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import { main, session } from '../actions';
import Logo from '../images/logo.png';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    height: '100vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${DeskImage})`,
    backgroundSize: 'cover',
  },
  box: {
    flex: '0 1 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    width: 'calc(100% - 600px)',
    minWidth: 900,
    height: 'calc(100% - 200px)',
    backgroundColor: 'rgba(255,255,255,0.95)',
    backgroundClip: 'content-box',
    padding: theme.spacing.unit * 3,
    border: '4px solid rgba(255,255,255,0.85)',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 200px)',
      alignItems: 'center',
      minWidth: 300,
      minHeight: 500,
    },
  },
  fab: {
    position: 'absolute',
    left: theme.spacing.unit,
    top: theme.spacing.unit,
    opacity: 0.5,
  },
  left: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  right: {
    flex: '0 1 400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    backgroundColor: 'rgba(173,236,82,0.09)',
    padding: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      flex: '1 1 auto',
    }
  },
  description: {
    padding: theme.spacing.unit * 4,
  },
  logo: {
    padding: theme.spacing.unit * 4,
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 8,
    },
    '@media screen and (max-height: 900px)': {
      display: 'none',
    },
  },
  logoImage: {
    flex: '1 1 auto',
    maxWidth: 320,
    '@media screen and (max-height: 900px)': {
      paddingBottom: theme.spacing.unit * 4,
      maxWidth: 180,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 120,
    },
  },
  body1: {
    fontFamily: "'Spoqa Han Sans', 'Sans-serif'",
    fontSize: '1.2rem',
  },
  title: {
    fontFamily: "'BM Dohyun TTF', 'Sans-serif'",
    fontSize: '2.8rem',
  },
  email: {
    textDecoration: 'none',
    color: '#35a862'
  },
  mobile: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    '@media screen and (max-height: 900px)': {
      display: 'block',
    },
    '& img': {

    },
  },
  content: {
    padding: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sessionTimeoutContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogBox: {
    border: '1px solid #000',
    padding: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 280
    }
  }
});

class LoginPage extends React.Component {
  state = {
    open: false,
    message: '',
    dialogOpen: false,
  };

  componentDidMount() {
    this.props.loadMain();
    if (this.props.isSessionTimeout) {
      this.dialogOpen();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errors !== prevProps.errors && this.props.errors.length > 0) {
      this.setState({open: true, message: this.props.errors[0].message + ' Please check your ID and PW.'});
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.props.isSessionTimeout && nextProps.isSessionTimeout) {
      this.dialogOpen();
    }
  }

  dialogOpen = () => {
    this.setState({dialogOpen: true});
  };

  dialogClose = () => {
    this.props.updateSessionTime();
    this.setState({dialogOpen: false});
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.box}>
            <div className={classes.left}>
              <div className={classes.logo}>
                <img className={classes.logoImage} src={Logo} alt="logo" />
              </div>
              <div className={classes.description}>
                <div className={classes.body1}>
                  {this.props.isLoaded && this.props.main.content && (
                    <div dangerouslySetInnerHTML={{__html: this.props.main.content}} />
                  )}
                </div>
              </div>
            </div>
            <div className={classes.right}>
              <div className={cn(classes.logo, classes.mobile)}>
                <img className={classes.logoImage} src={Logo} alt="logo" />
              </div>
              <LoginForm />
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.message}</span>} />

        <Dialog
          open={this.state.dialogOpen}
          onClose={this.props.dialogClose}
        >
          <DialogContent className={classes.sessionTimeoutContent}>
            <div className={classes.dialogBox} style={{textAlign: 'center'}}>
              <Typography>
                30분 동안 사용이 없어<br />세션이 종료되었습니다.<br />감사합니다.
              </Typography>
            </div>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <Button variant="outlined" size="large" fullWidth onClick={this.dialogClose}>
                Close
              </Button>
            </FormControl>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return {field, message: state.auth.errors[field]};
    });
  }
  return {
    errors,
    isLoaded: state.main.main,
    main: state.main.main,
    isSessionTimeout: state.session.isSessionTimeout,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadMain: () => dispatch(main.loadMain()),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginPage));
