import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    marginBottom: theme.spacing.unit,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
    }
  },
  itemText: {
    letterSpacing: '0.02857em',
    lineHeight: '1.75',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.8)'
  }
});

class UserListMenu extends React.Component {
  state = {
  };

  handleListItemClick = (event) => {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick();
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <List component="nav" style={{ padding: 0 }}>
          <ListItem
            button
            onClick={event => this.handleListItemClick(event)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="User List" />
          </ListItem>
        </List>
      </div>
    );
  }
}

UserListMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserListMenu);
