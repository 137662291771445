import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import { connect } from "react-redux";
import { auth } from "../actions";
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import FormControl from '@material-ui/core/FormControl/index';
import InputLabel from '@material-ui/core/InputLabel/index';
import OutlinedInput from '@material-ui/core/OutlinedInput/index';
import { Redirect } from "react-router-dom";
import grey from "@material-ui/core/colors/grey";
import Button from "@material-ui/core/Button/index";

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  cssLabel: {
    '&$cssFocused': {
      color: grey[700],
    },
    fontFamily: "'Spoqa Han Sans', 'Sans-serif'",
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: grey[500],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: grey[500],
    },
    '&$cssFocused': {
      color: grey[500]
    },
    fontFamily: "'Spoqa Han Sans', 'Sans-serif'",
  },
  cssOutlinedPasswordInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: grey[500],
    },
    '&$cssFocused': {
      fontFamily: "'Spoqa Han Sans', 'Sans-serif'",
      color: grey[500]
    },
    fontFamily: "'Spoqa Han Sans', 'Sans-serif'",
    color: grey[500]
  },
  notchedOutline: {},
  button: {
    margin: theme.spacing.unit,
    fontFamily: "'Spoqa Han Sans', 'Sans-serif'",
    color: grey[700],
    borderColor: grey[500],
    fontWeight: 600,
    height: 56,
    '&:hover': {
      borderColor: '#000',
    }
  }
});

class LoginForm extends React.Component {
  state = {
    username: "",
    password: "",
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  };

  catchReturn = (e) => {
    if (e.key === 'Enter') {
      this.onSubmit(e);
    }
  };

  onFailure = (response) => {
    console.log(response);
  };

  render() {
    const { classes } = this.props;
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    return (
      <div className={classes.root}>
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmit}
        >
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel
              ref={ref => {
                this.labelRef = ReactDOM.findDOMNode(ref);
              }}
              classes={{
                root: classes.cssLabel,
              }}
              htmlFor="username-outlined"
            >
              Username
            </InputLabel>
            <OutlinedInput
              id="username-outlined"
              onChange={e => this.setState({username: e.target.value})}
              labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
              onKeyPress={this.catchReturn}
              classes={{
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }}
              fullWidth
              autoComplete="email"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel
              ref={ref => {
                this.labelRef = ReactDOM.findDOMNode(ref);
              }}
              classes={{
                root: classes.cssLabel,
              }}
              htmlFor="password-outlined"
            >
              Password
            </InputLabel>
            <OutlinedInput
              id="password-outlined"
              type="password"
              onChange={e => this.setState({password: e.target.value})}
              labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
              onKeyPress={this.catchReturn}
              classes={{
                root: classes.cssOutlinedPasswordInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }}
              fullWidth
              autoComplete="new-password"
            />
          </FormControl>
          <Button variant="outlined"
                  size="large"
                  color="primary"
                  className={classNames(classes.button)}
                  onClick={this.onSubmit}>
            Login
          </Button>
        </form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return {field, message: state.auth.errors[field]};
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => {
      return dispatch(auth.login(username, password));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginForm));
