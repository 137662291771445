import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { admin, session } from '../actions';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  toggleContainer: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: `${theme.spacing.unit}px 0`,
  },
  item: {
    padding: theme.spacing.unit * 2,
  },
  button: {
  },
  toggleButton: {
    padding: '4px 24px',
    height: 48,
  }
});

class ClientPayroll extends React.Component {
  state = {
    payrollType: 'W',
    open: false,
    messages: [],
  };

  componentDidMount() {
    const { selectedConfig } = this.props;
    if (selectedConfig && selectedConfig.json && selectedConfig.json.payroll) {
      this.setState({payrollType: selectedConfig.json.payroll.payrollType});
    }
  }

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  handlePayrollType = (event, payrollType) => {
    this.setState({payrollType});
  };

  saveConfig = () => {
    const config = {...this.props.selectedConfig};
    const { payrollType } = this.state;
    config.json.payroll = {
      payrollType
    };
    this.props.updateConfig(config);
    this.props.saveConfig();
    this.openSnackbar('Saved successfully');
    this.props.updateSessionTime();
  };

  render() {
    const { classes } = this.props;
    if (!!!this.props.selectedConfig) {
      return null;
    }
    const { payrollType } = this.state;

    return (
      <React.Fragment>
        <div className={classes.box}>
          <div className={classes.toggleContainer}>
            <ToggleButtonGroup value={payrollType} exclusive
                               onChange={this.handlePayrollType}>
              <ToggleButton className={classes.toggleButton} value="W">
                Weekly
              </ToggleButton>
              <ToggleButton className={classes.toggleButton} value="F">
                Fortnightly
              </ToggleButton>
              <ToggleButton className={classes.toggleButton} value="M">
                Monthly
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className={classes.item}>
            <Button className={classes.button}
                    size="large" variant="outlined" color="primary"
                    onClick={this.saveConfig}>Save</Button>
          </div>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={2000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />


      </React.Fragment>
    );
  }
}

ClientPayroll.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedUser: state.admin.selectedUser,
    selectedConfig: state.admin.selectedConfig,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateConfig: (config) => {
      return dispatch(admin.updateConfig(config));
    },
    saveConfig: () => {
      return dispatch(admin.saveConfig());
    },
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientPayroll));