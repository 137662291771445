import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auth, income, period, session } from '../actions';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/es/withMobileDialog';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IncomeInterestGrid from './IncomeInterestGrid';
import DayPicker from 'react-day-picker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { getTotal } from '../utils';
import XLSX from 'xlsx';


const styles = theme => ({
  categories: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'nowrap',
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'nowrap',
    }
  },
  actions: {
    marginTop: theme.spacing.unit * 2,
  },
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  namedBox: {
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  boxMargin: {
    marginTop: 32,
  },
  boxName: {
    margin: '-32px 8px 8px 8px',
    borderRadius: 4,
    padding: '8px 16px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(59, 132, 3, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(239, 239, 239, 0.4)',
    '& > span': {
      color: '#26710c',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
  },
  formControl: {
    flex: '1 1 auto',
    margin: theme.spacing.unit,
    minWidth: 140,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  button: {
    padding: '14px 24px',
    backgroundColor: '#e2f1d6',
    '&:hover': {
      backgroundColor: '#a0d873',
    },
    color: '#330809',
  },
  fab: {
    margin: theme.spacing.unit,
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.15)',
  },
  calendarFab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: 40,
    height: 40,
    '& svg': {
      width: '24px !important',
      height: 24,
    },
  },
  picker: {
    display: 'flex',
    justifyContent: 'center',
  },
});

class IncomeInterest extends React.Component {
  state = {
    labelWidth: 0,
    date: '',
    amount: '',
    open: false,
    messages: [],
    dateDialogOpen: false,
    fromMonth: null,
    toMonth: null
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });

    this.props.loadInterests();
    this.props.loadUserLocks();
    this.props.loadConfig();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errors !== prevProps.errors && this.props.errors.length > 0) {
      for (const error of this.props.errors) {
        this.openSnackbar(error.message);
        this.props.resetError();
      }
    }
    if (this.props.selectedUser !== prevProps.selectedUser && this.props.selectedUser) {
      this.props.loadInterests();
      this.props.loadUserLocks();
    }
    if (this.props.year !== prevProps.year || this.props.quarter !== prevProps.quarter) {
      this.props.loadInterests();
      this.props.loadUserLocks();
    }
  }

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  downloadCSV = () => {
    const username = this.props.selectedUser && this.props.selectedUser.username ? this.props.selectedUser.username : this.props.user.username;
    const filename = `income_interest_${username}_${this.props.year}_${this.props.quarter}.xlsx`;
    const workbook = XLSX.utils.table_to_book(document.getElementById('sheetjs'));
    XLSX.writeFile(workbook, filename);
  };

  unlockPage = (lockId) => () => {
    this.props.deleteUserLock(lockId);
    this.props.updateSessionTime();
  };

  lockPage = () => {
    this.props.addUserLock(
      this.props.year, this.props.quarter,
      'Interest');
    this.props.updateSessionTime();
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addInterest = () => {
    const { amount } = this.state;
    const { start, end } = this.props;

    const date = this.state.date ? moment(this.state.date, 'DD/MM/YYYY') : moment(end);
    if (!date.isValid()) {
      this.openSnackbar('Date is invalid');
      return;
    }

    if (moment(date).diff(moment(start), 'days') < 0 || moment(date).diff(moment(end), 'days') > 0) {
      this.openSnackbar('Date is out of range.');
      return;
    }

    if (!amount) {
      this.openSnackbar('Amount can not be blank.');
      return;
    }

    this.props.addInterest(
      date,
      Number(amount),
    );

    this.setState({
      amount: '',
    });
    this.props.updateSessionTime();
  };

  handleDateDialogOpen = () => {
    const { start, end } = this.props;

    this.setState({
      fromMonth: moment(start).toDate(),
      toMonth: moment(end).toDate(),
      dateDialogOpen: true
    });
  };

  handleDateDialogClose = () => {
    this.setState({dateDialogOpen: false});
  };

  onDayClick = (day) => {
    this.setState({
      dateDialogOpen: false,
      date: moment(day).format('DD/MM/YYYY'),
    })
  };

  render() {
    const { classes, fullScreen } = this.props;
    const { date, amount } = this.state;
    let isUserLocked = false;
    let lockId = 0;
    if (this.props.isUserLocked) {
      for (const userLock of this.props.userLocks) {
        if (userLock.year === this.props.year &&
          userLock.quarter === this.props.quarter &&
          userLock.model_name === 'Interest') {
          isUserLocked = true;
          lockId = userLock.id;
        }
      }
    }

    return (
      <React.Fragment>
        <div className={cn(classes.box, classes.namedBox)}>
          <div className={classes.boxName}><span>Input</span></div>
          <div className={classes.form}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-date"
              >
                Date
              </InputLabel>
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="date"
                value={date}
                id="outlined-date"
                onClick={this.handleDateDialogOpen}
                endAdornment={
                  <InputAdornment position="end">
                    <Fab className={classes.calendarFab}
                         onClick={this.handleDateDialogOpen}>
                      <FontAwesomeIcon icon={"calendar-alt"} />
                    </Fab>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-amount"
              >
                Amount
              </InputLabel>
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                type="number"
                name="amount"
                id="outlined-amount"
                value={amount}
                onChange={this.handleChange}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <Button disabled={isUserLocked || this.props.isLocked || this.props.isAllLocked}
                      variant="outlined" size="large" className={classes.button}
                      onClick={this.addInterest}>Add</Button>
            </FormControl>
          </div>
        </div>

        <IncomeInterestGrid />

        <div className={classes.actions}>
          <Tooltip title='Download Excel File'>
            <Fab aria-label="Download Excel File" className={classes.fab} onClick={this.downloadCSV}>
              <FontAwesomeIcon icon="file-excel" size="2x" />
            </Fab>
          </Tooltip>
          {isUserLocked ? (
            <Tooltip title='Unlock this Page'>
              <Fab disabled={!this.props.user.is_staff} aria-label='Unlock this Page' className={classes.fab} onClick={this.unlockPage(lockId)}>
                <FontAwesomeIcon icon="lock" size="2x" />
              </Fab>
            </Tooltip>
          ) : (
            <Tooltip title='Lock this Page'>
              <Fab aria-label='Lock this Page' className={classes.fab} onClick={this.lockPage}>
                <FontAwesomeIcon icon="unlock" size="2x" />
              </Fab>
            </Tooltip>
          )}
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        <Dialog
          fullScreen={fullScreen}
          fullWidth={!fullScreen}
          maxWidth={fullScreen ? false : 'md'}
          open={this.state.dateDialogOpen}
          onClose={this.handleDateDialogClose}
        >
          <DialogContent className={classes.picker}>
            <DayPicker
              initialMonth={this.state.fromMonth}
              fromMonth={this.state.fromMonth}
              toMonth={this.state.toMonth}
              onDayClick={this.onDayClick}
              numberOfMonths={3}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDateDialogClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <table id="sheetjs" style={{display: 'none'}}>
          <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
          </tr>
          </thead>
          <tbody>
          {this.props.interests && this.props.interests.length > 0 &&
          this.props.interests.map(row => {
            return (
                <tr key={row.id}>
                  <td>{moment(row.date).format('YYYY-MM-DD')}</td>
                  <td>{row.amount}</td>
                </tr>
            );
          })
          }
          <tr>
            <td>Total</td>
            <td>{getTotal(this.props.interests, 'amount')}</td>
          </tr>
          </tbody>
        </table>

      </React.Fragment>
    );
  }
}

IncomeInterest.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let errors = [];
  if (state.income.errors) {
    errors = Object.keys(state.income.errors).map(field => {
      return {field, message: state.income.errors[field]};
    });
  }
  return {
    errors,
    interests: state.income.interests,
    config: state.auth.config,
    selectedConfig: state.admin.selectedConfig,
    selectedUser: state.admin.selectedUser,
    user: state.auth.user,
    start: state.period.start,
    end: state.period.end,
    year: state.period.year,
    quarter: state.period.quarter,
    isUserLocked: state.period.isUserLocked,
    isAllLocked: state.period.isAllLocked,
    isLocked: state.period.isLocked,
    userLocks: state.period.userLocks,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    addInterest: (...args) => dispatch(income.addInterest(...args)),
    loadInterests: () => dispatch(income.loadInterests()),
    deleteInterest: (id) => dispatch(income.deleteInterest(id)),
    loadConfig: () => dispatch(auth.loadConfig()),
    resetError: () => dispatch(income.resetError()),
    loadUserLocks: () => dispatch(period.loadUserLocks()),
    addUserLock: (year, quarter, model_name) => dispatch(period.addUserLock(year, quarter, model_name)),
    deleteUserLock: (id) => dispatch(period.deleteUserLock(id)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(IncomeInterest)));
