import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';


const styles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 280,
    maxWidth: 600,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 280
    }
  },
  content: {
    padding: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    minWidth: 600,
    maxWidth: 600,
    border: '1px solid #000',
    padding: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 280
    }
  }
});


function Transition(props) {
  return <Slide direction="down" {...props} />;
}


class NoticeDialog extends React.Component {
  state = {
    open: false,
    labelWidth: 0,
  };

  componentDidMount() {
    this.props.setOpen(this.open);
  }

  dontShowToday = () => {
    localStorage.setItem('dontShowToday', moment().format());
    this.close();
  };

  close = () => {
    this.setState({ open: false });
    this.props.onClose();
  };

  open = () => {
    this.setState({ open: true });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value});
  };

  render() {
    const {
      classes,
      notice,
      isLoaded,
    } = this.props;

    return (
      <Dialog
        fullScreen
        open={this.state.open}
        onClose={this.close}
        TransitionComponent={Transition}
      >
        <DialogTitle>Notice</DialogTitle>
        <DialogContent className={classes.content}>
          {isLoaded && (notice.content ? (
            <div className={classes.box}>
              <div dangerouslySetInnerHTML={{__html: notice.content}} />
            </div>
          ) : (
            <div className={classes.box} style={{textAlign: 'center'}}>
              <Typography>작성된 공지가 없습니다.</Typography>
            </div>
          ))}
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <Button variant="outlined" size="large" fullWidth onClick={this.dontShowToday}>
              Don&apos;t show today
            </Button>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <Button variant="outlined" size="large" fullWidth onClick={this.close}>
              Close
            </Button>
          </FormControl>
        </DialogContent>
      </Dialog>
    );
  }
}

NoticeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoaded: state.notice.isLoaded,
    isLoading: state.notice.isLoading,
    notice: state.notice.notice,
  }
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NoticeDialog));
