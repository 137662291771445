import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { benchmark, session } from '../actions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { getSelectableYears } from '../utils';
import MenuItem from '@material-ui/core/MenuItem';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import cn from 'classnames';
import moment from 'moment';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';


const styles = theme => ({
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  namedBox: {
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  boxMargin: {
    marginTop: 16,
  },
  boxName: {
    margin: '-32px 8px 8px 8px',
    borderRadius: 4,
    padding: '8px 16px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(59, 132, 3, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(239, 239, 239, 0.4)',
    '& > span': {
      color: '#26710c',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
  },
  item: {
    padding: theme.spacing.unit,
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  table: {
    '& th': {
      padding: '4px 24px 4px 24px',
      backgroundColor: '#EDF5E6',
    },
    '& thead tr:nth-child(-n+3) th': {
      textAlign: 'center',
    },
    '& td': {
      textAlign: 'right',
    },
    '& td input': {
      textAlign: 'right',
    }
  },
  button: {
    padding: '14px 24px',
    backgroundColor: 'white',
  },
  bootstrapInput: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '5px 6px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  input: {
    maxWidth: 140
  }
});

class ClientBenchmark extends React.Component {
  state = {
    year: '',
    benchmark: null,
    labelWidth: 30,
    messages: [],
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
    const year = moment(this.props.fiscalEnd).year();
    this.props.loadBenchmark(year);
    this.setState({year});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errors !== prevProps.errors && this.props.errors.length > 0) {
      for (const error of this.props.errors) {
        this.openSnackbar(error.message);
        this.props.resetError();
      }
    }
  }

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.benchmark !== state.benchmark) {
      return { benchmark: props.benchmark };
    }
    return null;
  }

  handleYearChange = (event) => {
    this.setState({ [event.target.name]: event.target.value});
    this.props.loadBenchmark(Number(event.target.value));
  };

  updateBenchmark = () => {
    const { benchmark } = this.state;
    if (benchmark.json) {
      for (const values of Object.values(benchmark.json)) {
        for (const value of values) {
          if (isNaN(Number(value))) {
            this.openSnackbar('Invalid numeric data.');
            this.setState({benchmark: this.props.benchmark});
            return;
          }
        }
      }
      this.props.updateBenchmark(benchmark);
      this.openSnackbar('Saved successfully.');
    }
    this.props.updateSessionTime();
  };

  handleChange = (name, index) => (event) => {
    const { benchmark } = this.state;
    if (!benchmark || !benchmark.json) {
      return;
    }
    benchmark.json[name][index] = event.target.value;
    this.setState({benchmark});
  };

  render() {
    const { classes } = this.props;
    const { year, benchmark, labelWidth } = this.state;

    return (
      <React.Fragment>
        <div className={cn(classes.box, classes.namedBox)}>
          <div className={classes.boxName}><span>Fiscal Year</span></div>
          <div className={classes.item}>
            <div className={classes.form}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-year"
                >
                  Year
                </InputLabel>
                <Select
                  value={year}
                  onChange={this.handleYearChange}
                  input={
                    <OutlinedInput
                      fullWidth
                      labelWidth={labelWidth}
                      name="year"
                      id="outlined-year"
                    />
                  }
                >
                  {getSelectableYears().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className={cn(classes.box, classes.boxMargin)}>
          <div className={classes.item}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell component="th" rowSpan={3}>Key Benchmark</TableCell>
                  <TableCell component="th" colSpan={2}>ATO Standard</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" colSpan={2}>Average Turnover</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td">
                    <InputBase
                      className={classes.input}
                      id={`key-0`}
                      value={benchmark.json ? benchmark.json.key[0] : ''}
                      onChange={this.handleChange('key', 0)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                  <TableCell component="td">
                    <InputBase
                      className={classes.input}
                      id={`key-1`}
                      value={benchmark.json ? benchmark.json.key[1] : ''}
                      onChange={this.handleChange('key', 1)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th">Cost of Sales</TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`cost_of_sales-0`}
                      value={benchmark.json ? benchmark.json.cost_of_sales[0] : ''}
                      onChange={this.handleChange('cost_of_sales', 0)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`cost_of_sales-1`}
                      value={benchmark.json ? benchmark.json.cost_of_sales[1] : ''}
                      onChange={this.handleChange('cost_of_sales', 1)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Total Expenses</TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`total_expenses-0`}
                      value={benchmark.json ? benchmark.json.total_expenses[0] : ''}
                      onChange={this.handleChange('total_expenses', 0)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`total_expenses-1`}
                      value={benchmark.json ? benchmark.json.total_expenses[1] : ''}
                      onChange={this.handleChange('total_expenses', 1)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Non Capital Purchase</TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`non_capital_purchase-0`}
                      value={benchmark.json ? benchmark.json.non_capital_purchase[0] : ''}
                      onChange={this.handleChange('non_capital_purchase', 0)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`non_capital_purchase-1`}
                      value={benchmark.json ? benchmark.json.non_capital_purchase[1] : ''}
                      onChange={this.handleChange('non_capital_purchase', 1)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Labour</TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`labour-0`}
                      value={benchmark.json ? benchmark.json.labour[0] : ''}
                      onChange={this.handleChange('labour', 0)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`labour-1`}
                      value={benchmark.json ? benchmark.json.labour[1] : ''}
                      onChange={this.handleChange('labour', 1)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Rent</TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`rent-0`}
                      value={benchmark.json ? benchmark.json.rent[0] : ''}
                      onChange={this.handleChange('rent', 0)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`rent-1`}
                      value={benchmark.json ? benchmark.json.rent[1] : ''}
                      onChange={this.handleChange('rent', 1)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Cash</TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`cash-0`}
                      value={benchmark.json ? benchmark.json.cash[0] : ''}
                      onChange={this.handleChange('cash', 0)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <InputBase
                      className={classes.input}
                      id={`cash-1`}
                      value={benchmark.json ? benchmark.json.cash[1] : ''}
                      onChange={this.handleChange('cash', 1)}
                      classes={{
                        input: classes.bootstrapInput,
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
        <FormControl style={{marginTop: 16}} variant="outlined" className={classes.formControl} fullWidth>
          <Button className={classes.button} variant="outlined" size="large" color="primary" onClick={this.updateBenchmark} fullWidth>Save</Button>
        </FormControl>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={2000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </React.Fragment>
    );
  }
}

ClientBenchmark.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let errors = [];
  if (state.benchmark.errors) {
    errors = Object.keys(state.benchmark.errors).map(field => {
      return {field, message: state.benchmark.errors[field]};
    });
  }
  return {
    errors,
    fiscalEnd: state.period.fiscalEnd,
    benchmark: state.benchmark.benchmark,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadBenchmark: (year) => dispatch(benchmark.loadBenchmark(year)),
    updateBenchmark: (b) => dispatch(benchmark.updateBenchmark(b)),
    resetError: () => dispatch(benchmark.resetError()),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientBenchmark));