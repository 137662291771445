import moment from 'moment';

export const loadEmployees = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_EMPLOYEES_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { start } = getState().period;
    const body = JSON.stringify({
      user: userId,
      start,
    });

    return fetch("/api/clone_employee/", {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_EMPLOYEES_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_EMPLOYEES_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_EMPLOYEES_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const updateEmployee = (id, last_name, first_name, gender, dob, tfn, visa, commerce, terminate, owner, address, comment, expiration, user_id) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPDATE_EMPLOYEE_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const body = JSON.stringify({
      id,
      last_name: last_name.toUpperCase(),
      first_name: first_name.toUpperCase(),
      gender,
      dob,
      tfn: tfn.replace(/\s+/g, ''),
      visa,
      commerce,
      terminate,
      owner,
      address,
      comment,
      expiration,
      user: user_id,
    });

    return fetch(`/api/employee/${id}/`, {headers, body, method: "PUT"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPDATE_EMPLOYEE_SUCCESS', data});
            dispatch(loadEmployees());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "UPDATE_EMPLOYEE_FAILURE", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "UPDATE_EMPLOYEE_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const deleteEmployee = (id) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'DELETE_EMPLOYEE_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/employee/${id}/`, {headers, method: "DELETE"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          dispatch({type: 'DELETE_EMPLOYEE_SUCCESS'});
          dispatch(loadEmployees());
        } else {
          dispatch({type: "DELETE_EMPLOYEE_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "DELETE_EMPLOYEE_FAILURE", data: {'detail': 'Can\'t delete Employee. You may have Wage data in this year for the Employee.'}});
      });
  };
};


export const addEmployee = (last_name, first_name, gender, dob, tfn, visa, commerce, terminate, owner, address, comment, expiration) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'ADD_EMPLOYEE_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const body = JSON.stringify({
      last_name,
      first_name,
      gender,
      dob,
      tfn,
      visa,
      commerce,
      terminate,
      owner,
      address,
      comment,
      expiration,
      user: userId,
    });

    return fetch(`/api/employee/`, {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'ADD_EMPLOYEE_SUCCESS', data});
            dispatch(loadEmployees());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "ADD_EMPLOYEE_FAILURE", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "ADD_EMPLOYEE_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const loadSubs = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_SUBS_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { fiscalEnd } = getState().period;

    return fetch(`/api/sub/?user=${userId}&fiscal_year=${moment(fiscalEnd).year()}`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_SUBS_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_SUBS_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_SUBS_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const updateSub = (id, name, abn, gst, phone, address, comment, user_id) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPDATE_SUB_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { fiscalEnd } = getState().period;

    const body = JSON.stringify({
      name,
      abn,
      gst,
      phone,
      address,
      comment,
      fiscal_year: moment(fiscalEnd).year(),
      user: user_id,
    });

    return fetch(`/api/sub/${id}/`, {headers, body, method: "PUT"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPDATE_SUB_SUCCESS', data});
            dispatch(loadSubs());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "UPDATE_SUB_FAILURE", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "UPDATE_SUB_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const deleteSub = (id) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'DELETE_SUB_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/sub/${id}/`, {headers, method: "DELETE"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          dispatch({type: 'DELETE_SUB_SUCCESS'});
          dispatch(loadSubs());
        } else {
          dispatch({type: "DELETE_SUB_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "DELETE_SUB_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const addSub = (name, abn, gst, phone, address, comment) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'ADD_SUB_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { fiscalEnd } = getState().period;

    const body = JSON.stringify({
      name,
      abn,
      gst,
      phone,
      address,
      comment,
      user: userId,
      fiscal_year: moment(fiscalEnd).year(),
    });

    return fetch(`/api/sub/`, {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'ADD_SUB_SUCCESS', data});
            dispatch(loadSubs());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "ADD_SUB_FAILURE", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "ADD_SUB_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const loadWages = (employee_id) => {
  return (dispatch, getState) => {
    dispatch({type: 'LOAD_WAGES_STARTED'});

    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_WAGES_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let user_id;
    if (user.is_staff && getState().admin.selectedUser) {
      user_id = getState().admin.selectedUser.id;
    } else {
      user_id = user.id;
    }

    const { year, quarter } = getState().period;
    const body = JSON.stringify({
      user: user_id,
      employee: employee_id,
      year: year,
      quarter: quarter,
    });

    return fetch("/api/goc_wage/", {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_WAGES_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_WAGES_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_WAGES_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};

export const resetError = () => {
  return (dispatch, getState) => {
    dispatch({type: 'RESET_ERROR'});
  }
};

export const loadWageTaxes = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_WAGE_TAXES_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch("/api/wage_tax/", {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_WAGE_TAXES_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_WAGE_TAXES_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_WAGE_TAXES_FAILURE", data: {'detail': 'Server Error'}});
      });
  }
};

export const uploadWageTax = (year, wage_type, pond) => {
  return (dispatch, getState) => {
    dispatch({type: 'UPLOAD_WAGE_TAX_START'});
    const headers = {};

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPLOAD_WAGE_TAX_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const file = pond.getFile();
    if (!file) {
      dispatch({type: 'UPLOAD_WAGE_TAX_FAILURE', data: {'detail': 'File can not be null'}});
      return;
    }
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('year', year);
    formData.append('wage_type', wage_type);

    return fetch("/api/upload/wage_tax/", {headers, body: formData, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPLOAD_WAGE_TAX_SUCCESS', data});
            dispatch(loadWageTaxes());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "UPLOAD_WAGE_TAX_FAILURE", data});
            return data;
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "UPLOAD_WAGE_TAX_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};

export const updateWages = (employee_id, wages) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPDATE_WAGES_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let user_id;
    if (user.is_staff && getState().admin.selectedUser) {
      user_id = getState().admin.selectedUser.id;
    } else {
      user_id = user.id;
    }

    const { year, quarter } = getState().period;

    const body = JSON.stringify({
      year,
      quarter,
      user: user_id,
      wages
    });

    return fetch("/api/update/wages/", {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPDATE_WAGES_SUCCESS', data});
            dispatch(loadWages(employee_id));
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "UPDATE_WAGES_FAILURE", data});
            return data;
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "UPDATE_WAGES_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const deleteWage = (id) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'DELETE_WAGE_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/wage/${id}/`, {headers, method: "DELETE"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          dispatch({type: 'DELETE_WAGE_SUCCESS'});
        } else {
          dispatch({type: "DELETE_WAGE_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "DELETE_WAGE_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const loadEmployeeSummaries = (is_quarter) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_EMPLOYEE_SUMMARIES_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { start, end, fiscalStart, fiscalEnd } = getState().period;
    let body;
    if (is_quarter) {
      body = JSON.stringify({
        user: userId,
        start: start,
        end: end,
      });
    } else {
      body = JSON.stringify({
        user: userId,
        start: fiscalStart,
        end: fiscalEnd
      });
    }

    return fetch("/api/employee_summaries/", {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_EMPLOYEE_SUMMARIES_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_EMPLOYEE_SUMMARIES_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_EMPLOYEE_SUMMARIES_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const loadWageYearSummary = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_WAGE_YEAR_SUMMARY_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { fiscalEnd } = getState().period;
    const body = JSON.stringify({
      user: userId,
      fiscal_year: moment(fiscalEnd).year(),
    });

    return fetch("/api/get/wage/year/summary/", {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_WAGE_YEAR_SUMMARY_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_WAGE_YEAR_SUMMARY_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_WAGE_YEAR_SUMMARY_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};
