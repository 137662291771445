export const loadNotice = () => {
  return (dispatch, getState) => {
    dispatch({type: "NOTICE_LOADING"});

    const token = getState().auth.token;

    let headers = {
      "Content-Type": "application/json",
    };


    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: "NOTICE_LOAD_FAILED"});
      return;
    }
    return fetch(`/api/last/notice/`, {headers, })
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          }).catch(err => {
            throw err;
          });
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'NOTICE_LOADED', notice: res.data });
          return res.data;
        } else {
          dispatch({type: 'NOTICE_LOAD_FAILED', data: res.data});
          throw res.data;
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
};

export const saveNotice = (content) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'SAVE_NOTICE_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { notice, isLoaded } = getState().notice;

    const body = JSON.stringify({
      content
    });

    if (isLoaded && notice.id) {
      return fetch(`/api/notice/${notice.id}/`, {headers, body, method: "PUT"})
        .then(res => {
          if (200 <= res.status && res.status < 300) {
            res.json().then(data => {
              dispatch({type: 'SAVE_NOTICE_SUCCESS', data});
              dispatch(loadNotice());
              return data;
            }).catch(err => {
              throw err;
            });
          } else {
            dispatch({type: "SAVE_NOTICE_FAILURE", data: res.data});
            throw res.data;
          }
        }).catch(err => {
          dispatch({type: "SAVE_NOTICE_FAILURE", data: {'detail': 'Server Error'}});
        });
    } else {
      return fetch(`/api/notice/`, {headers, body, method: "POST"})
        .then(res => {
          if (200 <= res.status && res.status < 300) {
            res.json().then(data => {
              dispatch({type: 'SAVE_NOTICE_SUCCESS', data});
              dispatch(loadNotice());
              return data;
            }).catch(err => {
              throw err;
            });
          } else {
            dispatch({type: "SAVE_NOTICE_FAILURE", data: res.data});
            throw res.data;
          }
        }).catch(err => {
          dispatch({type: "SAVE_NOTICE_FAILURE", data: {'detail': 'Server Error'}});
        });
    }
  };
};
