const initialState = {
  token: sessionStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: true,
  user: null,
  config: null,
  errors: {},
  sessionTime: 5,
  isSessionTimeout: false,
};


export default function auth(state=initialState, action) {

  switch (action.type) {

    case 'USER_LOADING':
      return {...state, isLoading: true};

    case 'USER_LOADED':
      return {...state, isAuthenticated: true, isLoading: false, user: action.user};

    case 'LOGIN_SUCCESSFUL':
      sessionStorage.setItem("token", action.data.token);
      return {...state, ...action.data, isAuthenticated: true, isLoading: false, errors: null};

    case 'UNAUTHENTICATED':
      sessionStorage.removeItem("token");
      return {...state, errors: null, token: null, user: null,
        isAuthenticated: false, isLoading: false};

    case 'AUTHENTICATION_ERROR':
    case 'LOGIN_FAILED':
    case 'LOGOUT_SUCCESSFUL':
      sessionStorage.removeItem("token");
      return {...state, errors: action.data, token: null, user: null,
        isAuthenticated: false, isLoading: false};

    case 'LOAD_CONFIG_SUCCESS':
      return {...state, config: action.config};

    case 'LOAD_CONFIG_FAILURE':
      return {...state, config: null};

    case 'UPDATE_SESSION_TIME':
      return {...state, sessionTime: 1800};

    case 'DECREASE_SESSION_TIME':
      const sessionTime = state.sessionTime - 1 >= 0 ? state.sessionTime - 1 : 0;
      const isSessionTimeout = sessionTime === 0;
      return {...state, sessionTime, isSessionTimeout};

    default:
      return state;
  }
}
