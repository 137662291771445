import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { benchmark } from '../actions';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/es/withMobileDialog';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { currencyFormat } from '../utils';
import XLSX from 'xlsx';


const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'nowrap',
    }
  },
  actions: {
    marginTop: theme.spacing.unit * 2,
  },
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  namedBox: {
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  boxMargin: {
    marginTop: 32,
  },
  boxName: {
    margin: '-32px 8px 8px 8px',
    borderRadius: 4,
    padding: '8px 16px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(59, 132, 3, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(239, 239, 239, 0.4)',
    '& > span': {
      color: '#26710c',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
  },
  formControl: {
    flex: '1 1 auto',
    margin: theme.spacing.unit,
    minWidth: 140,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  button: {
    padding: '14px 24px',
  },
  fab: {
    margin: theme.spacing.unit,
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.15)',
  },
  toggleContainer: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: `${theme.spacing.unit}px 0`,
  },
  toggleButton: {
    padding: '4px 24px',
    height: 48,
  },
  table: {
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
    '& thead': {
      backgroundColor: '#8bc34a91',
    },
    '& tr:nth-child(odd)': {
      backgroundColor: '#8bc34a21',
    },
    '& td': {
      padding: '4px 24px',
      border: '1px solid #ddd',
    },
    '& th': {
      padding: '4px 24px',
      border: '1px solid #ddd',
    }
  },
  total: {
    backgroundColor: '#fbee7391 !important',
  },
});

class SummaryPL extends React.Component {
  state = {
    quarterView: true,
    messages: [],
  };

  componentDidMount() {
    if (this.state.quarterView) {
      this.props.loadPL();
    } else {
      this.props.loadPLYear();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errors !== prevProps.errors && this.props.errors.length > 0) {
      for (const error of this.props.errors) {
        this.openSnackbar(error.message);
        this.props.resetError();
      }
    }
    if (this.props.selectedUser !== prevProps.selectedUser && this.props.selectedUser) {
      if (this.state.quarterView) {
        this.props.loadPL();
      } else {
        this.props.loadPLYear();
      }
    }
    if (this.props.year !== prevProps.year || this.props.quarter !== prevProps.quarter) {
      if (this.state.quarterView) {
        this.props.loadPL();
      } else {
        this.props.loadPLYear();
      }
    }
  }

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  downloadCSV = () => {
    const username = this.props.selectedUser && this.props.selectedUser.username ? this.props.selectedUser.username : this.props.user.username;
    const filename = `summary_pl_${this.state.quarterView ? 'quarter' : 'year'}_${username}_${this.props.year}_${this.props.quarter}.xlsx`;
    const workbook = XLSX.utils.table_to_book(document.getElementById('sheetjs'));
    XLSX.writeFile(workbook, filename);
  };

  handleQuarterView = (event, value) => {
    if (value !== null) {
      this.setState({quarterView: value});
      if (value) {
        this.props.loadPL();
      } else {
        this.props.loadPLYear();
      }
    }
  };

  render() {
    const {
      classes,
      pl,
      plYear,
    } = this.props;
    const {
      quarterView,
    } = this.state;

    if (!pl.expenses) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={cn(classes.box, classes.namedBox)}>
          <div className={classes.boxName}><span>Summary</span></div>
          <div className={classes.form}>
            <div className={classes.toggleContainer}>
              <ToggleButtonGroup value={quarterView} exclusive onChange={this.handleQuarterView}>
                <ToggleButton className={classes.toggleButton} value={true}>
                  Quarter
                </ToggleButton>
                <ToggleButton className={classes.toggleButton} value={false}>
                  Year
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>

        <div className={cn(classes.box, classes.table)}>
          {quarterView ? (pl &&
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component={"th"}>Income</TableCell>
                <TableCell align="right">{currencyFormat(pl.income)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={"th"}>Interest Income</TableCell>
                <TableCell align="right">{currencyFormat(pl.interest_income)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={"th"}>Other Income</TableCell>
                <TableCell align="right">{currencyFormat(pl.other_income)}</TableCell>
              </TableRow>
              <TableRow className={classes.total}>
                <TableCell component={"th"}>Total Income</TableCell>
                <TableCell align="right">{currencyFormat(pl.total_income)}</TableCell>
              </TableRow>
              <TableRow className={classes.normal}>
                <TableCell colSpan={2} />
              </TableRow>
              {pl.expenses.map(expense => {
                return (
                  <TableRow key={expense[0]}>
                    <TableCell component={"th"}>{expense[0]}</TableCell>
                    <TableCell align="right">{currencyFormat(expense[1])}</TableCell>
                  </TableRow>
                )
              })}
              <TableRow className={classes.total}>
                <TableCell component={"th"}>Total Expense</TableCell>
                <TableCell align="right">{currencyFormat(pl.total_expense)}</TableCell>
              </TableRow>
              <TableRow className={classes.normal}>
                <TableCell colSpan={2} />
              </TableRow>
              <TableRow className={classes.total}>
                <TableCell component={"th"}>Net Profit</TableCell>
                <TableCell align="right">{currencyFormat(pl.net_profit)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          ) : (plYear && plYear.sep &&
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right">Sep</TableCell>
                <TableCell align="right">Dec</TableCell>
                <TableCell align="right">Mar</TableCell>
                <TableCell align="right">Jun</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component={"th"}>Income</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.dec.income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.mar.income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.jun.income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.income + plYear.dec.income + plYear.mar.income + plYear.jun.income)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={"th"}>Interest Income</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.interest_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.dec.interest_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.mar.interest_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.jun.interest_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.interest_income + plYear.dec.interest_income + plYear.mar.interest_income + plYear.jun.interest_income)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={"th"}>Other Income</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.other_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.dec.other_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.mar.other_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.jun.other_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.other_income + plYear.dec.other_income + plYear.mar.other_income + plYear.jun.other_income)}</TableCell>
              </TableRow>
              <TableRow className={classes.total}>
                <TableCell component={"th"}>Total Income</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.total_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.dec.total_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.mar.total_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.jun.total_income)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.total_income + plYear.dec.total_income + plYear.mar.total_income + plYear.jun.total_income)}</TableCell>
              </TableRow>
              <TableRow className={classes.normal}>
                <TableCell colSpan={6} />
              </TableRow>
              {plYear.sep.expenses.map((expense, i) => {
                return (
                  <TableRow key={expense[0]}>
                    <TableCell component={"th"}>{expense[0]}</TableCell>
                    <TableCell align="right">{currencyFormat(plYear.sep.expenses[i][1])}</TableCell>
                    <TableCell align="right">{currencyFormat(plYear.dec.expenses[i][1])}</TableCell>
                    <TableCell align="right">{currencyFormat(plYear.mar.expenses[i][1])}</TableCell>
                    <TableCell align="right">{currencyFormat(plYear.jun.expenses[i][1])}</TableCell>
                    <TableCell align="right">{currencyFormat(plYear.sep.expenses[i][1] + plYear.dec.expenses[i][1] + plYear.mar.expenses[i][1] + plYear.jun.expenses[i][1])}</TableCell>
                  </TableRow>
                )
              })}
              <TableRow className={classes.total}>
                <TableCell component={"th"}>Total Expense</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.total_expense)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.dec.total_expense)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.mar.total_expense)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.jun.total_expense)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.total_expense + plYear.dec.total_expense + plYear.mar.total_expense + plYear.jun.total_expense)}</TableCell>
              </TableRow>
              <TableRow className={classes.normal}>
                <TableCell colSpan={6} />
              </TableRow>
              <TableRow className={classes.total}>
                <TableCell component={"th"}>Net Profit</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.net_profit)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.dec.net_profit)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.mar.net_profit)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.jun.net_profit)}</TableCell>
                <TableCell align="right">{currencyFormat(plYear.sep.net_profit + plYear.dec.net_profit + plYear.mar.net_profit + plYear.jun.net_profit)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          )}
        </div>

        <div className={classes.actions}>
          <Tooltip title='Download Excel File'>
            <Fab aria-label="Download Excel File" className={classes.fab} onClick={this.downloadCSV}>
              <FontAwesomeIcon icon="file-excel" size="2x" />
            </Fab>
          </Tooltip>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        {quarterView ? (pl &&
        <table id="sheetjs" style={{display: 'none'}}>
          <thead>
          <tr>
            <th>Item</th>
            <th>Amount</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Income</td>
            <td>{pl.income}</td>
          </tr>
          <tr>
            <td>Interest Income</td>
            <td>{pl.interest_income}</td>
          </tr>
          <tr>
            <td>Other Income</td>
            <td>{pl.other_income}</td>
          </tr>
          <tr>
            <td>Total Income</td>
            <td>{pl.total_income}</td>
          </tr>
          {pl.expenses.map(expense => {
            return (
              <tr key={expense[0]}>
                <td>{expense[0]}</td>
                <td>{expense[1]}</td>
              </tr>
            )
          })}
          <tr>
            <td>Total Expense</td>
            <td>{pl.total_expense}</td>
          </tr>
          <tr>
            <td>Net Profit</td>
            <td>{pl.net_profit}</td>
          </tr>
          </tbody>
        </table>
        ) : (plYear && plYear.sep &&
        <table id="sheetjs" style={{display: 'none'}}>
          <thead>
          <tr>
            <th>Item</th>
            <th>Sep</th>
            <th>Dec</th>
            <th>Mar</th>
            <th>Jun</th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Income</td>
            <td>{plYear.sep.income}</td>
            <td>{plYear.dec.income}</td>
            <td>{plYear.mar.income}</td>
            <td>{plYear.jun.income}</td>
            <td>{plYear.sep.income + plYear.dec.income + plYear.mar.income + plYear.jun.income}</td>
          </tr>
          <tr>
            <td>Interest Income</td>
            <td>{plYear.sep.interest_income}</td>
            <td>{plYear.dec.interest_income}</td>
            <td>{plYear.mar.interest_income}</td>
            <td>{plYear.jun.interest_income}</td>
            <td>{plYear.sep.interest_income + plYear.dec.interest_income + plYear.mar.interest_income + plYear.jun.interest_income}</td>
          </tr>
          <tr>
            <td>Other Income</td>
            <td>{plYear.sep.other_income}</td>
            <td>{plYear.dec.other_income}</td>
            <td>{plYear.mar.other_income}</td>
            <td>{plYear.jun.other_income}</td>
            <td>{plYear.sep.other_income + plYear.dec.other_income + plYear.mar.other_income + plYear.jun.other_income}</td>
          </tr>
          <tr>
            <td>Total Income</td>
            <td>{plYear.sep.total_income}</td>
            <td>{plYear.dec.total_income}</td>
            <td>{plYear.mar.total_income}</td>
            <td>{plYear.jun.total_income}</td>
            <td>{plYear.sep.total_income + plYear.dec.total_income + plYear.mar.total_income + plYear.jun.total_income}</td>
          </tr>
          {plYear.sep.expenses.map((expense, i) => {
            return (
              <tr key={expense[0]}>
                <td>{expense[0]}</td>
                <td>{plYear.sep.expenses[i][1]}</td>
                <td>{plYear.dec.expenses[i][1]}</td>
                <td>{plYear.mar.expenses[i][1]}</td>
                <td>{plYear.jun.expenses[i][1]}</td>
                <td>{plYear.sep.expenses[i][1] + plYear.dec.expenses[i][1] + plYear.mar.expenses[i][1] + plYear.jun.expenses[i][1]}</td>
              </tr>
            )
          })}
          <tr>
            <td>Total Expense</td>
            <td>{plYear.sep.total_expense}</td>
            <td>{plYear.dec.total_expense}</td>
            <td>{plYear.mar.total_expense}</td>
            <td>{plYear.jun.total_expense}</td>
            <td>{plYear.sep.total_expense + plYear.dec.total_expense + plYear.mar.total_expense + plYear.jun.total_expense}</td>
          </tr>
          <tr>
            <td>Net Profit</td>
            <td>{plYear.sep.net_profit}</td>
            <td>{plYear.dec.net_profit}</td>
            <td>{plYear.mar.net_profit}</td>
            <td>{plYear.jun.net_profit}</td>
            <td>{plYear.sep.net_profit + plYear.dec.net_profit + plYear.mar.net_profit + plYear.jun.net_profit}</td>
          </tr>
          </tbody>
        </table>
        )}

      </React.Fragment>
    );
  }
}

SummaryPL.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let errors = [];
  if (state.payroll.errors) {
    errors = Object.keys(state.benchmark.errors).map(field => {
      return {field, message: state.benchmark.errors[field]};
    });
  }
  return {
    errors,
    pl: state.benchmark.pl,
    plYear: state.benchmark.plYear,
    selectedUser: state.admin.selectedUser,
    user: state.auth.user,
    year: state.period.year,
    quarter: state.period.quarter,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    resetError: () => dispatch(benchmark.resetError()),
    loadPL: () => dispatch(benchmark.loadPL()),
    loadPLYear: () => dispatch(benchmark.loadPLYear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(SummaryPL)));
