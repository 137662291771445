import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
});

class NotFound extends React.Component {
  render() {
    return (
      <React.Fragment>
        404 Not Found
      </React.Fragment>
    )
  }
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
