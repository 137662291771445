const initialState = {
  isRegistered: false,
  errors: {},
};


export default function registration(state=initialState, action) {

  switch (action.type) {

    case 'REGISTRATION_SUCCESSFUL':
      return {...state, isRegistered: true, errors: null};

    case 'REGISTRATION_ERROR':
    case 'REGISTRATION_FAILED':
      return {...state, errors: action.data, isRegistered: false};

    default:
      return state;
  }
}
