import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars, faCalendar, faCalendarAlt,
  faCheckCircle,
  faFileExcel,
  faFilePdf, faLock, faPen,
  faPrint, faSpinner, faTimes,
  faTimesCircle,
  faTrashAlt, faUnlock
} from '@fortawesome/free-solid-svg-icons'

const loadFontAwesome = () => {
  library.add(faPrint);
  library.add(faFileExcel);
  library.add(faFilePdf);
  library.add(faTrashAlt);
  library.add(faTimesCircle);
  library.add(faCheckCircle);
  library.add(faPen);
  library.add(faTimes);
  library.add(faBars);
  library.add(faLock);
  library.add(faUnlock);
  library.add(faSpinner);
  library.add(faCalendarAlt);
  library.add(faCalendar);
};

export default loadFontAwesome;
