import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SummaryMenu from '../components/SummaryMenu';
import { connect } from 'react-redux';
import { session } from '../actions';
import SummaryBenchmark from '../components/SummaryBenchmark';
import SummaryPL from '../components/SummaryPL';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
  left: {
    flex: '0 0 200px',
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 1080,
      flex: '1 1 auto',
      marginBottom: theme.spacing.unit * 2,
    }
  },
  right: {
    flex: '1 1 auto',
    maxWidth: 1080,
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: 1080,
      minWidth: 1080,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }
});

class Summary extends React.Component {
  state = {
    menu: 0,
  };

  componentDidMount() {
    this.props.updateSessionTime();
  }

  updateMenu = (menu) => {
    this.setState({ menu });
  };

  render() {
    const { classes } = this.props;
    const { menu } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.left}>
          <SummaryMenu onChange={this.updateMenu} />
        </div>
        <div className={classes.right}>
          {menu === 0 && <SummaryPL />}
          {menu === 1 && <SummaryBenchmark />}
        </div>
      </div>
    )
  }
}

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Summary));
