import React from 'react';
import cn from 'classnames';
import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import { expenditure, session } from '../actions';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { currencyFormat, DateFormatter, DetailFormatter, NumberFormatter } from '../utils';


const columns = [
  { key: 'item', name: 'Item', width: 100, sortable: true },
  { key: 'date', name: 'Date', width: 110, sortable: true, editable: true, formatter: DateFormatter },
  { key: 'gross', name: 'Gross', width: 90, sortable: true, editable: true, formatter: NumberFormatter },
  { key: 'detail', name: 'Detail', sortable: true, editable: true, formatter: DetailFormatter },
];


const styles = theme => ({
  wrapper: {
    marginTop: theme.spacing.unit * 2,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px !important',
    '& .react-grid-Grid': {
      overflow: 'visible !important'
    },
    '& .react-grid-Header': {
      position: 'sticky !important',
      zIndex: 3,
      top: 0,
    },
    '& .react-grid-Canvas': {
      overflowY: 'hidden !important'
    },
    '& .react-grid-Main': {
      outline: 'none'
    },
    '& .react-grid-HeaderRow': {
      backgroundColor: '#dedede',
    },
    '& .react-grid-HeaderCell': {
      fontWeight: 'normal',
      color: 'white',
      backgroundColor: '#8bc34a',
    },
    '& .react-grid-Row--odd > .react-grid-Cell': {
      backgroundColor: '#EDF5E6',
    },
    '& .react-grid-Row--odd:hover > .react-grid-Cell': {
      backgroundColor: '#e3ebdc',
    },
    '& .react-grid-Cell:nth-child(3)': {
      textAlign: 'right !important',
    },
  },
  bar: {
    position: 'relative',
    height: 19 + theme.spacing.unit * 4,
    backgroundColor: 'white',
    border: '1px solid #e7eaec',
    borderTop: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  total: {
    width: 270,
    padding: theme.spacing.unit,
    paddingLeft: 160 + theme.spacing.unit,
  },
  value: {
    width: 90,
    textAlign: 'right',
    padding: theme.spacing.unit,
  },
  button: {
    position: 'absolute',
    left: 10
  },
  fab: {
    margin: theme.spacing.unit,
    boxShadow: 'none',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.8)',
    color: 'black',
    width: 24,
    height: 24,
    minHeight: 24,
  }
});

const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === "DESC") {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};

class ExpenditureExpenseGrid extends React.Component {
  state = {
    rows: [],
    loans: [],
    selectedIndexes: [],
    isUpdate: false,
  };

  componentDidMount() {
    this.setState({rows: this.props.loans, loans: this.props.loans});
  }

  static getDerivedStateFromProps(props, state) {
    if (Array.isArray(props.loans) && !(props.loans.length === state.loans.length && props.loans.every((value, index) => value === state.loans[index]))) {
      return { rows: props.loans, loans: props.loans, isUpdate: true };
    }
    return null;
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const { loans, isUpdate } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(loans, this.sortColumn, this.sortDirection)(rows);
    }
    const newRow = {...rows[fromRow], ...updated};
    const { id, item, date, gross, detail, user } = newRow;
    const { start, end } = this.props;

    if (!date) {
      return;
    }

    if (moment(date).diff(moment(start)) < 0 || moment(date).diff(moment(end)) > 0) {
      return;
    }

    if (gross === undefined || gross === null) {
      return;
    }

    this.props.updateLoan(
      id,
      item,
      date,
      Number(gross),
      detail,
      user,
    );
    this.props.updateSessionTime();
  };

  onRowsSelected = rows => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        rows.map(r => r.rowIdx)
      )
    });
  };

  onRowsDeselected = rows => {
    let rowIndexes = rows.map(r => r.rowIdx);
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        i => rowIndexes.indexOf(i) === -1
      )
    });
  };

  getTotal = (colName) => {
    const { rows } = this.state;
    if (!rows || rows.length === 0) {
      return 0;
    }
    return rows.reduce((total, e) => total + Number(e[colName]), 0);
  };

  deleteSelectedRows = () => {
    const { loans, isUpdate, selectedIndexes } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(loans, this.sortColumn, this.sortDirection)(rows);
    }
    selectedIndexes.map((index) => {
      return this.props.deleteLoan(rows[index].id);
    });
    this.setState({ selectedIndexes: [] });
  };

  render() {
    const { classes } = this.props;
    const { loans, isUpdate, selectedIndexes } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(loans, this.sortColumn, this.sortDirection)(rows);
    }

    return (
      <div ref={ref => this.div = ref} className={classes.wrapper}>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          rowHeight={30}
          enableCellSelect
          minHeight={(rows.length + 1) * 30}
          onGridRowsUpdated={this.onGridRowsUpdated}
          onGridSort={(sortColumn, sortDirection) => {
            this.sortColumn = sortColumn;
            this.sortDirection = sortDirection;
            this.setState({rows: sortRows(loans, sortColumn, sortDirection)(rows), isUpdate: false})
          }}
          rowSelection={{
            showCheckbox: true,
            enableShiftSelect: true,
            onRowsSelected: this.onRowsSelected,
            onRowsDeselected: this.onRowsDeselected,
            selectBy: {
              indexes: selectedIndexes
            }
          }}
        />
        <div className={classes.bar}>
          <div className={classes.button}>
            <Tooltip title='Delete selected rows'>
              <Fab size="small" aria-label="Delete" className={classes.fab} onClick={this.deleteSelectedRows}>
                <FontAwesomeIcon icon="trash-alt" />
              </Fab>
            </Tooltip>
          </div>
          <div className={cn(classes.total, classes.bold)}>Total</div>
          <div className={classes.value}>
            {currencyFormat(this.getTotal('gross'))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loans: state.expenditure.loans,
    user: state.auth.user,
    start: state.period.start,
    end: state.period.end,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateLoan: (...args) => dispatch(expenditure.updateLoan(...args)),
    deleteLoan: (id) => dispatch(expenditure.deleteLoan(id)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExpenditureExpenseGrid));