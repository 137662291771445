import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ClientMenu from '../components/ClientMenu';
import UserListMenu from '../components/UserListMenu';
import ClientUserList from '../components/ClientUserList';
import ClientIncome from '../components/ClientIncome';
import ClientExpenditure from '../components/ClientExpenditure';
import ClientPayroll from '../components/ClientPayroll';
import ClientBasic from '../components/ClientBasic';
import ClientBenchmark from '../components/ClientBenchmark';
import ClientUserLock from '../components/ClientUserLock';
import { connect } from 'react-redux';
import { session } from '../actions';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
  left: {
    flex: '0 0 200px',
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 1080,
      flex: '1 1 auto',
      marginBottom: theme.spacing.unit * 2,
    }
  },
  right: {
    flex: '1 1 auto',
    maxWidth: 1080,
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: 1080,
      minWidth: 1080,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }
});

class Client extends React.Component {
  state = {
    menu: -1,
  };

  componentDidMount() {
    this.props.updateSessionTime();
  }

  updateMenu = (menu) => {
    this.setState({ menu });
  };

  showUserList = () => {
    this.setState({ menu: -1 });
  };

  render() {
    const { classes, selectedUser } = this.props;
    const { menu } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.left}>
          <UserListMenu user={selectedUser} onClick={this.showUserList} />
          <ClientMenu disabled={!!!selectedUser} onChange={this.updateMenu} />
        </div>
        <div className={classes.right}>
          {menu === -1 ? <ClientUserList /> : null}
          {menu === 0 ? <ClientBasic /> : null}
          {menu === 1 ? <ClientIncome /> : null}
          {menu === 2 ? <ClientExpenditure /> : null}
          {menu === 3 ? <ClientPayroll /> : null}
          {menu === 4 ? <ClientBenchmark /> : null}
          {menu === 5 ? <ClientUserLock /> : null}
        </div>
      </div>
    )
  }
}

Client.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedUser: state.admin.selectedUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Client));
