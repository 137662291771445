import React from 'react';
import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import { income, session } from '../actions';
import { withStyles } from '@material-ui/core/styles';
import { DateFormatter, NumberFormatter } from '../utils';
import cn from 'classnames';

const columns = [
  { key: 'date', name: 'Date', width: 110, sortable: true, formatter: DateFormatter },
  { key: 'card', name: 'Card', sortable: true, editable: true, formatter: NumberFormatter },
  { key: 'other', name: 'Other', sortable: true, editable: true, formatter: NumberFormatter },
  { key: 'total', name: 'Total', sortable: true, formatter: NumberFormatter },
  { key: 'gst_free', name: 'GST Free', sortable: true, editable: true, formatter: NumberFormatter },
  { key: 'gst', name: 'GST', sortable: true, formatter: NumberFormatter },
  { key: 'net', name: 'Net', sortable: true, formatter: NumberFormatter },
];

const styles = theme => ({
  wrapper: {
    marginTop: theme.spacing.unit * 2,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px !important',
    '& .react-grid-Grid': {
      overflow: 'visible !important'
    },
    '& .react-grid-Header': {
      position: 'sticky !important',
      zIndex: 3,
      top: 0,
    },
    '& .react-grid-Canvas': {
      overflowY: 'hidden !important'
    },
    '& .react-grid-Main': {
      outline: 'none'
    },
    '& .react-grid-HeaderRow': {
      backgroundColor: '#dedede',
    },
    '& .react-grid-HeaderCell': {
      fontWeight: 'normal',
      color: 'white',
      backgroundColor: '#8bc34a',
    },
    '& .react-grid-Row--odd > .react-grid-Cell': {
      backgroundColor: '#EDF5E6',
    },
    '& .react-grid-Row--odd:hover > .react-grid-Cell': {
      backgroundColor: '#e3ebdc',
    },
    '& .react-grid-Cell:nth-child(2)': {
      textAlign: 'right',
    },
    '& .react-grid-Cell:nth-child(3)': {
      textAlign: 'right',
    },
    '& .react-grid-Cell:nth-child(4)': {
      textAlign: 'right',
    },
    '& .react-grid-Cell:nth-child(5)': {
      textAlign: 'right',
    },
    '& .react-grid-Cell:nth-child(6)': {
      textAlign: 'right',
    },
    '& .react-grid-Cell:nth-child(7)': {
      textAlign: 'right',
    },
  },
  bar: {
    position: 'relative',
    height: 19 + theme.spacing.unit * 4,
    backgroundColor: 'white',
    border: '1px solid #e7eaec',
    borderTop: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  total: {
    width: 110,
    padding: theme.spacing.unit,
  },
  value: {
    width: 156,
    textAlign: 'right',
    padding: theme.spacing.unit,
  },
  button: {
    position: 'absolute',
    left: 10
  },
  fab: {
    margin: theme.spacing.unit,
    boxShadow: 'none',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.8)',
    color: 'black',
    width: 24,
    height: 24,
    minHeight: 24,
  }
});

const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === "DESC") {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};

function currencyFormat(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

class IncomeBusinessTypeAGrid extends React.Component {
  state = {
    rows: [],
    businessTypeAs: [],
    selectedIndexes: [],
    isUpdate: false,
  };

  componentDidMount() {
    this.setState({rows: this.props.businessTypeAs, businessTypeAs: this.props.businessTypeAs});
  }

  static getDerivedStateFromProps(props, state) {
    if (Array.isArray(props.businessTypeAs) && !(props.businessTypeAs.length === state.businessTypeAs.length && props.businessTypeAs.every((value, index) => value === state.businessTypeAs[index]))) {
      return { rows: props.businessTypeAs, businessTypeAs: props.businessTypeAs, isUpdate: true };
    }
    return null;
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const { businessTypeAs, isUpdate } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(businessTypeAs, this.sortColumn, this.sortDirection)(rows);
    }
    const newRow = {...rows[fromRow], ...updated};
    const total = Number(newRow.card) + Number(newRow.other);
    const gst = (total - Number(newRow.gst_free)) / 11;

    this.props.updateBusinessTypeA(
      newRow.id,
      newRow.date,
      newRow.card,
      newRow.other,
      total,
      newRow.gst_free,
      gst,
      total - gst,
      newRow.user,
    );
    this.props.updateSessionTime();
  };

  getTotal = (colName) => {
    const { rows } = this.state;
    if (!rows || rows.length === 0) {
      return 0;
    }
    return rows.reduce((total, e) => total + Number(e[colName]), 0);
  };

  render() {
    const { classes } = this.props;
    const { businessTypeAs, isUpdate } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(businessTypeAs, this.sortColumn, this.sortDirection)(rows);
    }

    return (
      <div ref={ref => this.div = ref} className={classes.wrapper}>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          rowHeight={30}
          enableCellSelect
          minHeight={(rows.length + 1) * 30}
          onGridRowsUpdated={this.onGridRowsUpdated}
          onGridSort={(sortColumn, sortDirection) => {
            this.sortColumn = sortColumn;
            this.sortDirection = sortDirection;
            this.setState({rows: sortRows(businessTypeAs, sortColumn, sortDirection)(rows), isUpdate: false})
          }}
        />
        <div className={classes.bar}>
          <div className={cn(classes.total, classes.bold)}>Total</div>
          <div className={cn(classes.value)}>
            {currencyFormat(this.getTotal('card'))}
          </div>
          <div className={cn(classes.value)}>
            {currencyFormat(this.getTotal('other'))}
          </div>
          <div className={cn(classes.value)}>
            {currencyFormat(this.getTotal('total'))}
          </div>
          <div className={cn(classes.value)}>
            {currencyFormat(this.getTotal('gst_free'))}
          </div>
          <div className={cn(classes.value)}>
            {currencyFormat(this.getTotal('gst'))}
          </div>
          <div className={cn(classes.value)}>
            {currencyFormat(this.getTotal('net'))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    config: state.auth.config,
    selectedConfig: state.admin.selectedConfig,
    businessTypeAs: state.income.businessTypeAs,
    user: state.auth.user
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateBusinessTypeA: (...args) => dispatch(income.updateBusinessTypeA(...args)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IncomeBusinessTypeAGrid));