const initialState = {
  employees: [],
  wages: [],
  wage_taxes: [],
  subs: [],
  employee_summaries: [],
  wage_year_summaries: [],
  isWageTaxUploading: false,
  isWageTaxUploaded: false,
  errors: {}
};

export default function payroll(state=initialState, action) {

  switch (action.type) {

    case 'LOAD_EMPLOYEES_SUCCESS':
      return {...state, employees: action.data, errors: {}};

    case 'LOAD_EMPLOYEES_FAILURE':
      return {...state, employees: [], errors: action.data};

    case 'LOAD_SUBS_SUCCESS':
      return {...state, subs: action.data, errors: {}};

    case 'LOAD_SUBS_FAILURE':
      return {...state, subs: [], errors: action.data};

    case 'LOAD_WAGES_STARTED':
      return {...state, wages: [], errors: {}};

    case 'LOAD_WAGES_SUCCESS':
      return {...state, wages: action.data, errors: {}};

    case 'LOAD_WAGES_FAILURE':
      return {...state, wages: [], errors: action.data};

    case 'LOAD_WAGE_TAXES_SUCCESS':
      return {...state, wage_taxes: action.data, errors: {}};

    case 'LOAD_WAGE_TAXES_FAILURE':
      return {...state, wage_taxes: [], errors: action.data};

    case 'LOAD_EMPLOYEE_SUMMARIES_SUCCESS':
      return {...state, employee_summaries: action.data, errors: {}};

    case 'LOAD_EMPLOYEE_SUMMARIES_FAILURE':
      return {...state, employee_summaries: [], errors: action.data};

    case 'UPLOAD_WAGE_TAX_START':
      return {...state, isWageTaxUploading: true, isWageTaxUploaded: false, errors: {}};

    case 'UPLOAD_WAGE_TAX_SUCCESS':
      return {...state, isWageTaxUploading: false, isWageTaxUploaded: true, errors: {}};

    case 'UPLOAD_WAGE_TAX_FAILURE':
      return {...state, isWageTaxUploading: false, isWageTaxUploaded: false, errors: {}};

    case 'LOAD_WAGE_YEAR_SUMMARY_SUCCESS':
      return {...state, wage_year_summaries: action.data, errors: {}};

    case 'LOAD_WAGE_YEAR_SUMMARY_FAILURE':
      return {...state, wage_year_summaries: [], errors: action.data};

    case 'ADD_SUB_SUCCESS':
    case 'UPDATE_SUB_SUCCESS':
    case 'DELETE_SUB_SUCCESS':
    case 'ADD_EMPLOYEE_SUCCESS':
    case 'UPDATE_EMPLOYEE_SUCCESS':
    case 'DELETE_EMPLOYEE_SUCCESS':
    case 'DELETE_WAGE_SUCCESS':
    case 'UPDATE_WAGES_SUCCESS':
      return {...state, errors: {}};

    case 'ADD_SUB_FAILURE':
    case 'UPDATE_SUB_FAILURE':
    case 'DELETE_SUB_FAILURE':
    case 'ADD_EMPLOYEE_FAILURE':
    case 'UPDATE_EMPLOYEE_FAILURE':
    case 'DELETE_EMPLOYEE_FAILURE':
    case 'DELETE_WAGE_FAILURE':
    case 'UPDATE_WAGES_FAILURE':
      return {...state, errors: action.data};

    case 'RESET_ERROR':
      return {...state, errors: {}};

    default:
      return state;
  }
}
