import moment from 'moment';


export const updateBusinessTypeA = (id, date, card, other, total, gst_free, gst, net, user_id) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPDATE_BUSINESS_TYPE_A_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'UPDATE_BUSINESS_TYPE_A_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    const body = JSON.stringify({
      date: moment(date).format('YYYY-MM-DD'),
      card: Number(card),
      other: Number(other),
      total: Number(total),
      gst_free: Number(gst_free),
      gst: Number(gst),
      net: Number(net),
      user: user_id,
      year,
      quarter,
    });

    return fetch(`/api/business_type_a/${id}/`, {headers, body, method: "PUT"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPDATE_BUSINESS_TYPE_A_SUCCESS', data});
            dispatch(loadBusinessTypeAs());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "UPDATE_BUSINESS_TYPE_A_FAILURE", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "UPDATE_BUSINESS_TYPE_A_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const loadBusinessTypeAs = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_BUSINESS_TYPE_AS_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'LOAD_BUSINESS_TYPE_AS_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { start, end } = getState().period;
    const body = JSON.stringify({
      user: userId,
      start,
      end,
      year,
      quarter
    });

    return fetch("/api/period_business_type_a/", {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_BUSINESS_TYPE_AS_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_BUSINESS_TYPE_AS_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_BUSINESS_TYPE_AS_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const loadBusinessTypeBs = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_BUSINESS_TYPE_BS_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { start, end } = getState().period;
    if (!start || !end) {
      dispatch({type: 'LOAD_BUSINESS_TYPE_BS_FAILURE', data: {'detail': 'Invalid date range'}});
    }

    return fetch(`/api/business_type_b/?user=${userId}&date_after=${start}&date_before=${end}`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_BUSINESS_TYPE_BS_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_BUSINESS_TYPE_BS_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_BUSINESS_TYPE_BS_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const updateBusinessTypeB = (id, date, income, gst, net, detail, user_id) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPDATE_BUSINESS_TYPE_B_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'UPDATE_BUSINESS_TYPE_B_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    const body = JSON.stringify({
      date: moment(date).format('YYYY-MM-DD'),
      income: Number(income),
      gst: Number(gst),
      net: Number(net),
      detail,
      user: user_id,
      year,
      quarter
    });

    return fetch(`/api/business_type_b/${id}/`, {headers, body, method: "PUT"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPDATE_BUSINESS_TYPE_B_SUCCESS', data});
            dispatch(loadBusinessTypeBs());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "UPDATE_BUSINESS_TYPE_B_FAILURE", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "UPDATE_BUSINESS_TYPE_B_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const deleteBusinessTypeB = (id) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'DELETE_BUSINESS_TYPE_B_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/business_type_b/${id}/`, {headers, method: "DELETE"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          dispatch({type: 'DELETE_BUSINESS_TYPE_B_SUCCESS'});
          dispatch(loadBusinessTypeBs());
        } else {
          res.json().then(data => {
            dispatch({type: "DELETE_BUSINESS_TYPE_B_FAILURE", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "DELETE_BUSINESS_TYPE_B_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const addBusinessTypeB = (date, income, gst, net, detail) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'ADD_BUSINESS_TYPE_B_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'ADD_BUSINESS_TYPE_B_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const body = JSON.stringify({
      date: moment(date).format('YYYY-MM-DD'),
      income: Number(income),
      gst: Number(gst),
      net: Number(net),
      detail,
      user: userId,
      year,
      quarter,
      model_name: 'BusinessTypeB'
    });

    return fetch(`/api/business_type_b/`, {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'ADD_BUSINESS_TYPE_B_SUCCESS', data});
            dispatch(loadBusinessTypeBs());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "ADD_BUSINESS_TYPE_B_FAILURE", data: data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "ADD_BUSINESS_TYPE_B_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const loadInterests = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_INTERESTS_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { start, end } = getState().period;
    if (!start || !end) {
      dispatch({type: 'LOAD_INTERESTS_FAILURE', data: {'detail': 'Invalid date range'}});
    }

    return fetch(`/api/interest/?user=${userId}&date_after=${start}&date_before=${end}`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_INTERESTS_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_INTERESTS_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_INTERESTS_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const updateInterest = (id, date, amount, user_id) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPDATE_INTEREST_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'UPDATE_INTEREST_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    const body = JSON.stringify({
      date: moment(date).format('YYYY-MM-DD'),
      amount: Number(amount),
      user: user_id,
      year,
      quarter
    });

    return fetch(`/api/interest/${id}/`, {headers, body, method: "PUT"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPDATE_INTEREST_SUCCESS', data});
            dispatch(loadInterests());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "UPDATE_INTEREST_FAILURE", data: data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "UPDATE_INTEREST_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const deleteInterest = (id) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'DELETE_INTEREST_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/interest/${id}/`, {headers, method: "DELETE"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          dispatch({type: 'DELETE_INTEREST_SUCCESS'});
          dispatch(loadInterests());
        } else {
          res.json().then(data => {
            dispatch({type: "DELETE_INTEREST_FAILURE", data: data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "DELETE_INTEREST_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const addInterest = (date, amount) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'ADD_INTEREST_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'ADD_INTEREST_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const body = JSON.stringify({
      date: moment(date).format('YYYY-MM-DD'),
      amount: Number(amount),
      user: userId,
      year,
      quarter,
      model_name: 'Interest'
    });

    return fetch(`/api/interest/`, {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'ADD_INTEREST_SUCCESS', data});
            dispatch(loadInterests());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "ADD_INTEREST_FAILURE", data: data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "ADD_INTEREST_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const loadOthers = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_OTHERS_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { start, end } = getState().period;
    if (!start || !end) {
      dispatch({type: 'LOAD_OTHERS_FAILURE', data: {'detail': 'Invalid date range'}});
    }

    return fetch(`/api/other/?user=${userId}&date_after=${start}&date_before=${end}`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_OTHERS_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_OTHERS_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_OTHERS_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const updateOther = (id, date, gross, gst, net, detail, user_id) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPDATE_OTHER_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'UPDATE_OTHER_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    const body = JSON.stringify({
      date: moment(date).format('YYYY-MM-DD'),
      gross: Number(gross),
      gst: Number(gst),
      net: Number(net),
      detail: detail,
      user: user_id,
      year,
      quarter
    });

    return fetch(`/api/other/${id}/`, {headers, body, method: "PUT"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPDATE_OTHER_SUCCESS', data});
            dispatch(loadOthers());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "UPDATE_OTHER_FAILURE", data: data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "UPDATE_OTHER_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const deleteOther = (id) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'DELETE_OTHER_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/other/${id}/`, {headers, method: "DELETE"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          dispatch({type: 'DELETE_OTHER_SUCCESS'});
          dispatch(loadOthers());
        } else {
          res.json().then(data => {
            dispatch({type: "DELETE_OTHER_FAILURE", data: data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "DELETE_OTHER_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const addOther = (date, gross, gst, net, detail) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'ADD_OTHER_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'ADD_OTHER_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const body = JSON.stringify({
      date: moment(date).format('YYYY-MM-DD'),
      gross: Number(gross),
      gst: Number(gst),
      net: Number(net),
      detail: detail,
      user: userId,
      year,
      quarter,
      model_name: 'Other'
    });

    return fetch(`/api/other/`, {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'ADD_OTHER_SUCCESS', data});
            dispatch(loadOthers());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "ADD_OTHER_FAILURE", data: data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "ADD_OTHER_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};

export const resetError = () => {
  return (dispatch, getState) => {
    dispatch({type: 'RESET_ERROR'});
  }
};
