import React from 'react';
import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import { payroll, session } from '../actions';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import {
  BooleanFormatter,
  GenderFormatter,
  TFNFormatter,
  VisaFormatter,
  VisaTypeEditor,
  GenderTypeEditor,
  BooleanTypeEditor
} from '../utils';

const columns = [
  { key: 'last_name', width: 90, name: 'Last name', sortable: true, editable: true, resizable: true},
  { key: 'first_name', width: 90, name: 'First name', sortable: true, editable: true, resizable: true},
  { key: 'gender', name: 'Gender', width: 60, sortable: true, editable: true, resizable: true, formatter: GenderFormatter, editor: GenderTypeEditor},
  { key: 'dob', name: 'D.O.B', width: 100, sortable: true, editable: true, resizable: true},
  { key: 'tfn', name: 'TFN', width: 100, sortable: true, editable: true, resizable: true, formatter: TFNFormatter},
  { key: 'visa', name: 'Visa', width: 70, sortable: true, editable: true, resizable: true, formatter: VisaFormatter, editor: VisaTypeEditor},
  { key: 'commerce', name: 'Commence', width: 70, sortable: true, resizable: true, editable: true},
  { key: 'terminate', name: 'Terminate', width: 40, sortable: true, editable: true, resizable: true, formatter: BooleanFormatter, editor: BooleanTypeEditor},
  { key: 'owner', name: 'Owner', width: 40, sortable: true, editable: true, resizable: true, formatter: BooleanFormatter, editor: BooleanTypeEditor},
  { key: 'address', name: 'Address', width: 80, sortable: true, editable: true, resizable: true},
  { key: 'comment', name: 'Comment', sortable: true, editable: true, resizable: true},
];

const styles = theme => ({
  wrapper: {
    marginTop: theme.spacing.unit * 2,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px !important',
    '& .react-grid-Grid': {
      overflow: 'visible !important'
    },
    '& .react-grid-Header': {
      position: 'sticky !important',
      zIndex: 3,
      top: 0,
    },
    '& .react-grid-Canvas': {
      overflowY: 'hidden !important'
    },
    '& .react-grid-Main': {
      outline: 'none'
    },
    '& .react-grid-HeaderRow': {
      backgroundColor: '#dedede',
    },
    '& .react-grid-HeaderCell': {
      fontWeight: 'normal',
      color: 'white',
      backgroundColor: '#8bc34a',
    },
    '& .react-grid-Row--odd > .react-grid-Cell': {
      backgroundColor: '#EDF5E6',
    },
    '& .react-grid-Row--odd:hover > .react-grid-Cell': {
      backgroundColor: '#e3ebdc',
    },
  },
  bar: {
    position: 'relative',
    padding: theme.spacing.unit * 2,
    height: 19 + theme.spacing.unit * 4,
    backgroundColor: 'white',
    border: '1px solid #e7eaec',
    borderTop: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  total: {
    position: 'absolute',
    top: theme.spacing.unit * 2,
  },
  gross: {
    position: 'absolute',
    top: theme.spacing.unit * 2,
  },
  gst: {
    position: 'absolute',
    top: theme.spacing.unit * 2,
  },
  net: {
    position: 'absolute',
    top: theme.spacing.unit * 2,
  },
  button: {
    position: 'absolute',
    left: 10
  },
  fab: {
    margin: theme.spacing.unit,
    boxShadow: 'none',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.8)',
    color: 'black',
    width: 24,
    height: 24,
    minHeight: 24,
  }
});

const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === "DESC") {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};

class PayrollEmployeeGrid extends React.Component {
  state = {
    rows: [],
    employees: [],
    selectedIndexes: [],
    isUpdate: false,
  };

  componentDidMount() {
    this.setState({rows: this.props.employees, employees: this.props.employees});
  }

  static getDerivedStateFromProps(props, state) {
    if (Array.isArray(props.employees) && !(props.employees.length === state.employees.length && props.employees.every((value, index) => value === state.employees[index]))) {
      return { rows: props.employees, employees: props.employees, isUpdate: true };
    }
    return null;
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    if (this.props.disabled) {
      return;
    }
    const { employees, isUpdate } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(employees, this.sortColumn, this.sortDirection)(rows);
    }
    const newRow = {...rows[fromRow], ...updated};
    const {
      id,
      last_name,
      first_name,
      gender,
      dob,
      tfn,
      visa,
      commerce,
      terminate,
      owner,
      address,
      comment,
      expiration,
      user
    } = newRow;

    if (tfn && tfn.length !== 9) {
      return;
    }

    this.props.updateEmployee(
      id,
      last_name.toUpperCase(),
      first_name.toUpperCase(),
      gender,
      dob,
      tfn,
      visa,
      commerce.toUpperCase(),
      terminate,
      owner,
      address.toUpperCase(),
      comment.toUpperCase(),
      expiration,
      user
    );
    this.props.updateSessionTime();
  };

  onRowsSelected = rows => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        rows.map(r => r.rowIdx)
      )
    });
  };

  onRowsDeselected = rows => {
    let rowIndexes = rows.map(r => r.rowIdx);
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        i => rowIndexes.indexOf(i) === -1
      )
    });
  };

  getTotal = (colName) => {
    const { rows } = this.state;
    if (!rows || rows.length === 0) {
      return 0;
    }
    return rows.reduce((total, e) => total + Number(e[colName]), 0);
  };

  deleteSelectedRows = () => {
    const { employees, isUpdate, selectedIndexes } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(employees, this.sortColumn, this.sortDirection)(rows);
    }
    selectedIndexes.map((index) => {
      return this.props.deleteEmployee(rows[index].id);
    });
    this.setState({ selectedIndexes: [] });
    this.props.updateSessionTime();
  };

  render() {
    const { classes } = this.props;
    const { employees, isUpdate, selectedIndexes } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(employees, this.sortColumn, this.sortDirection)(rows);
    }

    return (
      <div ref={ref => this.div = ref} className={classes.wrapper}>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          rowHeight={30}
          enableCellSelect
          minHeight={(rows.length + 1) * 30}
          onGridRowsUpdated={this.onGridRowsUpdated}
          onGridSort={(sortColumn, sortDirection) => {
            this.sortColumn = sortColumn;
            this.sortDirection = sortDirection;
            this.setState({rows: sortRows(employees, sortColumn, sortDirection)(rows), isUpdate: false})
          }}
          rowSelection={{
            showCheckbox: true,
            enableShiftSelect: true,
            onRowsSelected: this.onRowsSelected,
            onRowsDeselected: this.onRowsDeselected,
            selectBy: {
              indexes: selectedIndexes
            }
          }}
        />
        <div className={classes.bar}>
          <div className={classes.button}>
            <Tooltip title='Delete selected rows'>
              <Fab size="small" aria-label="Delete" className={classes.fab} onClick={this.deleteSelectedRows}>
                <FontAwesomeIcon icon="trash-alt" />
              </Fab>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    employees: state.payroll.employees,
    user: state.auth.user,
    config: state.auth.config,
    selectedConfig: state.admin.selectedConfig,
    start: state.period.start,
    end: state.period.end,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateEmployee: (...args) => dispatch(payroll.updateEmployee(...args)),
    deleteEmployee: (id) => dispatch(payroll.deleteEmployee(id)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PayrollEmployeeGrid));