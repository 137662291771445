import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AdminMenu from '../components/AdminMenu';
import AdminLock from '../components/AdminLock';
import AdminSuper from '../components/AdminSuper';
import AdminNotice from '../components/AdminNotice';
import AdminMain from '../components/AdminMain';
import AdminWageTax from '../components/AdminWageTax';
import { connect } from 'react-redux';
import { session } from '../actions';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
  left: {
    flex: '0 0 200px',
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 1080,
      flex: '1 1 auto',
      marginBottom: theme.spacing.unit * 2,
    }
  },
  right: {
    flex: '1 1 auto',
    maxWidth: 1080,
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: 1080,
      minWidth: 1080,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }
});

class Admin extends React.Component {
  state = {
    menu: 0,
  };

  componentDidMount() {
    this.props.updateSessionTime();
  }

  updateMenu = (menu) => {
    this.setState({ menu });
  };

  render() {
    const { classes } = this.props;
    const { menu } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.left}>
          <AdminMenu onChange={this.updateMenu} />
        </div>
        <div className={classes.right}>
          {menu === 0 ? <AdminWageTax /> : null}
          {menu === 1 ? <AdminSuper /> : null}
          {menu === 2 ? <AdminMain /> : null}
          {menu === 3 ? <AdminNotice /> : null}
          {menu === 4 ? <AdminLock /> : null}
        </div>
      </div>
    )
  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Admin));
