import moment from 'moment';

export const selectPeriod = (year, quarter) => {
  return (dispatch, getState) => {
    if (!moment(year, 'YYYY').isValid()) {
      dispatch({type: 'PERIOD_SELECT_FAILED'});
    }
    if (quarter < 1 || 4 < quarter) {
      dispatch({type: 'PERIOD_SELECT_FAILED'});
    }

    let start, end, fiscalStart, fiscalEnd, fiscalYear;
    switch (quarter) {
      case 1:
        start = `${year}-01-01`;
        end = `${year}-03-31`;
        fiscalStart = `${year-1}-07-01`;
        fiscalEnd = `${year}-06-30`;
        fiscalYear = year;
        break;
      case 2:
        start = `${year}-04-01`;
        end = `${year}-06-30`;
        fiscalStart = `${year-1}-07-01`;
        fiscalEnd = `${year}-06-30`;
        fiscalYear = year;
        break;
      case 3:
        start = `${year}-07-01`;
        end = `${year}-09-30`;
        fiscalStart = `${year}-07-01`;
        fiscalEnd = `${year+1}-06-30`;
        fiscalYear = year + 1;
        break;
      case 4:
      default:
        start = `${year}-10-01`;
        end = `${year}-12-31`;
        fiscalStart = `${year}-07-01`;
        fiscalEnd = `${year+1}-06-30`;
        fiscalYear = year + 1;
        break;
    }

    dispatch({type: 'PERIOD_SELECTED', year, quarter, start, end, fiscalStart, fiscalEnd, fiscalYear});
    dispatch(loadLocks());
  };
};

export const loadLocks = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_LOCKS_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/lock/`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_LOCKS_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_LOCKS_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_LOCKS_FAILURE", data: {'detail': 'Server Error'}});
      });
  }
};

export const addLock = (year, quarter) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      return;
    }

    const body = JSON.stringify({
      year, quarter
    });

    return fetch(`/api/lock/?year=${year}&quarter=${quarter}`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            if (data && data.length > 0) {
              return fetch(`/api/lock/${data[0].id}/`, {headers, body, method: "PUT"})
                .then(res => {
                  if (200 <= res.status && res.status < 300) {
                    res.json().then(data => {
                      dispatch(loadLocks());
                      return data;
                    }).catch(err => {
                      throw err;
                    });
                  } else {
                    dispatch({type: "ADD_LOCK_FAILURE", data: res.data});
                    throw res.data;
                  }
                }).catch(err => {
                  dispatch({type: "ADD_LOCK_FAILURE", data: {'detail': 'Server Error'}});
                });
            } else {
              return fetch(`/api/lock/`, {headers, body, method: "POST"})
                .then(res => {
                  if (200 <= res.status && res.status < 300) {
                    res.json().then(data => {
                      dispatch(loadLocks());
                      return data;
                    }).catch(err => {
                      throw err;
                    });
                  } else {
                    dispatch({type: "ADD_LOCK_FAILURE", data: res.data});
                    throw res.data;
                  }
                }).catch(err => {
                  dispatch({type: "ADD_LOCK_FAILURE", data: {'detail': 'Server Error'}});
                });
            }
          }).catch(err => {
            throw err;
          });
        }
      })
      .catch(err => {
        dispatch({type: "ADD_LOCK_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};

export const deleteLock = (id) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'DELETE_LOCK_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/lock/${id}/`, {headers, method: "DELETE"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          dispatch(loadLocks());
        } else {
          dispatch({type: "DELETE_LOCK_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "DELETE_LOCK_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};

export const loadUserLocks = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_USER_LOCKS_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (getState().auth.user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = getState().auth.user.id;
    }

    return fetch(`/api/user-lock/?user=${userId}`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_USER_LOCKS_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_USER_LOCKS_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_USER_LOCKS_FAILURE", data: {'detail': 'Server Error'}});
      });
  }
};

export const addUserLock = (year, quarter, model_name) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      return;
    }

    let userId;
    if (getState().auth.user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = getState().auth.user.id;
    }

    const body = JSON.stringify({
      year, quarter, user: userId, model_name
    });

    return fetch(`/api/user-lock/?year=${year}&quarter=${quarter}&user=${userId}&model_name=${model_name}`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            if (data && data.length > 0) {
              return fetch(`/api/user-lock/${data[0].id}/`, {headers, body, method: "PUT"})
                .then(res => {
                  if (200 <= res.status && res.status < 300) {
                    res.json().then(data => {
                      dispatch(loadUserLocks());
                      return data;
                    }).catch(err => {
                      throw err;
                    });
                  } else {
                    dispatch({type: "ADD_USER_LOCK_FAILURE", data: res.data});
                    throw res.data;
                  }
                }).catch(err => {
                  dispatch({type: "ADD_USER_LOCK_FAILURE", data: {'detail': 'Server Error'}});
                });
            } else {
              return fetch(`/api/user-lock/`, {headers, body, method: "POST"})
                .then(res => {
                  if (200 <= res.status && res.status < 300) {
                    res.json().then(data => {
                      dispatch(loadUserLocks());
                      return data;
                    }).catch(err => {
                      throw err;
                    });
                  } else {
                    dispatch({type: "ADD_USER_LOCK_FAILURE", data: res.data});
                    throw res.data;
                  }
                }).catch(err => {
                  dispatch({type: "ADD_USER_LOCK_FAILURE", data: {'detail': 'Server Error'}});
                });
            }
          }).catch(err => {
            throw err;
          });
        }
      })
      .catch(err => {
        dispatch({type: "ADD_USER_LOCK_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};

export const deleteUserLock = (id) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'DELETE_USER_LOCK_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/user-lock/${id}/`, {headers, method: "DELETE"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          dispatch(loadUserLocks());
        } else {
          dispatch({type: "DELETE_USER_LOCK_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "DELETE_USER_LOCK_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};
