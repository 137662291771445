import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { period } from '../actions';
import moment from 'moment';
import { getSelectableYears } from '../utils';


const styles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  content: {
    padding: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: 400,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  }
});


function Transition(props) {
  return <Slide direction="down" {...props} />;
}


function getCurrentYearQuarter() {
  const current = moment();
  const quarter = current.quarter(), year = current.year();
  return {year, quarter};
}


class SelectPeriodDialog extends React.Component {
  state = {
    open: false,
    year: null,
    quarter: null,
    labelWidth: 50,
  };

  close = () => {
    const { year, quarter } = this.state;
    this.props.selectPeriod(year, quarter);
    this.setState({ open: false });
  };

  open = () => {
    this.setState({ open: true });
  };

  componentDidMount() {
    const yq = getCurrentYearQuarter();
    this.setState({
      labelWidth: this.InputLabelRef ? ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth : 50,
      year: yq.year,
      quarter: yq.quarter,
    });

    this.props.setOpen(this.open);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value});
  };

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        fullScreen
        open={this.state.open}
        onClose={this.close}
        TransitionComponent={Transition}
      >
        <DialogTitle>Select The Period</DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.form}>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-year-simple"
              >
                Year
              </InputLabel>
              <Select
                value={this.state.year}
                onChange={this.handleChange}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="year"
                    id="outlined-year-simple"
                  />
                }
              >
                {getSelectableYears().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-quarter-simple"
              >
                Month
              </InputLabel>
              <Select
                value={this.state.quarter}
                onChange={this.handleChange}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="quarter"
                    id="outlined-quarter-simple"
                  />
                }
              >
                <MenuItem value={1}>March</MenuItem>
                <MenuItem value={2}>June</MenuItem>
                <MenuItem value={3}>September</MenuItem>
                <MenuItem value={4}>December</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <Button variant="outlined" size="large" fullWidth onClick={this.close}>Select</Button>
            </FormControl>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

SelectPeriodDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {}
};

const mapDispatchToProps = dispatch => {
  return {
    selectPeriod: (year, quarter) => dispatch(period.selectPeriod(year, quarter))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectPeriodDialog));
