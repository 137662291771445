const initialState = {
  supers: [],
  errors: {},
};


export default function auth(state=initialState, action) {

  switch (action.type) {

    case 'LOAD_SUPERS_SUCCESS':
      return {...state, supers: action.data, errors: {}};

    case 'LOAD_SUPERS_FAILURE':
      return {...state, errors: action.data};

    case 'SAVE_SUPER_SUCCESS':
      return {...state, errors: {}};

    case 'SAVE_SUPER_FAILURE':
      return {...state, errors: action.data};

    default:
      return state;
  }
}
