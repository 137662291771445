import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auth, income, period } from '../actions';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/es/withMobileDialog';
import IncomeBusinessTypeAGrid from './IncomeBusinessTypeAGrid';
import Button from '@material-ui/core/Button';
import IncomeBusinessTypeADayDialog from './IncomeBusinessTypeADayDialog';
import IncomeBusinessTypeAMonthDialog from './IncomeBusinessTypeAMonthDialog';
import moment from 'moment';
import XLSX from 'xlsx';
import { getTotal } from '../utils';


const styles = theme => ({
  categories: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'nowrap',
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'nowrap',
    }
  },
  actions: {
    marginTop: theme.spacing.unit * 2,
  },
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  namedBox: {
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  boxMargin: {
    marginTop: 32,
  },
  boxName: {
    margin: '-32px 8px 8px 8px',
    borderRadius: 4,
    padding: '8px 16px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(59, 132, 3, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(239, 239, 239, 0.4)',
    '& > span': {
      color: '#26710c',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
  },
  formControl: {
    flex: '1 1 auto',
    margin: theme.spacing.unit,
    minWidth: 140,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  button: {
    padding: '14px 24px',
    backgroundColor: '#e2f1d6',
    '&:hover': {
      backgroundColor: '#a0d873',
    },
    color: '#330809',
  },
  dialogButton: {
    backgroundColor: '#fbee73',
    '&:hover': {
      backgroundColor: '#cea336',
    },
    color: '#330809',
    minWidth: 160,
    marginLeft: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing.unit,
    }
  },
  fab: {
    margin: theme.spacing.unit,
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.15)',
  },
});

class IncomeBusinessTypeA extends React.Component {
  state = {
    labelWidth: 0,
    open: false,
    messages: [],
    incomeBusinessTypeADayDialogOpen: false,
    incomeBusinessTypeAMonthDialogOpen: false,
  };

  componentDidMount() {
    this.setState({
      labelWidth: this.InputLabelRef ? ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth : 0,
    });

    this.props.loadBusinessTypeAs();
    this.props.loadUserLocks();
    this.props.loadConfig();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errors !== prevProps.errors && this.props.errors.length > 0) {
      for (const error of this.props.errors) {
        this.openSnackbar(error.message);
        this.props.resetError();
      }
    }
    if (this.props.selectedUser !== prevProps.selectedUser && this.props.selectedUser) {
      this.props.loadBusinessTypeAs();
      this.props.loadUserLocks();
    }
    if (this.props.year !== prevProps.year || this.props.quarter !== prevProps.quarter) {
      this.props.loadBusinessTypeAs();
      this.props.loadUserLocks();
    }
  }

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  downloadCSV = () => {
    const username = this.props.selectedUser && this.props.selectedUser.username ? this.props.selectedUser.username : this.props.user.username;
    const filename = `income_business_${username}_${this.props.year}_${this.props.quarter}.xlsx`;
    const workbook = XLSX.utils.table_to_book(document.getElementById('sheetjs'));
    XLSX.writeFile(workbook, filename);
  };

  unlockPage = (lockId) => () => {
    this.props.deleteUserLock(lockId);
  };

  lockPage = () => {
    this.props.addUserLock(
      this.props.year, this.props.quarter,
      'BusinessTypeA');
  };

  onIncomeBusinessTypeADayDialogClose = () => {
    this.setState({incomeBusinessTypeADayDialogOpen: false});
  };

  onIncomeBusinessTypeAMonthDialogClose = () => {
    this.setState({incomeBusinessTypeAMonthDialogOpen: false});
  };

  render() {
    const { classes } = this.props;
    let isUserLocked = false;
    let lockId = 0;
    if (this.props.isUserLocked) {
      for (const userLock of this.props.userLocks) {
        if (userLock.year === this.props.year &&
          userLock.quarter === this.props.quarter &&
          userLock.model_name === 'BusinessTypeA') {
          isUserLocked = true;
          lockId = userLock.id;
        }
      }
    }

    return (
      <React.Fragment>
        <div className={cn(classes.box, classes.namedBox)}>
          <div className={classes.boxName}><span>Summary</span></div>
          <div className={classes.categories}>
            <Button
              className={classes.dialogButton}
              variant="outlined"
              onClick={() => this.setState({incomeBusinessTypeADayDialogOpen: true})}
            >
              Day
            </Button>
            <Button
              className={classes.dialogButton}
              variant="outlined"
              onClick={() => this.setState({incomeBusinessTypeAMonthDialogOpen: true})}
            >
              Month
            </Button>
          </div>
        </div>

        <IncomeBusinessTypeAGrid />

        <div className={classes.actions}>
          <Tooltip title='Download Excel File'>
            <Fab aria-label="Download Excel File" className={classes.fab} onClick={this.downloadCSV}>
              <FontAwesomeIcon icon="file-excel" size="2x" />
            </Fab>
          </Tooltip>
          {isUserLocked ? (
            <Tooltip title='Unlock this Page'>
              <Fab disabled={!this.props.user.is_staff} aria-label='Unlock this Page' className={classes.fab} onClick={this.unlockPage(lockId)}>
                <FontAwesomeIcon icon="lock" size="2x" />
              </Fab>
            </Tooltip>
          ) : (
            <Tooltip title='Lock this Page'>
              <Fab aria-label='Lock this Page' className={classes.fab} onClick={this.lockPage}>
                <FontAwesomeIcon icon="unlock" size="2x" />
              </Fab>
            </Tooltip>
          )}
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        <IncomeBusinessTypeADayDialog open={this.state.incomeBusinessTypeADayDialogOpen} onClose={this.onIncomeBusinessTypeADayDialogClose}/>
        <IncomeBusinessTypeAMonthDialog open={this.state.incomeBusinessTypeAMonthDialogOpen} onClose={this.onIncomeBusinessTypeAMonthDialogClose}/>

        <table id="sheetjs" style={{display: 'none'}}>
          <thead>
          <tr>
            <th>Date</th>
            <th>Card</th>
            <th>Other</th>
            <th>Total</th>
            <th>GST Free</th>
            <th>GST</th>
            <th>Net</th>
          </tr>
          </thead>
          <tbody>
          {this.props.businessTypeAs && this.props.businessTypeAs.length > 0 &&
            this.props.businessTypeAs.map(row => {
              return (
                <tr key={row.id}>
                  <td>{moment(row.date).format('YYYY-MM-DD')}</td>
                  <td>{row.card}</td>
                  <td>{row.other}</td>
                  <td>{row.total}</td>
                  <td>{row.gst_free}</td>
                  <td>{row.gst}</td>
                  <td>{row.net}</td>
                </tr>
              );
            })
          }
          <tr>
            <td>Total</td>
            <td>{getTotal(this.props.businessTypeAs, 'card')}</td>
            <td>{getTotal(this.props.businessTypeAs, 'other')}</td>
            <td>{getTotal(this.props.businessTypeAs, 'total')}</td>
            <td>{getTotal(this.props.businessTypeAs, 'gst_free')}</td>
            <td>{getTotal(this.props.businessTypeAs, 'gst')}</td>
            <td>{getTotal(this.props.businessTypeAs, 'net')}</td>
          </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

IncomeBusinessTypeA.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let errors = [];
  if (state.income.errors) {
    errors = Object.keys(state.income.errors).map(field => {
      return {field, message: state.income.errors[field]};
    });
  }
  return {
    errors,
    businessTypeAs: state.income.businessTypeAs,
    config: state.auth.config,
    selectedConfig: state.admin.selectedConfig,
    selectedUser: state.admin.selectedUser,
    user: state.auth.user,
    start: state.period.start,
    end: state.period.end,
    year: state.period.year,
    quarter: state.period.quarter,
    isUserLocked: state.period.isUserLocked,
    userLocks: state.period.userLocks,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadConfig: () => dispatch(auth.loadConfig()),
    loadBusinessTypeAs: () => dispatch(income.loadBusinessTypeAs()),
    resetError: () => dispatch(income.resetError()),
    loadUserLocks: () => dispatch(period.loadUserLocks()),
    addUserLock: (year, quarter, model_name) => dispatch(period.addUserLock(year, quarter, model_name)),
    deleteUserLock: (id) => dispatch(period.deleteUserLock(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(IncomeBusinessTypeA)));
