import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter } from 'react-router-dom'
import { capitalize } from '@material-ui/core/utils/helpers';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabs: {
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      margin: 'auto',
    },
  }
});

const defaultTabs = ['income', 'expenditure', 'payroll', 'summary'];
const adminTabs = ['income', 'expenditure', 'payroll', 'summary', 'client', 'admin'];

class ScrollableTabs extends React.Component {
  state = {
    value: 0,
  };

  componentDidMount() {
    const { user } = this.props;
    const tabs = user && user.is_staff ? adminTabs : defaultTabs;
    const idx = tabs.indexOf(window.location.pathname.slice(1));
    this.setState({ value: idx === -1 ? 0 : idx});
  }

  handleChange = (event, value) => {
    const { history, user } = this.props;
    const tabs = user && user.is_staff ? adminTabs : defaultTabs;
    this.setState({ value });
    if (0 <= value <= tabs.length) {
      history.push(`/${tabs[value]}`);
    } else {
      history.push('/');
    }
  };

  render() {
    const { classes, children, user } = this.props;
    const { value } = this.state;
    const tabs = user && user.is_staff ? adminTabs : defaultTabs;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((v) => {return <Tab key={v} label={capitalize(v)} />})}
          </Tabs>
        </AppBar>
        {children}
      </div>
    );
  }
}

ScrollableTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScrollableTabs)));