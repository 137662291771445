import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getSelectableYears } from '../utils';
import { period, session } from '../actions';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

const styles = theme => ({
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  toggleContainer: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: `${theme.spacing.unit}px 0`,
  },
  item: {
    padding: theme.spacing.unit * 2,
  },
  button: {
    marginTop: 8,
    marginBottom: 8,
  },
  formControl: {
    marginTop: 8,
    marginBottom: 8,
  },
  table: {
    '& thead': {
      backgroundColor: '#8bc34a91',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#8bc34a21',
    }
  }
});

class ClientUserLock extends React.Component {
  state = {
    labelWidth: 50,
    year: '',
    quarter: '',
  };

  componentDidMount() {
    this.setState({
      labelWidth: this.InputLabelRef ? ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth : 50,
    });
    this.props.loadUserLocks();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value});
  };

  lock = () => {
    const { year, quarter } = this.state;
    if (year && quarter) {
      this.props.addUserLock(year, quarter);
    }
    this.props.updateSessionTime();
  };

  render() {
    const { classes, userLocks } = this.props;

    return (
      <React.Fragment>
        <div className={classes.box}>
          <div className={classes.item}>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-year-simple"
              >
                Year
              </InputLabel>
              <Select
                value={this.state.year}
                onChange={this.handleChange}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="year"
                    id="outlined-year-simple"
                  />
                }
              >
                {getSelectableYears().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-quarter-simple"
              >
                Month
              </InputLabel>
              <Select
                value={this.state.quarter}
                onChange={this.handleChange}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="quarter"
                    id="outlined-quarter-simple"
                  />
                }
              >
                <MenuItem value={1}>March</MenuItem>
                <MenuItem value={2}>June</MenuItem>
                <MenuItem value={3}>September</MenuItem>
                <MenuItem value={4}>December</MenuItem>
              </Select>
            </FormControl>
            <Button className={classes.button} fullWidth size="large" variant="outlined" color="primary" onClick={this.lock}>Lock</Button>
          </div>

          <div className={classes.item}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Quarter</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLocks.filter(lock => lock.model_name === 'all').map(lock => (
                  <TableRow key={`lock-${lock.year}-${lock.quarter}`}>
                    <TableCell>{lock.year}</TableCell>
                    <TableCell>{lock.quarter}</TableCell>
                    <TableCell><Button variant={"outlined"} size={"small"} onClick={_ => this.props.deleteUserLock(lock.id)}>Unlock</Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ClientUserLock.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    userLocks: state.period.userLocks,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadUserLocks: () => dispatch(period.loadUserLocks()),
    addUserLock: (year, quarter) => dispatch(period.addUserLock(year, quarter, 'all')),
    deleteUserLock: (id) => dispatch(period.deleteUserLock(id)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientUserLock));