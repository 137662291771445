import moment from 'moment';

export const loadBenchmark = (year) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_BENCHMARK_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const body = JSON.stringify({
      year,
      user: userId,
      json: {
        key: [0, 0],
        cost_of_sales: [0, 0],
        total_expenses: [0, 0],
        non_capital_purchase: [0, 0],
        labour: [0, 0],
        rent: [0, 0],
        cash: [0, 0],
      }
    });

    return fetch(`/api/benchmark/?year=${year}&user=${userId}`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            if (data && data.length > 0) {
              dispatch({type: 'LOAD_BENCHMARK_SUCCESS', data: data[0]});
            } else {
              fetch(`/api/benchmark/`, {headers, body, method: 'POST'})
                .then(res => {
                  if (200 <= res.status && res.status < 300) {
                    res.json().then(data => {
                      dispatch({type: 'LOAD_BENCHMARK_SUCCESS', data: data});
                    }).catch(err => {
                      throw err;
                    })
                  } else {
                    dispatch({type: "LOAD_BENCHMARK_FAILURE", data: res.data});
                  }
                })
                .catch(err => {
                  throw err;
                });
            }
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_BENCHMARK_FAILURE", data: res.data});
        }
      }).catch(err => {
        dispatch({type: "LOAD_BENCHMARK_FAILURE", data: {'detail': 'Server Error'}});
      });
  }
};

export const updateBenchmark = (benchmark) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPDATE_BENCHMARK_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const body = JSON.stringify({
      user: benchmark.user,
      year: benchmark.year,
      json: benchmark.json
    });

    return fetch(`/api/benchmark/${benchmark.id}/`, {headers, body, method: 'PUT'})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: "UPDATE_BENCHMARK_SUCCESS", data});
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "UPDATE_BENCHMARK_FAILURE", data: res.data});
        }
      })
      .catch(err => {
        dispatch({type: "UPDATE_BENCHMARK_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};

export const loadBenchmarkClientResult = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_BENCHMARK_CLIENT_RESULT_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { fiscalEnd } = getState().period;
    const body = JSON.stringify({
      user: userId,
      year: moment(fiscalEnd).year()
    });

    return fetch(`/api/benchmark_client_result/`, {headers, body, method: 'POST'})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: "LOAD_BENCHMARK_CLIENT_RESULT_SUCCESS", data});
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_BENCHMARK_CLIENT_RESULT_FAILURE", data: res.data});
        }
      })
      .catch(err => {
        dispatch({type: "LOAD_BENCHMARK_CLIENT_RESULT_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};

export const loadPL = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_PL_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { year, quarter } = getState().period;
    const body = JSON.stringify({
      user: userId,
      year,
      quarter
    });

    return fetch(`/api/pl/`, {headers, body, method: 'POST'})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: "LOAD_PL_SUCCESS", data});
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_PL_FAILURE", data: res.data});
        }
      })
      .catch(err => {
        dispatch({type: "LOAD_PL_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};

export const loadPLYear = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_PL_YEAR_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { fiscalYear } = getState().period;
    const body = JSON.stringify({
      user: userId,
      fiscal_year: fiscalYear,
    });

    return fetch(`/api/pl-year/`, {headers, body, method: 'POST'})
        .then(res => {
          if (200 <= res.status && res.status < 300) {
            res.json().then(data => {
              dispatch({type: "LOAD_PL_YEAR_SUCCESS", data});
            }).catch(err => {
              throw err;
            });
          } else {
            dispatch({type: "LOAD_PL_YEAR_FAILURE", data: res.data});
          }
        })
        .catch(err => {
          dispatch({type: "LOAD_PL_YEAR_FAILURE", data: {'detail': 'Server Error'}});
        });
  };
};

export const resetError = () => {
  return (dispatch, getState) => {
    dispatch({type: 'RESET_ERROR'});
  }
};
