import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import { session } from '../actions';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
    }
  },
  itemText: {
    letterSpacing: '0.02857em',
    lineHeight: '1.75',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.8)'
  },
  badge: {
    top: '50%',
    right: theme.spacing.unit,
    borderRadius: 4,
    backgroundColor: '#fbee73',
  }
});

class ExpenditureMenu extends React.Component {
  state = {
    selectedIndex: 0,
  };

  handleListItemClick = (event, index) => {
    this.setState({ selectedIndex: index });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(index);
    }
    this.props.updateSessionTime();
  };

  render() {
    const { classes } = this.props;
    const { expenses, assets, loans } = this.props;

    return (
      <div className={classes.root}>
        <List component="nav">
          <ListItem
            button
            selected={this.state.selectedIndex === 0}
            onClick={event => this.handleListItemClick(event, 0)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="Expense"/>
            {expenses && <Badge badgeContent={expenses.length} classes={{badge: classes.badge}}>{""}</Badge>}
          </ListItem>
          <ListItem
            button
            selected={this.state.selectedIndex === 1}
            onClick={event => this.handleListItemClick(event, 1)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="Asset" />
            {assets && <Badge badgeContent={assets.length} classes={{badge: classes.badge}}>{""}</Badge>}
          </ListItem>
          <ListItem
            button
            selected={this.state.selectedIndex === 2}
            onClick={event => this.handleListItemClick(event, 2)}
          >
            <ListItemText classes={{primary: classes.itemText}} primary="Loan" />
            {loans && <Badge badgeContent={loans.length} classes={{badge: classes.badge}}>{""}</Badge>}
          </ListItem>
        </List>
      </div>
    );
  }
}

ExpenditureMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    expenses: state.expenditure.expenses,
    loans: state.expenditure.loans,
    assets: state.expenditure.assets,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExpenditureMenu));