export const loadUsers = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      return;
    }

    return fetch("/api/user/", {headers, })
      .then(res => {
        if ( 200 <= res.status && res.status < 300) {
          return res.json();
        }
      }).then(data => {
        dispatch({type: 'LOAD_USERS_SUCCESS', users: data});
      }).catch(err => {
        console.error(err);
      })
  }
};

export const selectUser = (user) => {
  return (dispatch, getState) => {
    dispatch({type: 'SELECT_USER', user: null, config: null});
    if (!!!user) {
      return;
    }

    const token = getState().auth.token;

    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      return;
    }

    return fetch(`/api/config/?user=${user.id}`, {headers, })
      .then(res => {
        if ( 200 <= res.status && res.status < 300) {
          return res.json();
        }
      }).then(data => {
        if (data && data.length > 0) {
          dispatch({type: 'SELECT_USER', user, config: data[0]});
        }
      }).catch(err => {
        console.error(err);
      })
  }
};

export const updateConfig = (config) => {
  return (dispatch, getState) => {
    const { selectedUser, selectedConfig } = getState().admin;
    if (!!!selectedUser || !!!selectedConfig) {
      return;
    }
    dispatch({type: 'UPDATE_CONFIG', config});
  }
};

export const saveConfig = () => {
  return (dispatch, getState) => {
    const { selectedUser, selectedConfig } = getState().admin;
    if (!!!selectedUser || !!!selectedConfig) {
      return;
    }

    const token = getState().auth.token;

    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      return;
    }

    const body = JSON.stringify({ 'json': selectedConfig.json });

    return fetch(`/api/config/${selectedConfig.id}/`, {headers, body, method: 'PATCH'})
      .then(res => {
        if ( 200 <= res.status && res.status < 300) {
          return res.json();
        }
      }).then(data => {
        if (data) {
          dispatch({type: 'SAVE_CONFIG_SUCCESS', config: data});
        }
      }).catch(err => {
        console.error(err);
      });
  }
};

export const updateUser = (userId, username, password, is_active) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: "UPDATE_USER_FAILURE", data: {'detail': 'UnAuthorized'}});
      return;
    }

    const body = JSON.stringify({
      user_id: userId,
      username,
      password,
      is_active
    });

    return fetch(`/api/update/user/`, {headers, body, method: 'PUT'})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPDATE_USER_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "UPDATE_USER_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "UPDATE_USER_FAILURE", data: {'detail': 'Server Error'}});
      });
  }
};
