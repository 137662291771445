import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  root: {
    flex: '0 1 auto',
    display: 'flex',
    marginLeft: theme.spacing.unit
  },
  paper: {
    backgroundColor: '#fbee73',
  },
  button: {
    backgroundColor: '#fbee73',
    '&:hover': {
      backgroundColor: '#cea336',
    },
    color: '#330809',
    '& > span > span': {
      overflowX: 'hidden',
      whiteSpace: 'nowrap;',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing.unit,
    }
  },
  arrow: {
    position: 'absolute',
    left: 6,
  },
  small: {
    margin: theme.spacing.unit * 2,
  },
});

class CategoryButton extends React.Component {
  state = {
    open: false,
    buttonOver: false,
    menuOver: false,
    menuClicked: false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleButtonOver = () => {
    this.setState(state => ({ open: true, buttonOver: true }));
  };

  handleButtonOut = () => {
    if (this.state.menuOver) {
      this.setState(state => ({ buttonOver: false }));
    } else {
      this.setState(state => ({ open: false, buttonOver: false }));
    }
  };

  handleMenuOver = () => {
    if (!this.state.menuClicked) {
      this.setState(state => ({ open: true, menuOver: true }));
    }
  };

  handleMenuOut = () => {
    if (this.state.buttonOver) {
      this.setState(state => ({ menuOver: false }));
    } else {
      this.setState(state => ({ open: false, menuOver: false }));
    }
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleClick = (index) => (event) => {
    this.props.onClick(index);
    this.setState({ menuClicked: true });
    window.setTimeout(() => {
      this.setState({menuClicked: false});
    }, 500);
    this.handleClose(event);
  };

  render() {
    const { classes, main } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <Button
          className={classes.button}
          buttonRef={node => {
            this.anchorEl = node;
          }}
          variant="outlined"
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
          onMouseOver={this.handleButtonOver}
          onMouseOut={this.handleButtonOut}
        >
          <span className={classes.desc}>{main.name}</span>
        </Button>
        <Popper
          style={{zIndex: 4}}
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          onMouseOver={this.handleMenuOver}
          onMouseOut={this.handleMenuOut}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    <small className={classes.small}>{main.name.toUpperCase()}</small>
                    <Divider />
                    {main.subs.map((sub, i) => {
                      return (
                        <MenuItem key={`category-sub-${i}`} onClick={this.handleClick(i)}>{sub.name}</MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

CategoryButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(CategoryButton));
