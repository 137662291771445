import moment from 'moment';

export const addExpense = (main, sub, code, date, gross, gst, net, detail) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'ADD_EXPENSE_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (getState().auth.user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = getState().auth.user.id;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'ADD_EXPENSE_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    let body = JSON.stringify({main, sub, code,
      date: date.format('YYYY-MM-DD'),
      gross: Number(gross),
      gst: Number(gst),
      net: Number(net),
      detail,
      user: userId,
      year,
      quarter,
      model_name: 'Expense',
    });

    return fetch("/api/expense/", {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 201) {
          res.json().then(data => {
            dispatch({type: 'ADD_EXPENSE_SUCCESS', data});
            dispatch(loadExpenses());
            return data;
          }).catch(err => {
            throw err;
          });
        } else if (res.status === 403 || res.status === 401) {
          res.json().then(data => {
            dispatch({type: "ADD_EXPENSE_FAILED", data: data});
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "ADD_EXPENSE_ERROR", data: res.data});
        }
      }).catch(err => {
        dispatch({type: "ADD_EXPENSE_ERROR", data: {'detail': 'Server Error'}});
      });
  };
};

export const updateExpense = (id, main, sub, code, date, gross, gst, net, detail, user) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPDATE_EXPENSE_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'UPDATE_EXPENSE_FAILED', data: {'detail': 'Period did not set'}});
      return;
    }

    let body = JSON.stringify({
      main, sub, code,
      date: moment(date).format('YYYY-MM-DD'),
      gross: Number(gross),
      gst: Number(gst),
      net: Number(net),
      detail,
      user: user,
      year,
      quarter,
    });

    return fetch(`/api/expense/${id}/`, {headers, body, method: "PUT"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPDATE_EXPENSE_SUCCESS', data});
            dispatch(loadExpenses());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "UPDATE_EXPENSE_FAILED", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "UPDATE_EXPENSE_ERROR", data: {'detail': 'Server Error'}});
      });
  };
};

export const loadExpenses = () => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token,
      user = getState().auth.user;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_EXPENSES_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { start, end } = getState().period;
    if (!start || !end) {
      dispatch({type: 'LOAD_EXPENSES_FAILURE', data: {'detail': 'Invalid date range'}});
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    return fetch(`/api/expense/?user=${userId}&date_after=${start}&date_before=${end}`, {headers, method: "GET"})
      .then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            dispatch({type: 'LOAD_EXPENSES_SUCCESS', data});
            return data;
          }).catch(err => {
            console.log(err);
          });
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: "LOAD_EXPENSES_FAILED", data: res.data});
          throw res.data;
        } else {
          dispatch({type: "LOAD_EXPENSES_ERROR", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_EXPENSES_ERROR", data: {'detail': 'Server Error'}});
      });
  }
};

export const deleteExpense = (id) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'DELETE_EXPENSE_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/expense/${id}/`, {headers, method: "DELETE"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          dispatch({type: 'DELETE_EXPENSE_SUCCESS'});
          dispatch(loadExpenses());
        } else {
          res.json().then(data => {
            dispatch({type: "DELETE_EXPENSE_FAILED", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "DELETE_EXPENSE_ERROR", data: {'detail': 'Server Error'}});
      });
  };
};



export const loadAssets = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_ASSETS_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { start, end } = getState().period;
    if (!start || !end) {
      dispatch({type: 'LOAD_ASSETS_FAILURE', data: {'detail': 'Invalid date range'}});
    }

    return fetch(`/api/asset/?user=${userId}&date_after=${start}&date_before=${end}`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_ASSETS_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_ASSETS_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_ASSETS_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const updateAsset = (id, item, date, gross, gst, net, detail, user_id) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPDATE_ASSET_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'UPDATE_ASSET_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    const body = JSON.stringify({
      item: item,
      date: moment(date).format('YYYY-MM-DD'),
      gross: Number(gross),
      gst: Number(gst),
      net: Number(net),
      detail: detail,
      user: user_id,
      year,
      quarter
    });

    return fetch(`/api/asset/${id}/`, {headers, body, method: "PUT"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPDATE_ASSET_SUCCESS', data});
            dispatch(loadAssets());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "UPDATE_ASSET_FAILURE", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "UPDATE_ASSET_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const deleteAsset = (id) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'DELETE_ASSET_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/asset/${id}/`, {headers, method: "DELETE"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          dispatch({type: 'DELETE_ASSET_SUCCESS'});
          dispatch(loadAssets());
        } else {
          dispatch({type: "DELETE_ASSET_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "DELETE_ASSET_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const addAsset = (item, date, gross, gst, net, detail) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'ADD_ASSET_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'ADD_ASSET_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const body = JSON.stringify({
      item,
      date: moment(date).format('YYYY-MM-DD'),
      gross: Number(gross),
      gst: Number(gst),
      net: Number(net),
      detail,
      user: userId,
      year,
      quarter,
      model_name: 'Asset',
    });

    return fetch(`/api/asset/`, {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'ADD_ASSET_SUCCESS', data});
            dispatch(loadAssets());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "ADD_ASSET_FAILURE", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "ADD_ASSET_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};



export const loadLoans = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'LOAD_LOANS_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const { start, end } = getState().period;
    if (!start || !end) {
      dispatch({type: 'LOAD_LOANS_FAILURE', data: {'detail': 'Invalid date range'}});
    }

    return fetch(`/api/loan/?user=${userId}&date_after=${start}&date_before=${end}`, {headers, })
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'LOAD_LOANS_SUCCESS', data});
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          dispatch({type: "LOAD_LOANS_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "LOAD_LOANS_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const updateLoan = (id, item, date, gross, detail, user_id) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'UPDATE_LOAN_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'UPDATE_LOAN_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    const body = JSON.stringify({
      item,
      date: moment(date).format('YYYY-MM-DD'),
      gross: Number(gross),
      detail,
      user: user_id,
      year,
      quarter
    });

    return fetch(`/api/loan/${id}/`, {headers, body, method: "PUT"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'UPDATE_LOAN_SUCCESS', data});
            dispatch(loadLoans());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "UPDATE_LOAN_FAILURE", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "UPDATE_LOAN_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const deleteLoan = (id) => {
  return (dispatch, getState) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const token = getState().auth.token;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'DELETE_LOAN_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    return fetch(`/api/loan/${id}/`, {headers, method: "DELETE"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          dispatch({type: 'DELETE_LOAN_SUCCESS'});
          dispatch(loadLoans());
        } else {
          dispatch({type: "DELETE_LOAN_FAILURE", data: res.data});
          throw res.data;
        }
      }).catch(err => {
        dispatch({type: "DELETE_LOAN_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};


export const addLoan = (item, date, gross, detail) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const { token, user } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    } else {
      dispatch({type: 'ADD_LOAN_FAILURE', data: {'detail': 'UnAuthorized'}});
      return;
    }

    const { year, quarter } = getState().period;
    if (!year || !quarter) {
      dispatch({type: 'ADD_LOAN_FAILURE', data: {'detail': 'Period did not set'}});
      return;
    }

    let userId;
    if (user.is_staff && getState().admin.selectedUser) {
      userId = getState().admin.selectedUser.id;
    } else {
      userId = user.id;
    }

    const body = JSON.stringify({
      item,
      date: moment(date).format('YYYY-MM-DD'),
      gross: Number(gross),
      detail,
      user: userId,
      year,
      quarter,
      model_name: 'Loan',
    });

    return fetch(`/api/loan/`, {headers, body, method: "POST"})
      .then(res => {
        if (200 <= res.status && res.status < 300) {
          res.json().then(data => {
            dispatch({type: 'ADD_LOAN_SUCCESS', data});
            dispatch(loadLoans());
            return data;
          }).catch(err => {
            throw err;
          });
        } else {
          res.json().then(data => {
            dispatch({type: "ADD_LOAN_FAILURE", data});
          }).catch(err => {
            throw err;
          });
        }
      }).catch(err => {
        dispatch({type: "ADD_LOAN_FAILURE", data: {'detail': 'Server Error'}});
      });
  };
};

export const resetError = () => {
  return (dispatch, getState) => {
    dispatch({type: 'RESET_ERROR'});
  }
};
