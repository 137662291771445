const initialState = {
  isLoading: true,
  isLoaded: false,
  notice: null,
  errors: {},
};


export default function auth(state=initialState, action) {

  switch (action.type) {

    case 'NOTICE_LOADING':
      return {...state, isLoading: true, isLoaded: false, notice: null, errors: {}};

    case 'NOTICE_LOADED':
      return {...state, isLoading: false, isLoaded: true, notice: action.notice, errors: {}};

    case 'NOTICE_LOAD_FAILED':
      return {...state, isLoading: false, isLoaded: false, notice: null, errors: action.data};

    case 'SAVE_NOTICE_SUCCESS':
      return {...state, errors: {}};

    case 'SAVE_NOTICE_FAILURE':
      return {...state, errors: action.data};

    default:
      return state;
  }
}
