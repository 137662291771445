import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { admin, registration, session } from '../actions';
import ClientUserListTable from './ClientUserListTable';
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/es/withMobileDialog/withMobileDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  table: {
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.15)',
    marginTop: 0,
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: 'white',
  },
  formControl: {
    marginTop: 8,
    marginBottom: 8,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

class ClientUserList extends React.Component {
  state = {
    labelWidth: 80,
    dialogOpen: false,
    username: '',
    nickname: '',
    password: '',
    isStaff: false,
  };

  componentDidMount() {
    this.props.loadUsers();
  }

  openDialog = () => {
    this.setState({ dialogOpen: true, username: '', nickname: '', password: '', isStaff: false });
  };

  handleCreateUser = () => {
    const { username, nickname, password, isStaff } = this.state;
    this.props.register(username, nickname, password, isStaff);
    window.setTimeout(() => {
      this.props.loadUsers();
      this.setState({ dialogOpen: false });
    }, 2000);
    this.props.updateSessionTime();
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({[name]: event.target.value});
  };

  handleCheckedChange = name => e => {
    this.setState({ [name]: e.target.checked });
  };

  render() {
    const { classes, fullScreen } = this.props;
    const { username, nickname, password, isStaff } = this.state;

    return (
      <React.Fragment>
        <ClientUserListTable classes={{root: classes.table}}/>
        <Button className={classes.button} variant="outlined" color="primary" onClick={this.openDialog}>Create</Button>

        <Dialog
          fullScreen={fullScreen}
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Create User</DialogTitle>
          <DialogContent className={classes.content}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-username"
              >
                ID
              </InputLabel>
              <OutlinedInput
                fullWidth
                labelWidth={this.state.labelWidth}
                name="username"
                id="outlined-username"
                value={username}
                onChange={this.handleChange('username')}
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-nickname"
              >
                Name
              </InputLabel>
              <OutlinedInput
                fullWidth
                labelWidth={this.state.labelWidth}
                name="nickname"
                id="outlined-nickname"
                value={nickname}
                onChange={this.handleChange('nickname')}
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-password"
              >
                Password
              </InputLabel>
              <OutlinedInput
                fullWidth
                labelWidth={this.state.labelWidth}
                name="password"
                id="outlined-password"
                value={password}
                onChange={this.handleChange('password')}
              />
            </FormControl>
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  checked={isStaff}
                  onChange={this.handleCheckedChange('isStaff')}
                />
              }
              label='Admin'
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleCreateUser} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ClientUserList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    users: state.admin.users,
    selectedUser: state.admin.selectedUser,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadUsers: () => dispatch(admin.loadUsers()),
    selectUser: (user) => dispatch(admin.selectUser(user)),
    register: (...args) => dispatch(registration.register(...args)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(ClientUserList)));