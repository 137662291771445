import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { auth, period, expenditure, admin } from "../actions";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExitToApp from '@material-ui/icons/ExitToApp';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import { getSelectableYears } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import cn from 'classnames';
import WhiteLogo from '../images/logo_white.png';

const styles = theme => ({
  root: {
    width: '100%',
  },
  appBar: {
    backgroundColor: theme.palette.primary,
  },
  toolbar: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    width: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      margin: 'auto',
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: 8,
    marginRight: 8,
  },
  title: {
    display: 'none',
    fontFamily: '"BM Jua TTF"',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logo: {
    display: 'none',
    height: 40,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 200,
      '&:focus-within': {
        width: 250,
      }
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    fontFamily: '"BM Jua TTF"',
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  inputFocused: {
    width: '100%',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuIcon: {
    padding: '12px 12px 12px 0'
  },
  formControl: {
    minWidth: 160,
    textAlign: 'center',
    margin: '0 8px',
    backgroundColor: 'white',
    borderRadius: 4,
    '& div[role="button"]': {
      paddingTop: 8,
      paddingBottom: 8,
    },
    '& input[name="username"]': {
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 80,
    }
  },
  hidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ebebeb',
    borderRadius: 4,
    border: '1px solid rgba(0,0,0,0.15)',
    borderBottom: '1px solid #292929',
    padding: '8px 24px',
    margin: '0 8px',
    height: 35,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  svg: {
    width: 19,
  },
  '@keyframes dash': {
    '0%': {
      strokeDashoffset: 1000
    },
    '100%': {
      strokeDashoffset: 0
    }
  },
  '@keyframes dashCheck': {
    '0%': {
      strokeDashoffset: -100
    },
    '100%': {
      strokeDashoffset: 900
    }
  },
  path: {
    strokeDasharray: 1000,
    strokeDashoffset: 0,
  },
  circle: {
    animation: 'dash .9s ease-in-out',
  },
  line: {
    strokeDashoffset: 1000,
    animation: 'dash .9s .35s ease-in-out forwards',
  },
  check: {
    strokeDashoffset: -100,
    animation: 'dashCheck .9s .35s ease-in-out forwards',
  },
  none: {
    opacity: 0
  },
  mobileLeft: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 48,
    }
  },
});

class Header extends React.Component {
  state = {
    username: '',
    anchorEl: null,
    mobileMoreAnchorEl: null,
    showUserSelectResult: false,
    isUserSelected: false,
  };

  componentDidMount() {
    if (this.props.user) {
      this.setState({
        username: this.props.selectedUser ? this.props.selectedUser.username : this.props.user.username
      });
    }
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleLogout = () => {
    this.handleMenuClose();
    this.props.logout();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleChange = (event) => {
    const yq = {...this.props, [event.target.name]: event.target.value};
    this.props.selectPeriod(yq.year, yq.quarter);
  };

  handleUsernameChange = (event) => {
    this.setState({username: event.target.value});
  };

  selectUser = () => {
    const { username } = this.state;
    const { users } = this.props;
    this.setState({showUserSelectResult: true});
    if (this.timer) {
      window.clearTimeout(this.timer);
    }
    for (const user of users) {
      if (username === user.username) {
        this.props.selectUser(user);
        this.setState({isUserSelected: true});
        this.timer = window.setTimeout(() => {
          this.setState({showUserSelectResult: false});
        }, 2000);
        return;
      }
    }
    this.setState({isUserSelected: false});
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes, user, selectedUser, isLocked, isAllLocked } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMenuClose}>
          <IconButton color="inherit" className={classes.menuIcon}>
            <AccountCircle />
          </IconButton>
          {user.username}
        </MenuItem>
        <MenuItem onClick={this.handleLogout}>
          <IconButton color="inherit" className={classes.menuIcon}>
            <ExitToApp />
          </IconButton>
          Logout
        </MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMobileMenuClose}>
          <IconButton color="inherit" className={classes.menuIcon}>
            <AccountCircle />
          </IconButton>
          <p>{user.username}</p>
        </MenuItem>
        <MenuItem onClick={this.handleLogout}>
          <IconButton color="inherit" className={classes.menuIcon}>
            <ExitToApp />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <img className={cn(classes.menuButton, classes.logo)} src={WhiteLogo} alt="logo" />
            <div className={classes.grow} />
            {user.is_staff ? (
              <FormControl variant="outlined" className={cn(classes.formControl, classes.grow, classes.hidden)}>
                <FilledInput
                  margin="dense"
                  name="username"
                  id="outlined-header-username"
                  defaultValue={selectedUser ? selectedUser.username : user.username}
                  onChange={this.handleUsernameChange}
                  endAdornment={
                    this.state.isUserSelected ? (
                      <svg className={cn(classes.svg, this.state.showUserSelectResult && this.state.isUserSelected ? null : classes.none)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle className={cn(classes.path, classes.circle)} fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10"
                                cx="65.1" cy="65.1" r="62.1"/>
                        <polyline className={cn(classes.path, classes.check)} fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round"
                                  strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                      </svg>
                    ) : (
                      <svg className={cn(classes.svg, this.state.showUserSelectResult && !this.state.isUserSelected ? null : classes.none)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle className={cn(classes.path, classes.circle)} fill="none" stroke="#D06079" strokeWidth="6" strokeMiterlimit="10"
                                cx="65.1" cy="65.1" r="62.1"/>
                        <line className={cn(classes.path, classes.line)} fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round"
                              strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                        <line className={cn(classes.path, classes.line)} fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round"
                              strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                      </svg>
                    )
                  }
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      this.selectUser();
                      ev.preventDefault();
                    }
                  }}
                />
              </FormControl>
            ) : (
              <div className={classes.user}>
                {user.nickname}
              </div>
            )}
            {user.is_staff && (
              <div className={classes.user}>
                {selectedUser ? selectedUser.nickname : user.nickname}
              </div>
            )}
            <FormControl variant="outlined" className={cn(classes.formControl, classes.mobileLeft)}>
              <Select
                value={this.props.year}
                onChange={this.handleChange}
                input={
                  <FilledInput
                    margin="dense"
                    name="year"
                    id="outlined-year"
                  />
                }
              >
                {getSelectableYears().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                value={this.props.quarter}
                onChange={this.handleChange}
                input={
                  <FilledInput
                    margin="dense"
                    name="quarter"
                    id="outlined-quarter"
                  />
                }
              >
                <MenuItem value={1}>March</MenuItem>
                <MenuItem value={2}>June</MenuItem>
                <MenuItem value={3}>September</MenuItem>
                <MenuItem value={4}>December</MenuItem>
              </Select>
            </FormControl>
            {(isLocked || isAllLocked) && <Tooltip title={"You can not edit this period"}><FontAwesomeIcon style={{marginLeft: 8}} icon={"lock"} /></Tooltip>}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return {field, message: state.auth.errors[field]};
    });
  }
  return {
    errors,
    user: state.auth.user,
    selectedUser: state.admin.selectedUser,
    year: state.period.year,
    quarter: state.period.quarter,
    isLocked: state.period.isLocked,
    isAllLocked: state.period.isAllLocked,
    users: state.admin.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(auth.logout()),
    selectPeriod: (year, quarter) => dispatch(period.selectPeriod(year, quarter)),
    selectUser: (user) => dispatch(admin.selectUser(user)),
    loadLoans: () => dispatch(expenditure.loadLoans()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));