export const updateSessionTime = () => {
  return (dispatch) => {
    return dispatch({type: 'UPDATE_SESSION_TIME'});
  }
};

export const setSessionTimeout = () => {
  return (dispatch) => {
    return dispatch({type: 'SET_SESSION_TIMEOUT'});
  }
};
