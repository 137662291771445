import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { Editors, Formatters } from "react-data-grid-addons";

const { DropDownEditor } = Editors;
const { DropDownFormatter } = Formatters;


export const currencyFormat = (num) => {
  const value = Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  if (Number(num) < 0) {
    return <span style={{color: '#f00'}}>{value}</span>
  } else {
    return <span>{value}</span>
  }
};

export const currencyFormatWithPercent = (num) => {
  const value = Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  if (Number(num) < 0) {
    return <span style={{color: '#f00'}}>{value}%</span>
  } else {
    return <span>{value}%</span>
  }
};

export const currencyFormatNum = (num) => {
  return Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const DateFormatter = ({ value }) => {
  return <span>{moment(value).format('DD/MM/YYYY')}</span>;
};

export const NumberFormatter = ({ value }) => {
  if (value >= 0) {
    return (
      <span>{value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
    );
  } else {
    return (
      <span style={{color: 'red'}}>{value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
    );
  }
};

export const DetailFormatter = ({ value }) => {
  return <Tooltip title={value}><span>{value}</span></Tooltip>
};

export const BooleanFormatter = ({ value }) => {
  return <span>{value ? 'Yes' : 'No'}</span>
};

export const GenderFormatter = ({ value }) => {
  switch (value) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    default:
      return 'Empty';
  }
};

export const TFNFormatter = ({ value }) => {
  return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
};

export const VisaFormatter = ({ value }) => {
  switch (value) {
    case 'R':
      return 'Resident';
    case 'N':
      return 'Non-Resident';
    case 'W':
      return 'Working';
    default:
      return 'Invalid';
  }
};

export const computeGST = (rate, income) => {
  return Math.round(Number(income) * Number(rate) / (100 + Number(rate)) * 100) / 100;
};

export const getSelectableYears = () => {
  // 시작은 무조건 2018년부터
  // 현재 년도를 기준으로 현재 날짜가 7월 1일 이후면 +1까지
  const startYear = 2018;
  let offset = moment().year() - startYear;
  if (moment().quarter() > 2) {
    offset += 1;
  }
  const years = [startYear];
  for (let i = 0; i < offset; ++i) {
    years.push(startYear + 1 + i);
  }
  return years;
};

const visaTypes = [
  { id: "resident", value: "R", text: 'Resident' },
  { id: "non-resident", value: "N", text: 'Non-Resident' },
  { id: "working", value: "W", text: 'Working' }
];
export const VisaTypeEditor = <DropDownEditor options={visaTypes} formatter={DropDownFormatter} />;

const booleanTypes = [
  { id: "yes", value: true, text: 'Yes' },
  { id: "no", value: false, text: 'No' },
];
export const BooleanTypeEditor = <DropDownEditor options={booleanTypes} formatter={DropDownFormatter} />;

const genderTypes = [
  { id: "empty", value: 'E', text: 'Empty' },
  { id: "male", value: 'M', text: 'Male' },
  { id: "female", value: 'F', text: 'Female' },
];
export const GenderTypeEditor = <DropDownEditor options={genderTypes} formatter={DropDownFormatter} />;

export const getTotal = (datas, colName) => {
  if (!datas || datas.length === 0) {
    return 0;
  }
  return datas.reduce((total, data) => total + Number(data[colName]), 0);
};
