import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auth, payroll, period, session } from '../actions';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/es/withMobileDialog';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { currencyFormat, currencyFormatNum } from '../utils';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputBase from '@material-ui/core/InputBase';
import XLSX from 'xlsx';


const styles = theme => ({
  categories: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'nowrap',
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'nowrap',
    }
  },
  actions: {
    marginTop: theme.spacing.unit * 2,
  },
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  namedBox: {
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  boxMargin: {
    marginTop: 32,
  },
  boxName: {
    margin: '-32px 8px 8px 8px',
    borderRadius: 4,
    padding: '8px 16px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(59, 132, 3, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(239, 239, 239, 0.4)',
    '& > span': {
      color: '#26710c',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
  },
  formControl: {
    flex: '1 1 auto',
    margin: theme.spacing.unit,
    minWidth: 140,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  button: {
    padding: '14px 24px',
  },
  dialogButton: {
    backgroundColor: '#fbee73',
    '&:hover': {
      backgroundColor: '#cea336',
    },
    color: '#330809',
    minWidth: 160,
    marginLeft: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing.unit,
    }
  },
  fab: {
    margin: theme.spacing.unit,
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.15)',
  },
  table: {
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
    '& thead': {
      backgroundColor: '#8bc34a91',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#8bc34a21',
    },
    '& tbody tr:last-child': {
      backgroundColor: '#fbee7391',
    },
    '& td': {
      padding: '4px 24px',
      border: '1px solid #ddd',
    },
    '& th': {
      padding: '4px 24px',
      border: '1px solid #ddd',
    }
  },
  yearSummaryTable: {
    '& thead': {
      backgroundColor: '#8bc34a91',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#8bc34a21',
    },
    '& tbody tr:last-child': {
      backgroundColor: '#fbee7391',
    },
    '& td': {
      padding: '4px 24px',
      border: '1px solid #ddd',
    },
    '& th': {
      padding: '4px 24px',
      border: '1px solid #ddd',
    }
  },
  bootstrapInput: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '5px 6px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
});

class PayrollWage extends React.Component {
  state = {
    quarterView: true,
    dialogOpen: false,
    yearDialogOpen: false,
    employee: null,
    wages: [],
    messages: [],
  };

  componentDidMount() {
    this.props.loadEmployees();
    this.props.loadEmployeeSummaries(this.state.quarterView);
    this.props.loadWageYearSummary();
    this.props.loadUserLocks();
    this.props.loadConfig();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errors !== prevProps.errors && this.props.errors.length > 0) {
      for (const error of this.props.errors) {
        this.openSnackbar(error.message);
      }
    }
    if (this.props.selectedUser !== prevProps.selectedUser && this.props.selectedUser) {
      this.props.loadEmployees();
      this.props.loadEmployeeSummaries(this.state.quarterView);
      this.props.loadWageYearSummary();
      this.props.loadUserLocks();
    }
    if (this.props.year !== prevProps.year || this.props.quarter !== prevProps.quarter) {
      this.props.loadEmployees();
      this.props.loadEmployeeSummaries(this.state.quarterView);
      this.props.loadWageYearSummary();
      this.props.loadUserLocks();
    }
  }

  static getDerivedStateFromProps(props, state) {
    return { wages: props.wages };
  }

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  getPayrollType = () => {
    const { selectedConfig } = this.props;
    const config = selectedConfig ? selectedConfig : this.props.config;
    if (!config || !config.json || !config.json.payroll || !config.json.payroll.payrollType) {
      return '';
    }
    return config.json.payroll.payrollType;
  };

  getRowCount = (payrollType) => {
    switch (payrollType) {
      case 'F':
        return 7;
      case 'M':
        return 3;
      case 'W':
      default:
        return 13;
    }
  };

  downloadYearCSV = () => {
    const username = this.props.selectedUser && this.props.selectedUser.username ? this.props.selectedUser.username : this.props.user.username;
    const filename = `payroll_wage_year_summary_${username}_${this.props.year}_${this.props.quarter}.xlsx`;
    const workbook = XLSX.utils.table_to_book(document.getElementById('sheetjs-year'));
    XLSX.writeFile(workbook, filename);
  };

  downloadCSV = () => {
    const username = this.props.selectedUser && this.props.selectedUser.username ? this.props.selectedUser.username : this.props.user.username;
    const filename = `payroll_wage_${username}_${this.props.year}_${this.props.quarter}.xlsx`;
    const workbook = XLSX.utils.table_to_book(document.getElementById('sheetjs'));
    XLSX.writeFile(workbook, filename);
  };

  unlockPage = (lockId) => () => {
    this.props.deleteUserLock(lockId);
    this.props.updateSessionTime();
  };

  lockPage = () => {
    this.props.addUserLock(
      this.props.year, this.props.quarter,
      'Wage');
    this.props.updateSessionTime();
  };

  openDialog = (employee) => (event) => {
    this.setState({
      employee,
      dialogOpen: true,
    });
    this.props.loadWages(employee.id);
    this.props.updateSessionTime();
  };

  handleCloseDialog = () => {
    this.props.loadEmployeeSummaries(this.state.quarterView);
    this.props.loadWageYearSummary();
    this.setState({employee: null, dialogOpen: false});
    this.props.updateSessionTime();
  };

  handleDeleteAll = () => {
    const { wages } = this.state;
    this.setState({employee: null, dialogOpen: false});
    for (const wage of wages) {
      this.props.deleteWage(wage.id);
    }
    this.props.updateSessionTime();
  };

  handleUpdate = () => {
    const { wages, employee } = this.state;
    this.props.updateWages(
      employee.id,
      wages.map(wage => {
        const { id, start, end, gross } = wage;
        return {
          id,
          start,
          end,
          gross
        }
      })
    );
    window.setTimeout(() => this.props.loadEmployeeSummaries(true), 1000);

    this.props.updateSessionTime();
  };

  getVisaName = (visa) => {
    switch (visa) {
      case 'R':
        return 'Resident';
      case 'N':
        return 'Non-Resident';
      case 'W':
      default:
        return 'Working';
    }
  };

  openYearDialog = () => {
    this.setState({yearDialogOpen: true});
    this.props.updateSessionTime();
  };

  handleCloseYearDialog = () => {
    this.setState({yearDialogOpen: false});
    this.props.updateSessionTime();
  };

  handleDateChange = (index) => (event) => {
    const { wages } = this.state;
    const date = moment(event.target.value, 'DD/MM/YYYY');
    if (date.isValid()) {
      wages[index][event.target.name] = date.format('YYYY-MM-DD');
      this.setState({wages});
    }
  };

  onFirstDateBlur = (event) => {
    const { wages } = this.state;
    const payrollType = this.getPayrollType();
    let start, end;

    if (moment(event.target.value, 'DD/MM/YYYY').isValid()) {
      if (payrollType === 'W') {
        end = moment(event.target.value, 'DD/MM/YYYY').add(6, 'days');
      } else if (payrollType === 'F') {
        end = moment(event.target.value, 'DD/MM/YYYY').add(2, 'weeks').subtract(1, 'days');
      } else {
        end = moment(event.target.value, 'DD/MM/YYYY').add(1, 'months').subtract(1, 'days');
      }
      wages[0].end = end.format('YYYY-MM-DD');
      document.querySelector(`#wage-${wages[0].id}-end`).value = end.format('DD/MM/YYYY');

      for (let i = 1; i < wages.length; ++i) {
        if (payrollType === 'W') {
          start = moment(event.target.value, 'DD/MM/YYYY').add(i * 7, 'days');
          end = moment(event.target.value, 'DD/MM/YYYY').add(i * 7 + 6, 'days');
        } else if (payrollType === 'F') {
          start = moment(event.target.value, 'DD/MM/YYYY').add(i * 2, 'weeks');
          end = moment(event.target.value, 'DD/MM/YYYY').add((i + 1) * 2, 'weeks').subtract(1, 'days');
        } else {
          start = moment(event.target.value, 'DD/MM/YYYY').add(i, 'months');
          end = moment(event.target.value, 'DD/MM/YYYY').add(i + 1, 'months').subtract(1, 'days');
        }
        wages[i].start = start.format('YYYY-MM-DD');
        wages[i].end = end.format('YYYY-MM-DD');
        document.querySelector(`#wage-${wages[i].id}-start`).value = start.format('DD/MM/YYYY');
        document.querySelector(`#wage-${wages[i].id}-end`).value = end.format('DD/MM/YYYY');
      }
    }
  };

  handleChange = (index) => (event) => {
    const { wages } = this.state;
    const number = Number(event.target.value.replace(/,/g, ''));
    if (!isNaN(number)) {
      wages[index][event.target.name] = number;
      this.setState({wages});
    }
  };

  getSummary = (employeeId) => {
    const { employee_summaries } = this.props;
    for (const summary of employee_summaries) {
      if (summary.id === employeeId) {
        return summary;
      }
    }
    return null;
  };

  getTotal = (name) => {
    const { employee_summaries } = this.props;
    let total = 0;
    for (const summary of employee_summaries) {
      total += Number(summary[name]);
    }
    return total;
  };

  getYearTotal = (name) => {
    const { wage_year_summaries } = this.props;
    let total = 0;
    for (const summary of wage_year_summaries) {
      total += Number(summary[name]);
    }
    return total;
  };

  render() {
    const {
      classes,
      employees,
      fullScreen
    } = this.props;
    const {
      employee,
      wages,
      dialogOpen,
      yearDialogOpen,
    } = this.state;
    const payrollType = this.getPayrollType();
    const emptyRows = this.getRowCount(payrollType) - (wages ? wages.length : 0);
    let isUserLocked = false;
    let lockId = 0;
    if (this.props.isUserLocked) {
      for (const userLock of this.props.userLocks) {
        if (userLock.year === this.props.year &&
          userLock.quarter === this.props.quarter &&
          userLock.model_name === 'Wage') {
          isUserLocked = true;
          lockId = userLock.id;
        }
      }
    }

    return (
      <React.Fragment>
        <div className={cn(classes.box, classes.namedBox)}>
          <div className={classes.boxName}><span>Summary</span></div>
          <div className={classes.categories}>
            <Button
              className={classes.dialogButton}
              variant="outlined"
              onClick={this.openYearDialog}
            >
              Year
            </Button>
          </div>
        </div>

        <div className={cn(classes.box, classes.table)}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Last name</TableCell>
                <TableCell>First name</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell align="right">Gross</TableCell>
                <TableCell align="right">Tax</TableCell>
                <TableCell align="right">Net</TableCell>
                <TableCell align="right">Super</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map(e => (
                <TableRow key={e.id}>
                  <TableCell>{e.last_name}</TableCell>
                  <TableCell>{e.first_name}</TableCell>
                  <TableCell>
                    {e.owner ? (
                      <FontAwesomeIcon style={{color: '#43c55d'}} icon='check-circle' />
                    ) : (
                      <FontAwesomeIcon style={{color: '#ff7461'}} icon='times-circle' />
                    )}
                  </TableCell>
                  {this.getSummary(e.id) !== null ? (
                    <React.Fragment>
                      <TableCell align="right">{currencyFormat(this.getSummary(e.id).gross)}</TableCell>
                      <TableCell align="right">{currencyFormat(this.getSummary(e.id).tax)}</TableCell>
                      <TableCell align="right">{currencyFormat(this.getSummary(e.id).net)}</TableCell>
                      <TableCell align="right">{currencyFormat(this.getSummary(e.id).super)}</TableCell>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TableCell align="right">{currencyFormat(0)}</TableCell>
                      <TableCell align="right">{currencyFormat(0)}</TableCell>
                      <TableCell align="right">{currencyFormat(0)}</TableCell>
                      <TableCell align="right">{currencyFormat(0)}</TableCell>
                    </React.Fragment>
                  )}
                  <TableCell align="center">
                    {payrollType ? (
                      <Button variant="outlined" size="small" onClick={this.openDialog(e)}>Edit</Button>
                    ) : (
                      <Tooltip title="No Payroll Type configuration">
                       <Button disabled variant="outlined" size="small">Edit</Button>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow className={classes.total}>
                <TableCell colSpan={3}>Total</TableCell>
                <TableCell align="right">{currencyFormat(this.getTotal('gross'))}</TableCell>
                <TableCell align="right">{currencyFormat(this.getTotal('tax'))}</TableCell>
                <TableCell align="right">{currencyFormat(this.getTotal('net'))}</TableCell>
                <TableCell align="right">{currencyFormat(this.getTotal('super'))}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div className={classes.actions}>
          <Tooltip title='Download Excel File'>
            <Fab aria-label="Download Excel File" className={classes.fab} onClick={this.downloadCSV}>
              <FontAwesomeIcon icon="file-excel" size="2x" />
            </Fab>
          </Tooltip>
          {isUserLocked ? (
            <Tooltip title='Unlock this Page'>
              <Fab disabled={!this.props.user.is_staff} aria-label='Unlock this Page' className={classes.fab} onClick={this.unlockPage(lockId)}>
                <FontAwesomeIcon icon="lock" size="2x" />
              </Fab>
            </Tooltip>
          ) : (
            <Tooltip title='Lock this Page'>
              <Fab aria-label='Lock this Page' className={classes.fab} onClick={this.lockPage}>
                <FontAwesomeIcon icon="unlock" size="2x" />
              </Fab>
            </Tooltip>
          )}
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        <Dialog
          fullWidth
          maxWidth={!fullScreen && 'xl'}
          fullScreen={fullScreen}
          open={yearDialogOpen}
          onClose={this.handleCloseYearDialog}
          aria-labelledby="year-dialog-title"
        >
          <DialogTitle id="year-dialog-title">
            Summary of Wage (Year)
            <div className={classes.actions} style={{display: 'inline-block', margin: '0 0 0 16px'}}>
              <Tooltip title='Download Excel File'>
                <Fab aria-label="Download Excel File" className={classes.fab} onClick={this.downloadYearCSV}>
                  <FontAwesomeIcon icon="file-excel" size="2x" />
                </Fab>
              </Tooltip>
            </div>
          </DialogTitle>
          <DialogContent>
            <Table className={classes.yearSummaryTable}>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2}>Last Name</TableCell>
                  <TableCell rowSpan={2}>First Name</TableCell>
                  <TableCell align="center" colSpan={3}>Total</TableCell>
                  <TableCell align="center" colSpan={3}>Sep</TableCell>
                  <TableCell align="center" colSpan={3}>Dec</TableCell>
                  <TableCell align="center" colSpan={3}>Mar</TableCell>
                  <TableCell align="center" colSpan={3}>Jun</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Gross</TableCell>
                  <TableCell align="center">Tax</TableCell>
                  <TableCell align="center">Net</TableCell>
                  <TableCell align="center">Gross</TableCell>
                  <TableCell align="center">Tax</TableCell>
                  <TableCell align="center">Net</TableCell>
                  <TableCell align="center">Gross</TableCell>
                  <TableCell align="center">Tax</TableCell>
                  <TableCell align="center">Net</TableCell>
                  <TableCell align="center">Gross</TableCell>
                  <TableCell align="center">Tax</TableCell>
                  <TableCell align="center">Net</TableCell>
                  <TableCell align="center">Gross</TableCell>
                  <TableCell align="center">Tax</TableCell>
                  <TableCell align="center">Net</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.wage_year_summaries.map(summary =>
                  <TableRow key={summary.id}>
                    <TableCell>{summary.last_name}</TableCell>
                    <TableCell>{summary.first_name}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.total_gross)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.total_tax)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.total_net)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.sep_gross)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.sep_tax)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.sep_net)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.dec_gross)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.dec_tax)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.dec_net)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.mar_gross)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.mar_tax)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.mar_net)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.jun_gross)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.jun_tax)}</TableCell>
                    <TableCell align="right">{currencyFormat(summary.jun_net)}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={2}>Total</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('total_gross'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('total_tax'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('total_net'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('sep_gross'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('sep_tax'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('sep_net'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('dec_gross'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('dec_tax'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('dec_net'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('mar_gross'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('mar_tax'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('mar_net'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('jun_gross'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('jun_tax'))}</TableCell>
                  <TableCell align="right">{currencyFormat(this.getYearTotal('jun_net'))}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseYearDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth={!fullScreen && 'md'}
          fullScreen={fullScreen}
          open={dialogOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {employee ? `${employee.first_name} ${employee.last_name} / ${this.getVisaName(employee.visa)}` : ''}
          </DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Start</TableCell>
                  <TableCell>End</TableCell>
                  <TableCell align="right">Gross</TableCell>
                  <TableCell align="right">Tax</TableCell>
                  <TableCell align="right">Net</TableCell>
                  <TableCell align="right">Super</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wages.map((wage, index) => (
                  <TableRow key={`wage-${wage.id}`}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <FormControl>
                        <InputBase
                          id={`wage-${wage.id}-start`}
                          name='start'
                          defaultValue={wage.start && moment(wage.start).format('DD/MM/YYYY')}
                          onChange={this.handleDateChange(index)}
                          onBlur={index === 0 ? this.onFirstDateBlur : null}
                          classes={{
                            input: classes.bootstrapInput,
                          }}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl>
                        <InputBase
                          id={`wage-${wage.id}-end`}
                          name='end'
                          defaultValue={wage.end && moment(wage.end).format('DD/MM/YYYY')}
                          onChange={this.handleDateChange(index)}
                          classes={{
                            input: classes.bootstrapInput,
                          }}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">
                      <FormControl>
                        <InputBase
                          id={`wage-${wage.id}-gross`}
                          name='gross'
                          defaultValue={currencyFormatNum(wage.gross)}
                          onChange={this.handleChange(index)}
                          classes={{
                            input: classes.bootstrapInput,
                          }}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">{currencyFormat(wage.tax)}</TableCell>
                    <TableCell align="right">{currencyFormat(wage.net)}</TableCell>
                    <TableCell align="right">{currencyFormat(wage.super)}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 ? (
                  <TableRow style={{ height: 49 * (emptyRows) }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                ) : null}
                {employee &&
                  <TableRow>
                    <TableCell colSpan={3}>Total</TableCell>
                    <TableCell align="right">{currencyFormat(this.getSummary(employee.id).gross)}</TableCell>
                    <TableCell align="right">{currencyFormat(this.getSummary(employee.id).tax)}</TableCell>
                    <TableCell align="right">{currencyFormat(this.getSummary(employee.id).net)}</TableCell>
                    <TableCell align="right">{currencyFormat(this.getSummary(employee.id).super)}</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteAll} color="primary">
              Delete All
            </Button>
            <Button onClick={this.handleUpdate} color="primary">
              Update
            </Button>
            <Button onClick={this.handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <table id="sheetjs" style={{display: 'none'}}>
          <thead>
          <tr>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Owner</th>
            <th>Gross</th>
            <th>Tax</th>
            <th>Net</th>
            <th>Super</th>
          </tr>
          </thead>
          <tbody>
          {this.props.employees && this.props.employees.length > 0 &&
          this.props.employees.map(row => {
            const summary = this.getSummary(row.id);
            if (summary === null || summary === undefined) {
              return null;
            }
            return (
                <tr key={row.id}>
                  <td>{row.last_name}</td>
                  <td>{row.first_name}</td>
                  <td>{row.owner}</td>
                  <td>{summary.gross}</td>
                  <td>{summary.tax}</td>
                  <td>{summary.net}</td>
                  <td>{summary.super}</td>
                </tr>
            );
          })
          }
          <tr>
            <td>Total</td>
            <td />
            <td />
            <td>{this.getTotal('gross')}</td>
            <td>{this.getTotal('tax')}</td>
            <td>{this.getTotal('net')}</td>
            <td>{this.getTotal('super')}</td>
          </tr>
          </tbody>
        </table>

        <table id="sheetjs-year" style={{display: 'none'}}>
          <thead>
          <tr>
            <th rowSpan={2}>Last Name</th>
            <th rowSpan={2}>First Name</th>
            <th colSpan={3}>Total</th>
            <th colSpan={3}>Sep</th>
            <th colSpan={3}>Dec</th>
            <th colSpan={3}>Mar</th>
            <th colSpan={3}>Jun</th>
          </tr>
          <tr>
            <th>Gross</th>
            <th>Tax</th>
            <th>Net</th>
            <th>Gross</th>
            <th>Tax</th>
            <th>Net</th>
            <th>Gross</th>
            <th>Tax</th>
            <th>Net</th>
            <th>Gross</th>
            <th>Tax</th>
            <th>Net</th>
            <th>Gross</th>
            <th>Tax</th>
            <th>Net</th>
          </tr>
          </thead>
          <tbody>
          {this.props.wage_year_summaries && this.props.wage_year_summaries.length > 0 &&
          this.props.wage_year_summaries.map(row => {
            return (
              <tr key={row.id}>
                <td>{row.last_name}</td>
                <td>{row.first_name}</td>
                <td>{row.total_gross}</td>
                <td>{row.total_tax}</td>
                <td>{row.total_net}</td>
                <td>{row.sep_gross}</td>
                <td>{row.sep_tax}</td>
                <td>{row.sep_net}</td>
                <td>{row.dec_gross}</td>
                <td>{row.dec_tax}</td>
                <td>{row.dec_net}</td>
                <td>{row.mar_gross}</td>
                <td>{row.mar_tax}</td>
                <td>{row.mar_net}</td>
                <td>{row.jun_gross}</td>
                <td>{row.jun_tax}</td>
                <td>{row.jun_net}</td>
              </tr>
            );
          })
          }
          <tr>
            <td>Total</td>
            <td />
            <td>{this.getYearTotal('total_gross')}</td>
            <td>{this.getYearTotal('total_tax')}</td>
            <td>{this.getYearTotal('total_net')}</td>
            <td>{this.getYearTotal('sep_gross')}</td>
            <td>{this.getYearTotal('sep_tax')}</td>
            <td>{this.getYearTotal('sep_net')}</td>
            <td>{this.getYearTotal('dec_gross')}</td>
            <td>{this.getYearTotal('dec_tax')}</td>
            <td>{this.getYearTotal('dec_net')}</td>
            <td>{this.getYearTotal('mar_gross')}</td>
            <td>{this.getYearTotal('mar_tax')}</td>
            <td>{this.getYearTotal('mar_net')}</td>
            <td>{this.getYearTotal('jun_gross')}</td>
            <td>{this.getYearTotal('jun_tax')}</td>
            <td>{this.getYearTotal('jun_net')}</td>
          </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

PayrollWage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let errors = [];
  if (state.payroll.errors) {
    errors = Object.keys(state.payroll.errors).map(field => {
      return {field, message: state.payroll.errors[field]};
    });
  }
  return {
    errors,
    employees: state.payroll.employees,
    employee_summaries: state.payroll.employee_summaries,
    wage_year_summaries: state.payroll.wage_year_summaries,
    wages: state.payroll.wages,
    config: state.auth.config,
    selectedConfig: state.admin.selectedConfig,
    selectedUser: state.admin.selectedUser,
    user: state.auth.user,
    year: state.period.year,
    quarter: state.period.quarter,
    isUserLocked: state.period.isUserLocked,
    userLocks: state.period.userLocks,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadEmployees: () => dispatch(payroll.loadEmployees()),
    loadEmployeeSummaries: (is_quarter) => dispatch(payroll.loadEmployeeSummaries(is_quarter)),
    loadWageYearSummary: () => dispatch(payroll.loadWageYearSummary()),
    updateWages: (employee_id, wages) => dispatch(payroll.updateWages(employee_id, wages)),
    loadWages: (employee_id) => dispatch(payroll.loadWages(employee_id)),
    deleteWage: (id) => dispatch(payroll.deleteWage(id)),
    loadConfig: () => dispatch(auth.loadConfig()),
    resetError: () => dispatch(payroll.resetError()),
    loadUserLocks: () => dispatch(period.loadUserLocks()),
    addUserLock: (year, quarter, model_name) => dispatch(period.addUserLock(year, quarter, model_name)),
    deleteUserLock: (id) => dispatch(period.deleteUserLock(id)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(PayrollWage)));
