import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auth, payroll, period, session } from '../actions';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/es/withMobileDialog';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PayrollEmployeeGrid from './PayrollEmployeeGrid';
import XLSX from 'xlsx';


const styles = theme => ({
  categories: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'nowrap',
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'nowrap',
    }
  },
  actions: {
    marginTop: theme.spacing.unit * 2,
  },
  box: {
    padding: theme.spacing.unit,
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.background.paper,
    flex: '0 1 auto',
  },
  namedBox: {
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  boxMargin: {
    marginTop: 32,
  },
  boxName: {
    margin: '-32px 8px 8px 8px',
    borderRadius: 4,
    padding: '8px 16px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(59, 132, 3, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(239, 239, 239, 0.4)',
    '& > span': {
      color: '#26710c',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
  },
  formControl: {
    flex: '1 1 auto',
    margin: theme.spacing.unit,
    minWidth: 140,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  button: {
    padding: '14px 24px',
    backgroundColor: '#e2f1d6',
    '&:hover': {
      backgroundColor: '#a0d873',
    },
    color: '#330809',
  },
  fab: {
    margin: theme.spacing.unit,
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.15)',
  },
});

class PayrollEmployee extends React.Component {
  state = {
    last_name: '',
    first_name: '',
    gender: 'E',
    dob: '',
    tfn: '',
    visa: '',
    commerce: '',
    terminate: false,
    owner: false,
    address: '',
    comment: '',
    labelWidth: 0,
    open: false,
    messages: [],
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });

    this.props.loadEmployees();
    this.props.loadUserLocks();
    this.props.loadConfig();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errors !== prevProps.errors && this.props.errors.length > 0) {
      for (const error of this.props.errors) {
        this.openSnackbar(error.message);
        this.props.resetError();
      }
    }
    if (this.props.selectedUser !== prevProps.selectedUser && this.props.selectedUser) {
      this.props.loadEmployees();
      this.props.loadUserLocks();
    }
    if (this.props.year !== prevProps.year || this.props.quarter !== prevProps.quarter) {
      this.props.loadEmployees();
      this.props.loadUserLocks();
    }
  }

  openSnackbar = (message) => {
    const messages = [...this.state.messages, message];
    this.setState({ open: true, messages: messages});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    window.setTimeout(() => {
      const messages = [...this.state.messages];
      messages.splice(0, 1);
      const open = messages.length > 0;
      this.setState({ open, messages });
    }, 1000);
  };

  downloadCSV = () => {
    const username = this.props.selectedUser && this.props.selectedUser.username ? this.props.selectedUser.username : this.props.user.username;
    const filename = `payroll_employee_${username}_${this.props.year}_${this.props.quarter}.xlsx`;
    const workbook = XLSX.utils.table_to_book(document.getElementById('sheetjs'));
    XLSX.writeFile(workbook, filename);
  };

  unlockPage = (lockId) => () => {
    this.props.deleteUserLock(lockId);
    this.props.updateSessionTime();
  };

  lockPage = () => {
    this.props.addUserLock(
      this.props.year, this.props.quarter,
      'Employee');
    this.props.updateSessionTime();
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addEmployee = () => {
    const { last_name, first_name, gender, dob, tfn, visa, commerce, terminate, owner, address, comment } = this.state;
    const { fiscalEnd } = this.props;

    if (!last_name) {
      this.openSnackbar('Last name can not be blank.');
      return;
    }

    if (!first_name) {
      this.openSnackbar('First name can not be blank.');
      return;
    }

    if (!visa) {
      this.openSnackbar('Visa can not be blank.');
      return;
    }

    this.props.addEmployee(
      last_name.toUpperCase(),
      first_name.toUpperCase(),
      gender,
      dob,
      tfn,
      visa,
      commerce.toUpperCase(),
      terminate,
      owner,
      address.toUpperCase(),
      comment.toUpperCase(),
      fiscalEnd,
    );

    this.setState({
      last_name: '',
      first_name: '',
      gender: 'E',
      dob: '',
      tfn: '',
      visa: '',
      commerce: '',
      terminate: false,
      owner: false,
      address: '',
      comment: ''
    });
    this.props.updateSessionTime();
  };

  render() {
    const { classes } = this.props;
    const {
      first_name,
      last_name,
      gender,
      dob,
      tfn,
      visa,
      commerce,
      terminate,
      owner,
      address,
      comment,
    } = this.state;
    let isUserLocked = false;
    let lockId = 0;
    if (this.props.isUserLocked) {
      for (const userLock of this.props.userLocks) {
        if (userLock.year === this.props.year &&
          userLock.quarter === this.props.quarter &&
          userLock.model_name === 'Employee') {
          isUserLocked = true;
          lockId = userLock.id;
        }
      }
    }

    return (
      <React.Fragment>
        <div className={cn(classes.box, classes.namedBox)}>
          <div className={classes.boxName}><span>Input</span></div>
          <div className={classes.form}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-last_name"
              >
                Last Name
              </InputLabel>
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="last_name"
                id="outlined-last_name"
                value={last_name}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-first_name"
              >
                First Name
              </InputLabel>
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="first_name"
                id="outlined-first_name"
                value={first_name}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-gender"
              >
                Gender
              </InputLabel>
              <Select
                value={gender}
                onChange={this.handleChange}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="gender"
                    id="outlined-gender"
                    onKeyPress={(ev) => {
                      if (ev.key === 'm' || ev.key === 'M') {
                        this.setState({gender: 'M'});
                        ev.preventDefault();
                      }
                      if (ev.key === 'f' || ev.key === 'F') {
                        this.setState({gender: 'F'});
                        ev.preventDefault();
                      }
                    }}
                  />
                }
              >
                <MenuItem value={'E'}>Empty</MenuItem>
                <MenuItem value={'M'}>Male</MenuItem>
                <MenuItem value={'F'}>Female</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-dob"
              >
                D.O.B (dd/MM/yyyy)
              </InputLabel>
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="dob"
                id="outlined-dob"
                value={dob}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-tfn"
              >
                TFN
              </InputLabel>
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="tfn"
                id="outlined-tfn"
                value={tfn}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-visa"
              >
                Visa
              </InputLabel>
              <Select
                value={visa}
                onChange={this.handleChange}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="visa"
                    id="outlined-visa"
                    onKeyPress={(ev) => {
                      if (ev.key === 'r' || ev.key === 'R') {
                        this.setState({visa: 'R'});
                        ev.preventDefault();
                      }
                      if (ev.key === 'n' || ev.key === 'N') {
                        this.setState({visa: 'N'});
                        ev.preventDefault();
                      }
                      if (ev.key === 'w' || ev.key === 'W') {
                        this.setState({visa: 'W'});
                        ev.preventDefault();
                      }
                    }}
                  />
                }
              >
                <MenuItem value={'R'}>Resident</MenuItem>
                <MenuItem value={'N'}>Non-Resident</MenuItem>
                <MenuItem value={'W'}>Working</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-commerce"
              >
                Commence
              </InputLabel>
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="commerce"
                id="outlined-commerce"
                value={commerce}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-terminate"
              >
                Terminate
              </InputLabel>
              <Select
                value={terminate}
                onChange={this.handleChange}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="terminate"
                    id="outlined-terminate"
                    onKeyPress={(ev) => {
                      if (ev.key === 'y' || ev.key === 'Y') {
                        this.setState({terminate: true});
                        ev.preventDefault();
                      }
                      if (ev.key === 'n' || ev.key === 'N') {
                        this.setState({terminate: false});
                        ev.preventDefault();
                      }
                    }}
                  />
                }
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>


            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-owner"
              >
                Owner
              </InputLabel>
              <Select
                value={owner}
                onChange={this.handleChange}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="owner"
                    id="outlined-owner"
                    onKeyPress={(ev) => {
                      if (ev.key === 'y' || ev.key === 'Y') {
                        this.setState({owner: true});
                        ev.preventDefault();
                      }
                      if (ev.key === 'n' || ev.key === 'N') {
                        this.setState({owner: false});
                        ev.preventDefault();
                      }
                    }}
                  />
                }
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>


            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-address"
              >
                Address
              </InputLabel>
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="address"
                id="outlined-address"
                value={address}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-comment"
              >
                Comment
              </InputLabel>
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="comment"
                id="outlined-comment"
                value={comment}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <Button disabled={isUserLocked || this.props.isLocked || this.props.isAllLocked} variant="outlined" size="large" className={classes.button} onClick={this.addEmployee}>Add</Button>
            </FormControl>
          </div>
        </div>

        <PayrollEmployeeGrid disabled={isUserLocked || this.props.isLocked || this.props.isAllLocked} />

        <div className={classes.actions}>
          <Tooltip title='Download Excel File'>
            <Fab aria-label="Download Excel File" className={classes.fab} onClick={this.downloadCSV}>
              <FontAwesomeIcon icon="file-excel" size="2x" />
            </Fab>
          </Tooltip>
          {isUserLocked ? (
            <Tooltip title='Unlock this Page'>
              <Fab disabled={!this.props.user.is_staff} aria-label='Unlock this Page' className={classes.fab} onClick={this.unlockPage(lockId)}>
                <FontAwesomeIcon icon="lock" size="2x" />
              </Fab>
            </Tooltip>
          ) : (
            <Tooltip title='Lock this Page'>
              <Fab aria-label='Lock this Page' className={classes.fab} onClick={this.lockPage}>
                <FontAwesomeIcon icon="unlock" size="2x" />
              </Fab>
            </Tooltip>
          )}
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messages[0]}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        <table id="sheetjs" style={{display: 'none'}}>
          <thead>
          <tr>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Gender</th>
            <th>DOB</th>
            <th>TFN</th>
            <th>Visa</th>
            <th>Commence</th>
            <th>Terminate</th>
            <th>Owner</th>
            <th>Address</th>
            <th>Comment</th>
          </tr>
          </thead>
          <tbody>
          {this.props.employees && this.props.employees.length > 0 &&
          this.props.employees.map(row => {
            return (
                <tr key={row.id}>
                  <td>{row.last_name}</td>
                  <td>{row.first_name}</td>
                  <td>{row.gender}</td>
                  <td>{row.dob}</td>
                  <td>{row.tfn}</td>
                  <td>{row.visa}</td>
                  <td>{row.commerce}</td>
                  <td>{row.terminate}</td>
                  <td>{row.owner}</td>
                  <td>{row.address}</td>
                  <td>{row.comment}</td>
                </tr>
            );
          })
          }
          </tbody>
        </table>

      </React.Fragment>
    );
  }
}

PayrollEmployee.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let errors = [];
  if (state.payroll.errors) {
    errors = Object.keys(state.payroll.errors).map(field => {
      return {field, message: state.payroll.errors[field]};
    });
  }
  return {
    errors,
    employees: state.payroll.employees,
    config: state.auth.config,
    selectedConfig: state.admin.selectedConfig,
    selectedUser: state.admin.selectedUser,
    user: state.auth.user,
    fiscalEnd: state.period.fiscalEnd,
    year: state.period.year,
    quarter: state.period.quarter,
    isUserLocked: state.period.isUserLocked,
    isAllLocked: state.period.isAllLocked,
    isLocked: state.period.isLocked,
    userLocks: state.period.userLocks,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadEmployees: () => dispatch(payroll.loadEmployees()),
    addEmployee: (...args) => dispatch(payroll.addEmployee(...args)),
    deleteEmployee: (id) => dispatch(payroll.deleteEmployee(id)),
    loadConfig: () => dispatch(auth.loadConfig()),
    resetError: () => dispatch(payroll.resetError()),
    loadUserLocks: () => dispatch(period.loadUserLocks()),
    addUserLock: (year, quarter, model_name) => dispatch(period.addUserLock(year, quarter, model_name)),
    deleteUserLock: (id) => dispatch(period.deleteUserLock(id)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(PayrollEmployee)));
