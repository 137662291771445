import React from 'react';
import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import { income, session } from '../actions';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { currencyFormat, DateFormatter, NumberFormatter } from '../utils';
import moment from 'moment';
import cn from 'classnames';

const columns = [
  { key: 'date', name: 'Date', width: 110, sortable: true, editable: true, formatter: DateFormatter },
  { key: 'amount', name: 'Amount', sortable: true, editable: true, formatter: NumberFormatter },
];

const styles = theme => ({
  wrapper: {
    marginTop: theme.spacing.unit * 2,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px !important',
    '& .react-grid-Grid': {
      overflow: 'visible !important'
    },
    '& .react-grid-Header': {
      position: 'sticky !important',
      zIndex: 3,
      top: 0,
    },
    '& .react-grid-Canvas': {
      overflowY: 'hidden !important'
    },
    '& .react-grid-Main': {
      outline: 'none'
    },
    '& .react-grid-HeaderRow': {
      backgroundColor: '#dedede',
    },
    '& .react-grid-HeaderCell': {
      fontWeight: 'normal',
      color: 'white',
      backgroundColor: '#8bc34a',
    },
    '& .react-grid-Row--odd > .react-grid-Cell': {
      backgroundColor: '#EDF5E6',
    },
    '& .react-grid-Row--odd:hover > .react-grid-Cell': {
      backgroundColor: '#e3ebdc',
    },
    '& .react-grid-Cell:nth-child(2)': {
      textAlign: 'right',
    },
  },
  bar: {
    position: 'relative',
    height: 19 + theme.spacing.unit * 4,
    backgroundColor: 'white',
    border: '1px solid #e7eaec',
    borderTop: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  total: {
    width: 170,
    padding: theme.spacing.unit,
    paddingLeft: 60 + theme.spacing.unit,
  },
  value: {
    width: 879,
    textAlign: 'right',
    padding: theme.spacing.unit,
  },
  button: {
    position: 'absolute',
    left: 10
  },
  fab: {
    margin: theme.spacing.unit,
    boxShadow: 'none',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.8)',
    color: 'black',
    width: 24,
    height: 24,
    minHeight: 24,
  }
});

const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === "DESC") {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};

class IncomeInterestGrid extends React.Component {
  state = {
    rows: [],
    interests: [],
    selectedIndexes: [],
    isUpdate: false,
  };

  componentDidMount() {
    this.setState({rows: this.props.interests, interests: this.props.interests});
  }

  static getDerivedStateFromProps(props, state) {
    if (Array.isArray(props.interests) && !(props.interests.length === state.interests.length && props.interests.every((value, index) => value === state.interests[index]))) {
      return { rows: props.interests, interests: props.interests, isUpdate: true };
    }
    return null;
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const { interests, isUpdate } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(interests, this.sortColumn, this.sortDirection)(rows);
    }
    const newRow = {...rows[fromRow], ...updated};
    const { id, date, amount, user } = newRow;
    const { start, end } = this.props;

    if (!date) {
      return;
    }

    if (moment(date).diff(moment(start)) < 0 || moment(date).diff(moment(end)) > 0) {
      return;
    }

    if (!amount) {
      return;
    }

    this.props.updateInterest(
      id,
      date,
      Number(amount),
      user,
    );
    this.props.updateSessionTime();
  };

  onRowsSelected = rows => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        rows.map(r => r.rowIdx)
      )
    });
  };

  onRowsDeselected = rows => {
    let rowIndexes = rows.map(r => r.rowIdx);
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        i => rowIndexes.indexOf(i) === -1
      )
    });
  };

  getTotal = (colName) => {
    const { rows } = this.state;
    if (!rows || rows.length === 0) {
      return 0;
    }
    return rows.reduce((total, e) => total + Number(e[colName]), 0);
  };

  deleteSelectedRows = () => {
    const { interests, isUpdate, selectedIndexes } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(interests, this.sortColumn, this.sortDirection)(rows);
    }
    selectedIndexes.map((index) => {
      return this.props.deleteInterest(rows[index].id);
    });
    this.setState({ selectedIndexes: [] });
    this.props.updateSessionTime();
  };

  render() {
    const { classes } = this.props;
    const { interests, isUpdate, selectedIndexes } = this.state;
    let rows = this.state.rows;
    if (isUpdate && this.sortColumn && this.sortDirection) {
      rows = sortRows(interests, this.sortColumn, this.sortDirection)(rows);
    }

    return (
      <div ref={ref => this.div = ref} className={classes.wrapper}>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          rowHeight={30}
          enableCellSelect
          minHeight={(rows.length + 1) * 30}
          onGridRowsUpdated={this.onGridRowsUpdated}
          onGridSort={(sortColumn, sortDirection) => {
            this.sortColumn = sortColumn;
            this.sortDirection = sortDirection;
            this.setState({rows: sortRows(interests, sortColumn, sortDirection)(rows), isUpdate: false})
          }}
          rowSelection={{
            showCheckbox: true,
            enableShiftSelect: true,
            onRowsSelected: this.onRowsSelected,
            onRowsDeselected: this.onRowsDeselected,
            selectBy: {
              indexes: selectedIndexes
            }
          }}
        />
        <div className={classes.bar}>
          <div className={classes.button}>
            <Tooltip title='Delete selected rows'>
              <Fab size="small" aria-label="Delete" className={classes.fab} onClick={this.deleteSelectedRows}>
                <FontAwesomeIcon icon="trash-alt" />
              </Fab>
            </Tooltip>
          </div>
          <div className={cn(classes.total, classes.bold)}>Total</div>
          <div className={cn(classes.value)}>
            {currencyFormat(this.getTotal('amount'))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    interests: state.income.interests,
    user: state.auth.user,
    config: state.auth.config,
    selectedConfig: state.admin.selectedConfig,
    start: state.period.start,
    end: state.period.end,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateInterest: (...args) => dispatch(income.updateInterest(...args)),
    deleteInterest: (id) => dispatch(income.deleteInterest(id)),
    updateSessionTime: () => dispatch(session.updateSessionTime()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IncomeInterestGrid));