const initialState = {
  sessionUpdated: 0,
  isSessionTimeout: false,
};


export default function session(state=initialState, action) {

  switch (action.type) {
    case 'UPDATE_SESSION_TIME':
      return {...state, sessionUpdated: state.sessionUpdated + 1, isSessionTimeout: false};

    case 'SET_SESSION_TIMEOUT':
      return {...state, isSessionTimeout: true};

    default:
      return state;
  }
}
