const initialState = {
  businessTypeAs: [],
  businessTypeBs: [],
  interests: [],
  others: [],
  errors: {}
};


export default function income(state=initialState, action) {

  switch (action.type) {

    case 'LOAD_BUSINESS_TYPE_AS_SUCCESS':
      return {...state, businessTypeAs: action.data, errors: {}};

    case 'LOAD_BUSINESS_TYPE_AS_FAILURE':
      return {...state, businessTypeAs: [], errors: action.data};

    case 'UPDATE_BUSINESS_TYPE_A_SUCCESS':
      return {...state, errors: {}};

    case 'UPDATE_BUSINESS_TYPE_A_FAILURE':
      return {...state, errors: action.data};

    case 'LOAD_BUSINESS_TYPE_BS_SUCCESS':
      return {...state, businessTypeBs: action.data, errors: {}};

    case 'LOAD_BUSINESS_TYPE_BS_FAILURE':
      return {...state, businessTypeBs: {}, errors: action.data};

    case 'LOAD_INTERESTS_SUCCESS':
      return {...state, interests: action.data, errors: {}};

    case 'LOAD_INTERESTS_FAILURE':
      return {...state, interests: [], errors: action.data};

    case 'LOAD_OTHERS_SUCCESS':
      return {...state, others: action.data, errors: {}};

    case 'LOAD_OTHERS_FAILURE':
      return {...state, others: [], errors: action.data};

    case 'ADD_OTHER_SUCCESS':
    case 'UPDATE_OTHER_SUCCESS':
    case 'DELETE_OTHER_SUCCESS':
    case 'ADD_INTEREST_SUCCESS':
    case 'UPDATE_INTEREST_SUCCESS':
    case 'DELETE_INTEREST_SUCCESS':
    case 'ADD_BUSINESS_TYPE_B_SUCCESS':
    case 'UPDATE_BUSINESS_TYPE_B_SUCCESS':
    case 'DELETE_BUSINESS_TYPE_B_SUCCESS':
      return {...state, errors: {}};

    case 'ADD_OTHER_FAILURE':
    case 'UPDATE_OTHER_FAILURE':
    case 'DELETE_OTHER_FAILURE':
    case 'ADD_INTEREST_FAILURE':
    case 'UPDATE_INTEREST_FAILURE':
    case 'DELETE_INTEREST_FAILURE':
    case 'ADD_BUSINESS_TYPE_B_FAILURE':
    case 'UPDATE_BUSINESS_TYPE_B_FAILURE':
    case 'DELETE_BUSINESS_TYPE_B_FAILURE':
      return {...state, errors: action.data};

    case 'RESET_ERROR':
      return {...state, errors: {}};

    default:
      return state;
  }
}
